import { Outlet, useLocation, useParams } from "react-router-dom";

import { BaseAnimation } from "../../components/base/BaseAnimation";
import { AccountFooter } from "src/components/partials";
import { Logo } from "src/content/Logo/Logo";
import { Stepper } from "../../content";

import "./index.scss";

const pageIs = (param: string | undefined): string => {
  if (
    param?.includes("form/step/1") ||
    param?.includes("form/step/2") ||
    param?.includes("form/step/3") ||
    param === ""
  ) {
    return "Credentials-registration-step-1";
  } else if (param?.includes("form/step/4")) {
    return "Credentials-registration-step-1-without-hand";
  } else if (param?.includes("credentials")) {
    return "Credentials-registration-step-2";
  } else if (param?.includes("1") || param?.includes("reset-password")) {
    return "Credentials-poll-step-1";
  } else if (param?.includes("2") || param?.includes("enter-password")) {
    return "Credentials-poll-step-2";
  } else if (param?.includes("3")) {
    return "Credentials-poll-step-3";
  } else if (param?.includes("4")) {
    return "Credentials-poll-step-4";
  } else if (param?.includes("5")) {
    return "Credentials-poll-step-5 Credentials-poll-step-5-page-2";
  } else if (param?.includes("6")) {
    return "Credentials-poll-step-5 Credentials-poll-step-5-page-1";
  } else if (param?.includes("7")) {
    return "Credentials-poll-step-6";
  } else {
    return "";
  }
};

export const Credentials = () => {
  const location = useLocation();
  const currentStep = Number(location.pathname.slice(-1));

  const params = useParams();
  const className = pageIs(params["*"]);
  const classNameFooterMb = params["*"]?.includes("quiz")
    ? "Credentials__footer_position-fixed"
    : "";
  return (
    <BaseAnimation>
      <div className={["Credentials", className].join(" ").trim()}>
        <div className="Credentials__inner">
          <div className="Credentials__header">
            <Logo />
            {currentStep <= maxSteps ? <Stepper currentStep={currentStep} maxSteps={maxSteps} /> : null}
          </div>
          <div className="Credentials__content">
            <Outlet />
          </div>
          <div
            className={["Credentials__footer", classNameFooterMb]
              .join(" ")
              .trim()}
          >
            <AccountFooter />
          </div>
        </div>
      </div>
    </BaseAnimation>
  );
};

const maxSteps = 3;