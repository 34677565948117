export const cookieValue = () => {
  const cookie = document.cookie;
  const cookieValue: Record<string, string> = {};

  cookie.split("; ").forEach((pair) => {
    const [key, value] = pair.split("=");
    cookieValue[key] = value;
  });
  return cookieValue;
};

export function setCookie(name: string, value: string, options = {} as any) {
  options = {
    path: "/",
    // при необходимости добавьте другие значения по умолчанию
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function deleteCookie(name: string) {
  setCookie(name, "", {
    "max-age": -1,
  });
}
