import { Modal as ModalMUI } from "@mui/material";

import styles from "./Modal.module.scss";
import clsx from "clsx";

type PropsType = {
  className?: string;
  children: any;
  isOpen: boolean;
  toggle: () => void;
};

export default function Modal({
  className = "",
  children,
  isOpen,
  toggle,
}: PropsType) {
  return (
    <ModalMUI
      className={styles.Modal}
      aria-describedby="modal-modal-description"
      aria-labelledby="modal-modal-title"
      onClose={toggle}
      open={isOpen}
    >
      <div
        className={clsx({
          [styles.Modal__inner]: true,
          [className]: !!className,
        })}
      >
        {children}
      </div>
    </ModalMUI>
  );
}
