import React from 'react';
import { Link } from "react-router-dom";

import { Modal } from "../../components/base/Modal";
import { BaseTypography } from "../../components/base/BaseTypography";
import { BaseButton } from "../../components/base/BaseButton";

import styles from './MigrateUserModal.module.scss';

type PropsType = {
  open: boolean;
  onClose: () => void;
}

const MigrateUserModal = ({open, onClose}: PropsType) => {
  return (
    <Modal isOpen={open} toggle={onClose} className={styles.MigrateUserModal}>
      <BaseTypography variant={"p"} className={styles.MigrateUserModal__title}>
        У нас обновилась анкета профиля!
      </BaseTypography>
      <BaseTypography
        className={styles.MigrateUserModal__text}
        variant={'p'}
      >
        Пожалуйста, заполните новую анкету,
        чтобы продолжать участвовать во встречах Тёрки.
      </BaseTypography>
      <Link to={"/signup/form/step/2?FillFull=true"}>
        <BaseButton
          className={styles.MigrateUserModal__button}
          variant="base-button-new-style-black"
        >
          Заполнить
        </BaseButton>
      </Link>
    </Modal>
  );
};

export default MigrateUserModal;