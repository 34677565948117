import { Outlet } from "react-router-dom";

import { AccountFooter, AccountHeader } from "src/components/partials";
import { BaseContainer } from "src/components/base";
import { NavBar } from "src/content/NavBar";

import styles from "./DefaultAccount.module.scss";
import { useMe } from "src/api/hooks/personsHooks";

const DefaultAccount = () => {
  const { data: me } = useMe();
  return (
    <div className={styles.DefaultAccount}>
      <div className={styles.DefaultAccount__header}>
        {me ? <AccountHeader /> : <NavBar variant="account" />}
      </div>
      <div className={styles.DefaultAccount__body}>
        <BaseContainer>
          <Outlet />
        </BaseContainer>
      </div>
      <div className={styles.DefaultAccount__footer}>
        <AccountFooter />
      </div>
    </div>
  );
};

export default DefaultAccount;
