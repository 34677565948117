import { FC, ReactNode } from 'react';
import Modal from 'react-modal';

import { BaseTypography } from '../BaseTypography';
import { TikIcon } from '../BaseIcons';

import './index.scss';

Modal.setAppElement('#root');

type PropsType = {
  className?: string;
  isShowing: boolean;
  toggle: () => void;
  children: ReactNode;
  title: string;
};

export const BaseModal: FC<PropsType> = ({
  className = '',
  isShowing,
  toggle,
  children,
  title,
}) => {
  return (
    <Modal
      isOpen={isShowing}
      className={['base-modal', className].join(' ').trim()}
      overlayClassName="base-modal-overlay"
    >
      <div className="base-modal__inner">
        <TikIcon className="base-modal__close-icon" onClick={toggle} />
        <BaseTypography className="base-modal__title" variant="h3">
          {title}
        </BaseTypography>
        {children}
      </div>
    </Modal>
  );
};
