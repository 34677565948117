import { FC, ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

import './index.scss';
import clsx from "clsx";


type PropsType = InputHTMLAttributes<HTMLInputElement> & { error?: boolean };

export const BaseInput: FC<PropsType> = forwardRef(
  ({ className = '', error, ...rest }, ref: ForwardedRef<any>) => {
    return (
      <input
        // className={['base-input', className].join(' ').trim()}
        className={clsx({
          'base-input': true,
          'base-input_required': error,
          [className]: !!className,
        })}
        type="text"
        autoComplete="off"
        {...rest}
        ref={ref}
      />
    );
  },
);
