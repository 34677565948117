import React from "react";
import toast from "react-hot-toast/headless";

import {
  useTakeQueueInEvent,
  useTakePartInEvent,
  useLeaveEvent,
} from "src/api/hooks/eventsHooks";

type PropsType = {
  handleClick?: () => void;
};

export function useEventHandlers({ handleClick }: PropsType) {
  const takePartInEvent = useTakePartInEvent();

  // The function set user to the participants list of an event
  const onAddUserToParticipantsList = async (eventId: string) => {
    try {
      await takePartInEvent.mutateAsync({ id: eventId });
      handleClick && handleClick();
    } catch (e) {
      toast.error("Не удалось попасть в список участников мероприятия.");
    }
  };

  const takeQueueInEvent = useTakeQueueInEvent();

  // The function set user to the waiters list of an event
  const onAddUserToWaitersList = async (eventId: string) => {
    try {
      await takeQueueInEvent.mutateAsync({ id: eventId });
      handleClick && handleClick();
    } catch (e) {
      toast.error("Не удалось попасть в список ожидания мероприятия.");
    }
  };

  // The fucntion let to leave an event
  const onLeaveEvent = async (eventId: string) => {
    try {
      await leaveEvent.mutateAsync({ id: eventId });
      handleClick && handleClick();
    } catch (e) {
      toast.error("Не удалось покинуть мероприятие.");
    }
  };

  const leaveEvent = useLeaveEvent();

  return {
    onAddUserToParticipantsList,
    onAddUserToWaitersList,
    onLeaveEvent,
  };
}
