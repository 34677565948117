import React from "react";

export default function useUploadCover() {
  const [uploadedCover, setUploadedCover] = React.useState<
    File | string | undefined
  >();
  const [croppedCover, setCroppedCover] = React.useState<File | undefined>();
  const [isOpenCropper, setIsOpenCropper] = React.useState(false);

  const handleUploadCover = React.useCallback(
    (file: File | string, openCropper = true) => {
      setUploadedCover(() => file);
      if (openCropper) {
        setIsOpenCropper((prev) => !prev);
      }
    },
    [],
  );

  const handleEditCover = React.useCallback(() => {
    setIsOpenCropper((prev) => !prev);
  }, []);

  const handleDeleteCover = React.useCallback(() => {
    setUploadedCover(() => undefined);
  }, []);

  const handleUploadCropped = React.useCallback((file: File) => {
    setCroppedCover(() => file);
  }, []);

  const handleDeleteCropped = React.useCallback(() => {
    setUploadedCover(() => undefined);
    setCroppedCover(() => undefined);
  }, []);

  const toggleCropper = () => setIsOpenCropper((prev) => !prev);

  return {
    uploadedCover,
    croppedCover,
    isOpenCropper,
    handleUploadCover,
    handleEditCover,
    handleDeleteCover,
    handleUploadCropped,
    handleDeleteCropped,
    toggleCropper,
  };
}
