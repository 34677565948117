import React from "react";

import { EventCoverPreviewIcon, TikIcon } from "src/components/base/BaseIcons";
import { BaseTypography } from "src/components/base/BaseTypography";
import { BaseInput } from "src/components/base/BaseInput";
import { BaseImage } from "src/components/base/BaseImage";

import styles from "./EventCover.module.scss";
import toast from "react-hot-toast/headless";

type PropsType = {
  onUpload: (file: File) => void;
  onDelete: () => void;
  value: File | string | undefined;
  disabled?: boolean;
};

const maxFileSize = +(process.env.REACT_APP_MAX_FILE_SIZE as string) ?? 10;

const EventCover = ({ onUpload, onDelete, value, disabled }: PropsType) => {
  const onUploadHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    if (e.target.files && e.target.files[0].size > maxFileSize * 1024 * 1024) {
      toast.error(`Файл не должен превышать ${maxFileSize}mb`);
      return;
    }

    if (e.target.files && e.target.files[0]) {
      onUpload(e.target.files[0]);
    }
  };

  const onDeleteHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();
    onDelete();
  };

  const [isHovered, setIsHovered] = React.useState(false);

  const onMouseHover = () => setIsHovered((prev) => !prev);

  return (
    <div
      className={styles.EventCover}
      onMouseOver={onMouseHover}
      onMouseOut={onMouseHover}
      style={{ backgroundColor: isHovered ? "#E9E9E9" : "#FFFFFF" }}
    >
      {value ? (
        <>
          <BaseImage
            className={styles.EventCover__image_uploaded}
            src={value}
            height="134"
            width="297"
          />
          <div
            className={styles.EventCover__button_delete}
            onClick={onDeleteHandler}
          >
            <TikIcon
              className={styles.EventCover__button_delete_icon}
              color="#000000"
            />
          </div>
        </>
      ) : (
        <>
          <EventCoverPreviewIcon
            className={styles.EventCover__image}
            color={isHovered ? "#FFFFFF" : "#F2F2F2"}
          />
          <BaseTypography className={styles.EventCover__text} variant="p">
            + Добавить
          </BaseTypography>
        </>
      )}
      <BaseInput
        className={styles.EventCover__input}
        onChange={onUploadHandler}
        disabled={disabled || !!value}
        hidden={!!value}
        type="file"
        accept=".jpg,.jpeg,.png"
      />
    </div>
  );
};

const MomoizedEventCover = React.memo(EventCover);

export default MomoizedEventCover;
