import { useNavigate } from "react-router-dom";
import { FC } from "react";
import clsx from "clsx";

import { BaseImage } from "../../components/base/BaseImage";

import { RoutesEnum } from "src/common/enums";
import svgWhiteLogo from "src/common/svg/svg-logo-white.svg";
import svgBlackLogo from "src/common/svg/svg-black-logo.svg";
import styles from "./Logo.module.scss";

type PropsType = {
  className?: string;
  variant?: "black" | "white";
};

export const Logo: FC<PropsType> = ({ className = "", variant = "black" }) => {
  const navigate = useNavigate();
  return (
    <div
      className={clsx([styles.Logo, className])}
      onClick={() => {
        navigate(RoutesEnum.HOME);
      }}
    >
      <BaseImage
        className={styles.Logo__img}
        src={variant === "black" ? svgBlackLogo : svgWhiteLogo}
        alt="ТЁРКА"
      />
    </div>
  );
};
