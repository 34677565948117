import { useWindowSize } from '../../hooks/useWindowSize';

export const checkScreenWidth = <T,>(w1440: T, w1024: T, w768: T, w480: T) => {
  const [width] = useWindowSize();
  if (width > 1024) {
    return w1440;
  } else if (width <= 1024 && width > 768) {
    return w1024;
  } else if (width <= 768 && width > 480) {
    return w768;
  } else if (width <= 480) {
    return w480;
  }
};
