import { z } from "zod";
import { nameValidate } from "../CommonFormSchema.schema";

const purposeSchema = z.object({
  meeting: z.boolean(),
  events: z.boolean(),
  all: z.boolean()

}).refine((value) => value.meeting || value.events || value.all);

// Функция валидации для даты
const dateValidator = z.date().refine((date) => {
  const minDate = new Date(1920, 0, 1); // 1 января 1920 года
  const currentDate = new Date();
  const eighteenYearsAgo = new Date(currentDate);
  eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18); // 18 лет назад от текущей даты

  return date >= minDate && date <= eighteenYearsAgo;
}, {
  message: "Дата должна быть в диапазоне от 1 января 1920 года до 18 лет назад от текущей даты.",
});

export const EventsFormSchema2Step = z.object({
  purpose: purposeSchema,
  fillFull: z.boolean(),
  isOld: z.boolean(),
  iWasAnonymous: z.boolean(),
})

export const MeetingFormSchema2Step = EventsFormSchema2Step.extend({
  gender: z.string().min(4, "надо выбрать"),
  birthday: dateValidator,
  aboutMe: z
    .string()
    .min(5, "надо рассказать подробнее")
    .max(500, "максимум 500 символов")
});

export const AddCredentialsEventsFormSchema = EventsFormSchema2Step.extend({
  firstName: nameValidate,
  lastName: nameValidate,
})

export const AddCredentialsMeetingFormSchema = MeetingFormSchema2Step.extend({
  firstName: nameValidate,
  lastName: nameValidate,
})