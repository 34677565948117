import React from "react";
import { Link } from "react-router-dom";

import { BaseButton } from "../../../components/base/BaseButton";
import { Stack } from "../../../components/base/Stack";
import { BaseTypography } from "../../../components/base/BaseTypography";
import { useGetAllowedParticipants } from "../../../api/persons";

import styles from "./AccountFillFull.module.scss";

const AccountFillFull = () => {
  const { data } = useGetAllowedParticipants();
  const counterAllowPeople = data;
  const allowed = ["Доступен", "Доступно", "Доступно"];
  const participant = ["собеседник", "собеседника", "собеседников"];

  const definition = (number: number, txt: string[]) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return txt[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  };

  return (
    <div className={styles.AccountFillFull}>
      <div className={styles.AccountFillFull__inner}>
        <Stack space={8} className={styles.AccountFillFull__title_block}>
          <BaseTypography variant={"h3"} format={"profile-fill-title"}>
            Заполните профиль
          </BaseTypography>

          <BaseTypography variant={"p"} format={"profile-fill-subtitle"}>
            Чтобы воспользоваться всеми возможностями Тёрки, необходимо
            заполнить анкету.
          </BaseTypography>
        </Stack>
        <div className={styles.AccountFillFull__button_block}>
          <Link to={"/signup/form/step/2?FillFull=true"}>
            <BaseButton
              className={styles.AccountFillFull__button}
              variant={"base-button-new-style-black"}
            >
              Заполнить анкету
            </BaseButton>
          </Link>
          <span className={styles.AccountFillFull__counter}>
            {counterAllowPeople !== undefined
              ? definition(counterAllowPeople, allowed)
              : null}{" "}
            <span style={{ color: "#024BCD", fontWeight: 600 }}>
              {counterAllowPeople}
            </span>{" "}
            {counterAllowPeople !== undefined
              ? definition(counterAllowPeople, participant)
              : null}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AccountFillFull;
