import React from "react";

type PropsType = {
    className?: string;
    children: React.ReactNode;
    index: number;
    value: number;
}

const Tab = ({ children, index, value, ...rest }: PropsType) => {
    return (
        <>
            <div
                aria-labelledby={`simple-tab-${index}`}
                // hidden={value !== index}
                style={value !== index ? { display: "none" } : {}}
                role="tabpanel"
                id={`simple-tabpanel-${index}`}
                {...rest}
            >
                {children}
            </div>
        </>
    )
};

const MemoizedTab = React.memo(Tab);

export default MemoizedTab;