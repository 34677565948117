import Dropdown, { Group, Option } from "react-dropdown";
import { PropsWithChildren } from "react";

import "./styles.scss";

export const BaseMenu = ({
  options,
  onChange,
  value,
}: PropsWithChildren<{
  options: (Group | Option | string)[];
  onChange?: (arg: Option) => void;
  value?: string;
}>) => {
  const isAccount =
    location.href.includes("meetings") ||
    location.href.includes("account") ||
    location.href.includes("/?tab") ||
    location.href.includes("events");

  const placeholder = isAccount
    ? `menu__account-placeholder`
    : `menu__main-placeholder`;

  const menuControl = isAccount ? "" : "menu__control";
  return (
    <Dropdown
      className="label"
      placeholderClassName={placeholder}
      controlClassName={menuControl}
      menuClassName="menu"
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
