import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

import Notification from './content/Notification';
import App from './App';

import { AuthProvider } from './context/AuthProvider';
import { queryClient } from './api/request';
import './index.scss';

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AuthProvider>
        <App />
        <Notification />
      </AuthProvider>
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById('root'),
);
