import React, { ReactNode, useState } from "react";
import clsx from "clsx";

import style from "./BaseDropDown.module.scss";
import { useCloseByOutsideClick } from "../../../hooks/useCloseByOutsideClick";
import { BaseIcon } from "../BaseIcon";
import { Link } from "react-router-dom";

type item = {
  label: string;
  onClick?: () => void;
  href?: string;
}

type PropsType = {
  children: ReactNode;
  list: item[];
}

const BaseDropDown = ({children, list}: PropsType) => {
  const [isActive, onToggle] = useState(false);
  const {ref} = useCloseByOutsideClick(onToggle);
  const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    onToggle(!isActive);
  };

  return (
    <div className={style.BaseDropDown}>
      <div className={style.BaseDropDown__children} onClick={onClickHandler} ref={ref}>

        {children}

        <BaseIcon
          className={clsx({
            [style.BaseDropDown__icon]: true,
            [style.BaseDropDown__rotate]: isActive
          })}
          viewBox="0 0 15 8"
          width="15"
          height="8"
          icon="TICK_DOWN"
        />
      </div>
      <nav className={clsx({
        [style.BaseDropDown__menu]: true,
        [style.BaseDropDown__active]: isActive,
      })}>
        <ul className={style.BaseDropDown__menu_list}>
          {list.map(({...item}, key) => (
            item.href
              ?
              <Link
                className={style.BaseDropDown__menu_item}
                to={item.href}
                key={key}
              >
                {item.label}
              </Link>
              :
              <li
                className={style.BaseDropDown__menu_item}
                onClick={item.onClick}
                key={key}
              >
                {item.label}
              </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default BaseDropDown;
