import React from 'react';

type UseFiltersResponseType<T> = {
    filters: T,
    onHandleFilters: <T>(payload: T) => void;
}

export const useFilters = <T,>(props: T): UseFiltersResponseType<T> => {
    // Filters of the request
    const [filters, setFilters] = React.useState<T>(props);

    // Main filter handler
    const onHandleFilters = <T,>(payload: T) => {
        setFilters((prev) => ({
            ...prev,
            ...payload
        }));
    }

    return { filters, onHandleFilters };
}
