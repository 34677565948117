import { FooterHome } from "src/layouts/FooterHome/FooterHome";
import { IconList } from "../../layouts/IconList/IconList";
import { Cards } from "../../layouts/CardsHome/Cards";

import containerStyles from "./home-container.module.scss";
import styles from "./Main/main.module.scss";
import "./index.scss";

export const HomePage = () => {
  return (
    <>
      <main className={styles.main}>
        <div className={containerStyles.container}>
          <div className={styles.main__wrapper}>
            <IconList />
            <Cards />
          </div>
        </div>
      </main>
      <FooterHome />
    </>
  );
};
