import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { checkAccessToken, getMe } from "src/api/hooks/personsHooks";
import { resetUserSession } from "src/api/request";
import { getFromStorage } from "src/common/local-storage";
import { cookieValue } from "src/common/functions/cookie";
import { useAuth } from "./useAuth";
import { RoutesEnum } from "src/common/enums";

export function useCheckUser() {
  const [isSuccess, setIsSuccess] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const { signout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  async function logoutUser() {
    const promises = await Promise.allSettled([checkAccessToken, getMe]);

    for (let i = 0; i < promises.length; i++) {
      if (promises[i].status == "rejected") {
        resetUserSession();
        setIsSuccess(false);
        setIsLoading(false);
        return;
      }
    }

    if (!cookieValue()?.accessToken) {
      signout(false);
      resetUserSession();
      setIsSuccess(false);
      setIsLoading(false);
      return;
    }

    try {
      const user = await getMe();

      if (!user) {
        const accessToken = getFromStorage("ACCESS_TOKEN");

        if (!accessToken) {
          return;
        }

        signout(false);
        resetUserSession();
        navigate(RoutesEnum.EVENTS);
        setIsSuccess(false);
        setIsLoading(false);
        return;
      } else {
        setIsSuccess(true);
        setIsLoading(false);
      }
    } catch (e) {
      setIsSuccess(false);
      setIsLoading(false);
    }
  }

  return {
    logoutUser,
    isSuccess,
    isLoading,
    location,
  };
}
