import React from "react";

import styles from "./EventList.module.scss";

type PropsType = {
    className?: string;
    children: React.ReactNode;
    label: string;
}

const EventList = ({ className = "", children, label }: PropsType) => {
    return (
        <>
            <ul className={[styles.EventList, className].join(" ").trim()}>
                <li className={styles.EventList__item}>
                    {label}
                </li>
                <li className={styles.EventList__item}>
                    <div className={styles.EventList__content}>
                        {children}
                    </div>
                </li>
            </ul>
        </>
    )
}

const MemoizedEventList = React.memo(EventList);

export default MemoizedEventList;