import { useParams } from 'react-router-dom';

import { BaseTypography } from '../../../components/base/BaseTypography';

import { checkScreenWidth } from '../../../common/functions/titleFunctions';
import gopniks from '../../../common/images/image-sidim.webp';
import './index.css';

export const SigninConfirmEmail = () => {
  const email = useParams<{ email: string }>().email;
  return (
    <section className="SigninConfirmEmail">
      <div className="SigninConfirmEmail__inner">
        <BaseTypography
          className="SigninConfirmEmail__title"
          variant="h2"
          fontSize={checkScreenWidth(32, 32, 30, 24)}
          fontWeight={600}
        >
          Письмо отправлено
        </BaseTypography>
        <BaseTypography
          className="SigninConfirmEmail__text"
          variant="p"
          fontSize={checkScreenWidth(26, 24, 20, 14)}
          fontWeight={400}
        >
          Для восстановления пароля перейдите по ссылке, которую мы отправили на{' '}
          {email}
        </BaseTypography>
        <div className="SigninConfirmEmail__image-wrapper">
          <img
            className="SigninConfirmEmail__image"
            src={gopniks}
            alt="Гопники"
          />
        </div>
      </div>
    </section>
  );
};
