import { FC, memo } from 'react';
import './index.scss';

type PropsType = {
  className?: string;
  name: string;
  id: string;
  onClick: () => void;
};

export const BaseTag: FC<PropsType> = memo(
  ({ className = '', name, id, onClick }) => {
    return (
      <div
        className={['base-tag', className].join(' ').trim()}
        onClick={onClick}
        id={id}
      >
        {name}
      </div>
    );
  },
);
