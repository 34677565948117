import { BaseImage } from 'src/components/base/BaseImage';

import { useWindowSize } from 'src/hooks/useWindowSize';
import other from 'src/common/images/image-footer.webp';
import w360 from 'src/common/images/image-footer-w360.webp';
import styles from './AccountFooter.module.scss';

const AccountFooter = () => {
    const [width] = useWindowSize();
    return (
        <>
            <div className={styles.AccountFooter}>
                <BaseImage
                    className={styles.AccountFooter__img}
                    src={width > 480 ? other : w360}
                />
            </div>
        </>
    );
};

export default AccountFooter;