import React from "react";
import clsx from "clsx";

import { BaseIcon } from "src/components/base/BaseIcon";

import { useCloseByOutsideClick } from "src/hooks/useCloseByOutsideClick";
import { OptionType } from "src/common/types/option-type";
import { useModal } from "src/hooks/useModal";
import styles from "./SelectContained.module.scss";

type CommonPropsType = {
  className?: string;
  placeholder?: string;
  options: OptionType[];
  disabled?: boolean;
  error?: boolean;
};

type PropsTypeMultiple = CommonPropsType & {
  onChange: (value: OptionType[]) => void;
  value?: OptionType[];
  multiple: true;
};

type PropsTypeSingle = CommonPropsType & {
  onChange: (value: OptionType) => void;
  value?: OptionType;
  multiple: false;
};

type PropsType = PropsTypeMultiple | PropsTypeSingle;

export default function SelectContained({
  className = "",
  multiple,
  placeholder = "",
  onChange,
  options,
  value,
  error,
  disabled = false,
}: PropsType) {
  const { toggle, toggleValue, isShowing } = useModal();
  const { ref } = useCloseByOutsideClick(toggleValue);

  // To open drop down menu by click on select
  const handleOpenSelect = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    toggle();
  };

  // To handle value
  // if multiple = true we are transforming of the array to string
  // if multiple = false we take name of option
  const handledValue = React.useMemo(() => {
    if (!value) return;
    if (multiple) {
      if (value?.length === 0) return;
      return value.map(({ name }, i) =>
        i === value.length - 1 ? name : name + ", ",
      );
    } else {
      return value?.name;
    }
  }, [value]);

  // To check value, was chose or not
  const isChecked = React.useMemo(
    () =>
      (value: OptionType[] = [], itemId: string): boolean =>
        !!value.find(({ id }) => id === itemId),
    [value],
  );

  // To handle selected item
  const handleSelection = React.useMemo(
    () =>
      (item: OptionType) =>
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (disabled) return;
        if (multiple) {
          if (value) {
            const copyValues = [...value];
            if (isChecked(copyValues, item.id)) {
              const newValue = copyValues.filter(({ id }) => item.id !== id);
              onChange(newValue);
            } else {
              const newValue = [...copyValues, item];
              onChange(newValue);
            }
          } else {
            onChange([item]);
          }
        } else {
          onChange(item);
          toggleValue(false);
        }
      },
    [value],
  );

  return (
    <div
      className={clsx({
        [styles.SelectContained]: true,
        [styles.SelectContained_required]: error,
        [className]: !!className,
      })}
      onClick={handleOpenSelect}
      ref={ref}
    >
      <div className={styles.SelectContained__input}>
        <div className={styles.SelectContained__content}>
          {handledValue ? (
            handledValue
          ) : (
            <span className={styles.BaseSelect__placeholder}>
              {placeholder}
            </span>
          )}
        </div>
        <BaseIcon
          className={clsx({
            [styles.SelectContained__icon]: true,
            [styles.SelectContained__icon_active]: isShowing,
          })}
          viewBox="0 0 14 9"
          height="9"
          width="14"
          icon="ARROW_DOWN_OUTLINED"
          fill="none"
        />
      </div>
      <div
        className={clsx({
          [styles.SelectContained__options]: true,
          [styles.SelectContained__options_hide]: isShowing,
        })}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {options.map((option) => (
          <div
            className={styles.SelectContained__option}
            onClick={handleSelection(option)}
            key={option.id}
          >
            <div>{option.name}</div>
            <BaseIcon
              className={styles.SelectContained__option_icon}
              viewBox="0 0 18 13"
              height="13"
              width="18"
              fill="none"
              icon="CHECK"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
