import { FC, ReactNode } from 'react';
import clsx from "clsx";

import styles from './SurveyTitle.module.scss';

type PropsType = {
  className?: string;
  children?: ReactNode;
};

const SurveyTitle: FC<PropsType> = ({ className = '', children }) => {
  return (
    <h2 className={clsx({
      [styles.SurveyTitle]: true,
      [className]: !!className,
    })}
    >
      {children}
    </h2>
  );
};

export default SurveyTitle;
