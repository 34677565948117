import * as yup from "yup";
// import { PollStepType } from "src/pages/signup-routes";

export const PASSWORD_REG_EXP = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/gi;
export const NAME_REG_EXP = /^(?=.*[а-яА-ЯёЁ])(?=.{2,})/gi;
export const CODE_REG_EXP = /\d\d\d\-\d\d\d/gi;

export const SignupStep1Validation = yup.object().shape({
  email: yup
    .string()
    .email("Некорректный email")
    .required("Это обязательное поле"),
  wasAcceptedPolicy: yup.boolean().equals([true], "Это обязательное поле"),
});

export const SignupStep2Validation = yup.object().shape({
  n1: yup.number().required("Это обязательное поле"),
  n2: yup.number().required("Это обязательное поле"),
  n3: yup.number().required("Это обязательное поле"),
  n4: yup.number().required("Это обязательное поле"),
});

export const SignupStep4Validation = yup.object().shape({
  firstName: yup
    .string()
    .required("Это обязательное поле")
    .matches(NAME_REG_EXP, "Некорректные данные")
    .max(30, "Максимальное количество символов - 30"),
  lastName: yup
    .string()
    .required("Это обязательное поле")
    .matches(NAME_REG_EXP, "Некорректные данные")
    .max(30, "Максимальное количество символов - 30"),
  liveInKaliningrad: yup.boolean().required("Это обязательное поле"),
  readyToChatOnline: yup.boolean().required("Это обязательное поле"),
});

export const EnterPasswordValidation = yup.object().shape({
  password: yup
    .string()
    .required("Это обязательное поле")
    .matches(PASSWORD_REG_EXP, "Не менее 8 символов: латиница и цифры"),
  confirmPassword: yup
    .string()
    .required("Это обязательное поле")
    .oneOf([yup.ref("password"), null], "Пароли должны совпадать"),
});

export const FeedbackValidation = yup.object().shape({
  score: yup.number().required("Обязательное поле"),
  qualities: yup.array().required("Обязательное поле"),
  comment: yup.string().required("Обязательное поле"),
});

// const arrayValidation = yup.mixed().test({
//   message: "Обязательно к заполнению",
//   test: (val) => {
//     const data = val.filter((i: PollStepType) => {
//       if (i.answerType === "text" && i.answerText.trim().length > 0) {
//         return true;
//       }
//
//       if (i.answerType === "checkbox" && i.answerChecked === true) {
//         return true;
//       }
//
//       if (
//         i.answerChecked === true &&
//         i.answerType === "text" &&
//         i.answerText.trim().length === 0
//       ) {
//         return true;
//       }
//     });
//
//     const isNotFullMyVariant = data.find((i: PollStepType) => {
//       if (
//         i.answerChecked === true &&
//         i.answerType === "text" &&
//         i.answerText.trim().length === 0
//       ) {
//         return true;
//       }
//     });
//
//     return !isNotFullMyVariant && data.length !== 0;
//   },
// });

// const radioValidator = yup.mixed().test({
//   message: "Обязательно к заполнению",
//   test: (val) =>
//     val.filter((i: PollStepType) => {
//       if (i.answerChecked === "true" || i.answerChecked === true) {
//         return true;
//       }
//     }).length !== 0,
// });

// const interlocutorsAgeValidator = yup.mixed().test({
//   message: "Обязательно к заполнению",
//   test: (val) =>
//     val.filter((i: PollStepType) => {
//       if (i.answerChecked === true && i.answerType === "text") {
//         return true;
//       }
//     }).length !== 0,
// });

// export const PollValidation = yup.object().shape({
//   primaryActivity: arrayValidation,
//   primaryEmployment: arrayValidation,
//   interestedScope: arrayValidation,
//   expectations: arrayValidation,
//   interlocutorsType: radioValidator,
//   interlocutorsAge: interlocutorsAgeValidator,
//   mentoringRelation: radioValidator,
//   educationRelation: radioValidator,
//   myIdealInterlocutor: yup.string().required("Обязательное поле"),
//   aboutMe: yup.string().required("Обязательное поле"),
//   username: yup.string().notRequired().nullable(),
//   gender: yup.string().required("Обязательное поле"),
//   birthday: yup
//     .string()
//     .matches(
//       /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/g,
//       "Дата должна быть в формате дд.мм.гггг",
//     )
//     .required("Обязательное поле"),
//   liveInKaliningrad: yup.boolean(),
//   readyToChatOnline: yup.boolean(),
//   firstName: yup
//     .string()
//     .required("Это обязательное поле")
//     .matches(NAME_REG_EXP, "Некорректные данные")
//     .max(30, "Максимальное количество символов - 30"),
//   lastName: yup
//     .string()
//     .required("Это обязательное поле")
//     .matches(NAME_REG_EXP, "Некорректные данные")
//     .max(30, "Максимальное количество символов - 30"),
// });

export const SigninValidation = yup.object().shape({
  email: yup
    .string()
    .email("Некорректный email")
    .required("Это обязательное поле"),
  password: yup.string().required("Это обязательное поле"),
});

export const EmailValidation = yup.object().shape({
  email: yup
    .string()
    .email("Некорректный email")
    .required("Это обязательное поле"),
});

export const EventScheme = yup.object().shape(
  {
    organizer: yup
      .string()
      .min(1, "Это обязательное поле")
      .max(80, "Организатор не более 80 символов")
      .required("Это обязательное поле"),
    title: yup
      .string()
      .min(1, "Это обязательное поле")
      .max(80, "Заголовок не более 80 символов")
      .required("Это обязательное поле"),
    text: yup
      .string()
      .min(1, "Это обязательное поле")
      .max(1000, "Описание не более 1000 символов")
      .required("Это обязательное поле"),
    address: yup
      .string()
      .min(1, "Это обязательное поле")
      .required("Это обязательное поле"),
    maxParticipants: yup
      .number()
      .min(1, "Не менее 1 участника")
      .max(1000, "Не более 1000 участников")
      .required("Это обязательное поле"),
    meetingAt: yup.string().required("Это обязательное поле"),
    eventType: yup
      .array()
      .max(3, "Не более 3 тегов")
      .min(1, "Не менее 1 тега")
      .required("Это обязательное поле"),
    telegramLink: yup.string().when("telegramLink", (value) => {
      if (value?.length > 0) {
        return yup
          .string()
          .matches(
            /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([+a-zA-Z0-9_-]*)\/?$/,
            "Не валидная ссылка на телеграм",
          )
          .required("Это обязательное поле");
      } else {
        return yup.string().notRequired();
      }
    }),
    isFree: yup.boolean().required("Это обязательное поле"),
    cost: yup.number().when("isFree", {
      is: false,
      then: yup
        .number()
        .min(1, "Стоимость должна быть больше нуля")
        .required("Это обязательное поле"),
    }),
    ticketUrl: yup.string().when("isFree", (isFree) => {
      if (!isFree) {
        return (
          yup
            .string()
            // .matches(
            //   /^https:\/\/store\.terkatalk\.ru.*$/,
            //   "Не валидная ссылка на магазин",
            // )
            .required("Это обязательное поле")
        );
      } else {
        return yup.string().notRequired();
      }
    }),
    vendorCode: yup.string().when("isFree", (isFree) => {
      if (!isFree) {
        return yup
          .string()
          .min(1, "Это обязательное поле")
          .required("Это обязательное поле");
      } else {
        return yup.string().notRequired();
      }
    }),
  },
  [
    ["telegramLink", "telegramLink"],
    ["ticketUrl", "ticketUrl"],
    ["vendorCode", "vendorCode"],
  ],
);
