import React from "react";
import { DateTime } from "luxon";

export function useDaysTillEvent<T>(
  date: string | undefined,
  dependecies: T[],
) {
  const daysTillEvent = React.useMemo(() => {
    if (!date) return 0;

    const now = DateTime.now();
    const end = DateTime.fromISO(date);
    const dif = end.diff(now, "day");
    const days = dif.toObject().days;

    return !days || days < -1 ? -1 : Math.ceil(days);
  }, [dependecies]);

  return daysTillEvent;
}
