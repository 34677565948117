import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { Stack } from "../../../components/base/Stack";
import { BaseTypography } from "../../../components/base/BaseTypography";
import { BaseInput } from "../../../components/base/BaseInput";
import { getProfile } from "../../../api/persons";

import styles from "../styles.module.scss";
import { getMe } from "../../../api/hooks/personsHooks";

const AccountCredentials = () => {
  const { register, setValue } = useForm();

  //с пустым массивом зависимостей, данные не обновляемые
  useEffect(() => {
    (async () => {
      const data = await getMe();

      if (!data) return;

      setValue("firstName", data.firstName);
      setValue("lastName", data.lastName);
      setValue("email", data.email);
    })();
  }, []);

  return (
    <Stack space={30}>
      <span className={styles.Poll__line}>
        <BaseTypography
          variant={"h2"}
          format={"landing-title-lvl-4"}
          className={styles.Account__title}
        >
          Личные данные
        </BaseTypography>
      </span>
      <form>
        <Stack space={16}>
          <div className={styles.Poll__grid}>
            <Stack space={16}>
              <BaseTypography variant={"p"} format={"profile-sub-title"}>
                Имя
              </BaseTypography>
              <BaseInput
                className={styles.Poll__input}
                {...register("firstName")}
                disabled={true}
              />
            </Stack>
            <Stack space={16}>
              <BaseTypography variant={"p"} format={"profile-sub-title"}>
                Фамилия
              </BaseTypography>
              <BaseInput
                className={styles.Poll__input}
                {...register("lastName")}
                disabled={true}
              />
            </Stack>
          </div>
          <Stack space={16}>
            <BaseTypography variant={"p"} format={"profile-sub-title"}>
              Email
            </BaseTypography>
            <BaseInput
              className={styles.Poll__input}
              {...register("email")}
              disabled={true}
            />
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default AccountCredentials;
