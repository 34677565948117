import { TextareaHTMLAttributes, ForwardedRef, forwardRef, FC } from 'react';

import './index.scss';
import clsx from "clsx";

type Attributes = TextareaHTMLAttributes<HTMLTextAreaElement>;
type PropsType = {
  className?: string;
  error?: boolean;
};

export const BaseTextarea: FC<PropsType & Attributes> = forwardRef(
  ({ className = '', error, ...rest }, ref: ForwardedRef<HTMLTextAreaElement>) => {
    return (
      <textarea
        className={clsx({
          'base-textarea': true,
          'base-textarea_required': error,
          [className]: !!className,
        })}
        {...rest}
        ref={ref}
      />
    );
  },
);

// import React, { TextareaHTMLAttributes } from "react";
// import clsx from "clsx";
//
// import './index.scss';
//
// type Attributes = TextareaHTMLAttributes<HTMLTextAreaElement>;
//
// type PropsType = {
//   className?: string;
//   error?: boolean;
//   // placeholder?: string;
// };
// function BaseTextarea({className = '', error, ...rest}: PropsType & Attributes) {
//   return (
//     <textarea
//       //className={['base-textarea', className].join(' ').trim()}
//       className={clsx({
//         'base-textarea': true,
//         'base-textarea_required': error,
//         [className]: !!className,
//       })}
//       {...rest}
//     />
//   );
// }
//
// export default BaseTextarea;