import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-hot-toast/headless";

import { CredentialsForm } from "src/layouts/CredentialsForm";
import { BaseButton } from "src/components/base/BaseButton";
import { delFromStorage } from "src/common/local-storage";
import { Stack } from "../../../components/base/Stack";
import { SurveySubtitle, SurveyTitle } from "../../../content";
import SubscribeMeets from "../../../content/SubscribeMeets/SubscribeMeets";
import BaseCheckboxItem from "../../../components/base/BaseCheckboxItem/BaseCheckboxItem";
import AgeSelector from "../../../content/AgeSelector/AgeSelector";
import { SignUpRoutesContext } from "../index";
import { BaseInputSocials } from "../../../components/base";
import { BaseLoader } from "../../../components/base/BaseLoader";
import { useAuth } from "../../../hooks/useAuth";
import { checkTelegram } from "../../../api/hooks/personsHooks";
import {
  // preparePackageDataAll,
  // preparePackageDataEvents,
  // preparePackageDataMeeting,
  preparePackageData,
  preparePackageDataUpdateProfile,
  preparePackageMigrateUser,
} from "./PreparePackage";

import { PurposeType } from "../signup-step-2";
import {
  FormSchema3StepAll,
  FormSchema3StepEvents,
  FormSchema3StepMeeting,
} from "./FormSchema3Step.schema";
import "./index.scss";
import { updateProfile } from "../../../api/persons";
import { CategorySelector } from "src/content/CategorySelector";
import { StackCategoryWrapper } from "src/content/StackCategoryWrapper/StackCategoryWrapper";
import { StackCategory } from "src/content/StackCategory/StackCategory";
import { StackRomantic } from "src/content/StackRomantic/StackRomantic";

export type FormSchema3DataMeetingType = z.infer<typeof FormSchema3StepMeeting>;
export type FormSchema3DataEventsType = z.infer<typeof FormSchema3StepEvents>;
export type FormSchema3DataAllType = z.infer<typeof FormSchema3StepAll>;

export type FormSchema3DataType = {
  isNotifyByEvents?: boolean | undefined;
  age?: {
    from: number;
    till: number;
  };
  gender?: string;
  birthday?: string;
  poll?: {
    communication?: {
      professional?: number;
      romantic?: number;
      chat?: number;
    };
    // categories?: {
    //   professional?: number;
    //   romantic?: number;
    //   chat?: number;
    // };
    gender?: {
      male?: boolean;
      female?: boolean;
    };
    aboutMe?: string;
  };
  socials?: {
    telegram?: string;
    vk?: string;
    whatsapp?: string;
    facebook?: string;
    instagram?: string;
  };
};

export const SignupStep3 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { state, handleState } = useContext(SignUpRoutesContext);

  const { signup } = useAuth();

  const [showMeeting, setShowMeeting] = useState(false);
  const [showEvent, setShowEvent] = useState(false);

  // display block after 2 step choice
  const [purpose] = useState<PurposeType>(state.step2.purpose);

  useEffect(() => {
    if (purpose) {
      purpose.meeting && setShowMeeting(true);
      purpose.events && setShowEvent(true);
      if (purpose.all) {
        setShowEvent(true);
        setShowMeeting(true);
      }
    }
  }, [purpose]);

  const formSchema = purpose.meeting
    ? FormSchema3StepMeeting
    : purpose.events
    ? FormSchema3StepEvents
    : FormSchema3StepAll;

  const {
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormSchema3DataType>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: {
      age: {
        from: 18,
        till: 56,
      },
      isNotifyByEvents: false,
      poll: {
        communication: {
          professional: 5,
          romantic: 2,
          chat: 5,
        },
        gender: {
          male: false,
          female: false,
        },
        aboutMe: "",
      },
      socials: {
        telegram: "",
        vk: "",
        whatsapp: "",
        facebook: "",
        instagram: "",
      },
    },
  });

  const stateWatch = watch();

  // To show age, if romantic value more then 0
  const showAge = !!(
    stateWatch.poll?.communication?.romantic &&
    stateWatch.poll.communication.romantic > 0
  );

  useEffect(() => {
    setValue("isNotifyByEvents", showEvent);
  }, [showEvent]);

  const sendMeeting = async (data: FormSchema3DataMeetingType) => {
    try {
      const validateTelegram = await checkTelegram({
        telegram: data.socials.telegram,
      });

      if (validateTelegram.isExists) {
        toast.error("Аккаунт с таким Telegram уже зарегистрирован.");
        return;
      }

      await signup(preparePackageData(data, state));
      navigate("/signup/form/step/4");
    } catch (e: any) {
      console.error("send with error", e);
      throw new Error(e);
    }
  };

  const sendEvents = async (data: FormSchema3DataEventsType) => {
    try {
      await signup(preparePackageData(data, state));
      navigate("/signup/form/step/4");
    } catch (e: any) {
      console.error("send with error", e);
      throw new Error(e);
    }
  };

  const sendAll = async (data: FormSchema3DataAllType) => {
    try {
      const validateTelegram = await checkTelegram({
        telegram: data.socials.telegram,
      });

      if (validateTelegram.isExists) {
        toast.error("Аккаунт с таким Telegram уже зарегистрирован.");
        return;
      }

      await signup(preparePackageData(data, state));
      navigate("/signup/form/step/4");
    } catch (e: any) {
      console.error("send with error", e);
      throw new Error(e);
    }
  };

  const sendFillFull = async (data: any) => {
    try {
      await updateProfile(preparePackageDataUpdateProfile(data, state));
      navigate("/signup/form/step/4");
    } catch (e: any) {
      console.error("send with error", e);
      throw new Error(e);
    }
  };

  const updateOldUser = async (data: any) => {
    try {
      await updateProfile(preparePackageMigrateUser(data, state));
      navigate("/signup/form/step/4");
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      delFromStorage("CREDENTIALS");

      if (state.step2.fillFull) {
        if (state.step2.isOld) {
          await updateOldUser(data);
        } else await sendFillFull(data);
      } else {
        if (purpose.meeting) {
          await sendMeeting(data);
        } else if (purpose.events) {
          await sendEvents(data);
        } else if (purpose.all) {
          await sendAll(data);
        }
      }
      setIsLoading(false);
    } catch (e: any) {
      console.error("onSubmit", e);
      throw new Error(e);
    } finally {
      setIsLoading(false);
      delFromStorage("CREDENTIALS");
    }
  };

  return (
    <CredentialsForm title="">
      <div className="signup-step-3">
        <form
          className="signup-step-3__form"
          onSubmit={handleSubmit(onSubmit)}
          id="signup-step-3"
        >
          <Stack space={28}>
            {showMeeting && (
              <Stack space={28}>
                <Stack space={8}>
                  <Stack space={24}>
                    <Stack space={8}>
                      <SurveyTitle>Категории общения</SurveyTitle>
                      <SurveySubtitle className="signup-step-3__subtitle">
                        Укажите, насколько вы заинтересованы в категории по
                        шкале до 10 и свои предпочтения
                      </SurveySubtitle>
                    </Stack>
                    <StackCategoryWrapper space={24}>
                      <Stack space={24}>
                        <StackCategory label="Профессиональные">
                          <Controller
                            name="poll.communication.professional"
                            control={control}
                            defaultValue={
                              stateWatch.poll?.communication?.professional
                            }
                            render={({ field }) => (
                              <CategorySelector
                                value={field.value}
                                onChange={(_, value) => {
                                  field.onChange(value);
                                }}
                                label={"professional"}
                              />
                            )}
                          />
                        </StackCategory>
                        <StackCategory label="Просто общение">
                          <Controller
                            name="poll.communication.chat"
                            control={control}
                            defaultValue={stateWatch.poll?.communication?.chat}
                            render={({ field }) => (
                              <CategorySelector
                                value={field.value}
                                onChange={(_, value) => {
                                  field.onChange(value);
                                }}
                                label={"chat"}
                              />
                            )}
                          />
                        </StackCategory>
                      </Stack>
                      <Stack space={4}>
                        <SurveyTitle className="signup-step-3__gender-title">
                          Я готов(-а) общаться с:
                        </SurveyTitle>
                        <Stack columns={2} space={9}>
                          <Controller
                            name={"poll.gender.male"}
                            control={control}
                            defaultValue={stateWatch.poll?.gender?.male}
                            render={({ field }) => (
                              <BaseCheckboxItem
                                answerTitle={"Мужчина"}
                                answerChecked={field.value}
                                error={!!errors?.poll?.gender}
                                onChange={(e: boolean) => {
                                  field.onChange(e);
                                }}
                              />
                            )}
                          />
                          <Controller
                            name={"poll.gender.female"}
                            control={control}
                            defaultValue={stateWatch.poll?.gender?.female}
                            render={({ field }) => (
                              <BaseCheckboxItem
                                answerTitle={"Женщина"}
                                answerChecked={field.value}
                                error={!!errors?.poll?.gender}
                                onChange={(e: boolean) => {
                                  field.onChange(e);
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </Stack>
                    </StackCategoryWrapper>
                  </Stack>
                  <StackCategoryWrapper space={16}>
                    <StackRomantic description="Сердечко рядом с именем означает, что собеседник не против романтического общения">
                      <Controller
                        name="poll.communication.romantic"
                        control={control}
                        defaultValue={stateWatch.poll?.communication?.romantic}
                        render={({ field }) => (
                          <CategorySelector
                            value={field.value}
                            onChange={(_, value) => {
                              field.onChange(value);

                              if (value === 0) {
                                reset({
                                  ...stateWatch,
                                  age: {
                                    from: 18,
                                    till: 56,
                                  },
                                });
                              }
                            }}
                            label={"romantic"}
                          />
                        )}
                      />
                    </StackRomantic>
                    {showAge && (
                      <Stack>
                        <SurveySubtitle className="signup-step-3__label">
                          Укажите возраст
                        </SurveySubtitle>
                        <Controller
                          name={"age"}
                          control={control}
                          defaultValue={stateWatch.age}
                          render={({ field }) => (
                            <AgeSelector
                              value={field.value}
                              onChange={(event, value) => {
                                if (Array.isArray(value)) {
                                  field.onChange({
                                    from: value[0],
                                    till: value[1],
                                  });
                                }
                              }}
                            />
                          )}
                        />
                      </Stack>
                    )}
                  </StackCategoryWrapper>
                </Stack>
                <Stack space={16}>
                  <SurveyTitle>
                    Как с вами может связаться ваш собеседник
                  </SurveyTitle>
                  <Stack space={14}>
                    <Controller
                      name={"socials.telegram"}
                      control={control}
                      render={({ field }) => (
                        <BaseInputSocials
                          className="signup-step-3__input"
                          variant={"TG"}
                          placeholder="@username"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          error={!!errors?.socials?.telegram?.message}
                        />
                      )}
                    />
                    <Controller
                      name={"socials.vk"}
                      control={control}
                      render={({ field }) => (
                        <BaseInputSocials
                          className="signup-step-3__input"
                          variant={"VK"}
                          placeholder="VK"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={"socials.whatsapp"}
                      control={control}
                      render={({ field }) => (
                        <BaseInputSocials
                          className="signup-step-3__input"
                          variant={"WA"}
                          placeholder="Whatsapp"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={"socials.facebook"}
                      control={control}
                      render={({ field }) => (
                        <BaseInputSocials
                          className="signup-step-3__input"
                          variant={"FB"}
                          placeholder="Facebook"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={"socials.instagram"}
                      control={control}
                      render={({ field }) => (
                        <BaseInputSocials
                          className="signup-step-3__input"
                          variant={"IG"}
                          placeholder="Instagram"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Stack>
            )}
            {showEvent && (
              <Stack space={28}>
                <Controller
                  name={"isNotifyByEvents"}
                  defaultValue={stateWatch.isNotifyByEvents}
                  control={control}
                  render={({ field }) => (
                    <SubscribeMeets
                      checked={stateWatch.isNotifyByEvents}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />

                {purpose.events && !purpose.meeting && !purpose.all && (
                  <Stack space={14}>
                    <SurveyTitle>Укажите telegram</SurveyTitle>
                    <Controller
                      name={"socials.telegram"}
                      control={control}
                      render={({ field }) => (
                        <BaseInputSocials
                          className="signup-step-3__input"
                          variant={"TG"}
                          placeholder="@username"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          error={!!errors?.socials?.telegram?.message}
                        />
                      )}
                    />
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </form>
        {isLoading ? (
          <BaseLoader />
        ) : (
          <div className="signup-step-3__button-box">
            <BaseButton
              className="signup-step-3__back-button"
              variant="base-button-go-back"
              onClick={() => {
                navigate("/signup/form/step/2");
                handleState({ step3: null });
              }}
            />
            <BaseButton
              className="signup-step-3__button"
              variant="base-button-new-style-black-with-white-arrow"
              form="signup-step-3"
              type="submit"
            >
              Продолжить
            </BaseButton>
          </div>
        )}
      </div>
    </CredentialsForm>
  );
};
