import { HomePage } from "./HomePage";

import video from "../../common/video/video-promo-bg.mp4";
import space from "../../common/images/svg/space.svg";

export const Home = () => {
  return (
    <div className="home">
      <video autoPlay loop muted playsInline className="video">
        <source src={video} type="video/mp4" />
      </video>
      <img src={space} alt="" className="body-bg" />
      <HomePage />
    </div>
  );
};
