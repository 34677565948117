import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { CredentialsForm } from "../../../layouts/CredentialsForm";
import { BaseInputError } from "../../../components/base/BaseInputError";
import { BaseButton } from "../../../components/base/BaseButton";
import { BaseInput } from "../../../components/base/BaseInput";
import { Stack } from "src/components/base/Stack";

import { EmailValidation } from "../../../common/validation/index";
import { resetPassword } from "../../../api/hooks/personsHooks";
import { useNavigate } from "react-router-dom";
import "./index.css";

type SigninResetPasswordType = {
  email: string;
};

export const SigninResetPassword = () => {
  const navigate = useNavigate();
  const form = useForm<SigninResetPasswordType>({
    resolver: yupResolver(EmailValidation),
  });

  const onSubmit = async (data: SigninResetPasswordType) => {
    try {
      await resetPassword(data);
      navigate("/signin/confirm-email/" + data.email);
    } catch (e) {
      form.setError("email", {
        message: "Произошла ошибка",
      });
    }
  };

  const { errors } = form.formState;
  return (
    <CredentialsForm title="Восстановление пароля">
      <div className="SigninResetPassword">
        <form
          className="SigninResetPassword__form"
          onSubmit={form.handleSubmit(onSubmit)}
          id="form-signin-reset-password-id"
        >
          <Stack space={10}>
            <BaseInput
              className="SigninResetPassword__input"
              placeholder="Ваша электронная почта"
              {...form.register("email", {
                onChange: () => {
                  form.trigger("email");
                },
              })}
            />
            <BaseInputError<SigninResetPasswordType>
              className="SigninResetPassword__issue"
              errors={errors}
              field="email"
            />
          </Stack>
        </form>
        <div className="SigninResetPassword__control">
          <BaseButton
            className="SigninResetPassword__control-button"
            variant="base-button-new-style-black"
            type="submit"
            form="form-signin-reset-password-id"
          >
            Подтвердить
          </BaseButton>
        </div>
      </div>
    </CredentialsForm>
  );
};
