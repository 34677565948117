import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";

import { BaseInputError } from "src/components/base/BaseInputError";
import { BaseInput } from "src/components/base/BaseInput";
import { Button } from "src/components/base/Button";
import { Modal } from "src/components/base/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { SigninValidation } from "src/common/validation";
import { EventActionType } from "src/common/types/event-action.type";
import { useCheckOrder } from "src/api/hooks/ordersHooks";
import { RoutesEnum } from "src/common/enums";
import { useAuth } from "src/hooks/useAuth";
import styles from "./AuthModal.module.scss";

type PropsType = {
  isOpen: boolean;
  toggle: () => void;
  eventAction: EventActionType;
  onAddUserToParticipantsList: (eventId: string) => Promise<void>;
  onAddUserToWaitersList: (eventId: string) => Promise<void>;
};

type SigninType = {
  email: string;
  password: string;
};

export default function AuthModal(props: PropsType) {
  const [isLoading, setIsLoading] = React.useState(false);
  const checkOrder = useCheckOrder();
  const navigate = useNavigate();
  const location = useLocation();
  const form = useForm<SigninType>({
    resolver: yupResolver(SigninValidation),
  });

  const { signin } = useAuth();

  const handleTelegramLink = (telegramLink: string | undefined) => {
    if (!telegramLink) return;
    window.open(`${telegramLink}`);
  };

  const onSubmit = async (data: SigninType) => {
    try {
      setIsLoading(true);
      const token = await signin(data, false);
      const user = JSON.parse(window.atob(token?.split(".")[1]));

      if (user.id !== props.eventAction.ownerId) {
        if (
          props.eventAction.eventAction === "participants" &&
          props.eventAction.eventId
        ) {
          if (props.eventAction.isFree) {
            props.onAddUserToParticipantsList(props.eventAction.eventId);
            handleTelegramLink(props.eventAction.telegramLink);
          } else {
            try {
              const ticketBought = await checkOrder.mutateAsync({
                eventId: props.eventAction.eventId,
              });

              if (ticketBought) {
                props.onAddUserToParticipantsList(props.eventAction.eventId);
                handleTelegramLink(props.eventAction.telegramLink);
              } else {
                window.location.href = props.eventAction.ticketUrl!;
              }
            } catch (e) {
              toast.error("Что-то пошло не так");
              return;
            }
          }
        }

        if (
          props.eventAction.eventAction === "waiters" &&
          props.eventAction.eventId
        ) {
          props.onAddUserToWaitersList(props.eventAction.eventId);
        }
      }

      setTimeout(() => {
        const route =
          RoutesEnum.EVENTS + "/events/" + props.eventAction.eventId;
        setIsLoading(false);
        props.toggle();

        if (location.pathname === route) {
          navigate(0);
        } else {
          navigate(route);
        }
      }, 500);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const { errors } = form.formState;

  return (
    <Modal {...props} className={styles.AuthModal}>
      <div className={styles.AuthModal__inner}>
        <div className={styles.AuthModal__title}>
          Войдите в Тёрку чтобы участвовать
        </div>
        <form
          className={styles.AuthModal__form}
          onSubmit={form.handleSubmit(onSubmit)}
          id="auth-modal-form"
        >
          <div>
            <BaseInput
              className={styles.AuthModal__input}
              placeholder="Эл. почта"
              type="text"
              {...form.register("email")}
            />
            <BaseInputError<SigninType> errors={errors} field={"email"} />
          </div>
          <div>
            <BaseInput
              className={styles.AuthModal__input}
              placeholder="Пароль"
              type="password"
              {...form.register("password")}
            />
            <BaseInputError<SigninType> errors={errors} field={"password"} />
          </div>
        </form>
        <div className={styles.AuthModal__issue}>
          <div className={styles.AuthModal__text}>Ещё нет аккаунта?</div>
          <Link
            className={styles.AuthModal__link_signup}
            to={RoutesEnum.SIGNUP}
          >
            Зарегистрироваться
          </Link>
        </div>
        <div className={styles.AuthModal__buttons}>
          <Button
            className={styles.AuthModal__button}
            fullWidth={true}
            variant="contained_nuar"
            isLoading={isLoading}
            type="submit"
            form="auth-modal-form"
          >
            Войти
          </Button>
          <Link
            className={styles.AuthModal__link_restore}
            to={RoutesEnum.RESTORE}
          >
            Забыли пароль?
          </Link>
        </div>
      </div>
    </Modal>
  );
}
