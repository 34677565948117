import { BaseTypography } from 'src/components/base/BaseTypography';
import styles from './ProfileTitle.module.scss';

type PropsType = {
  title: string;
  after?: React.ReactNode;
};

const ProfileTitle = ({ title, after }: PropsType) => {
  return (
    <div className={styles.ProfileTitle}>
      <h2 className={styles.ProfileTitle__title}>
        {title}
      </h2>
      {after}
    </div>
  );
}

export default ProfileTitle;
