import { PropsWithChildren } from 'react';
import './styles.scss';

export function BaseLabel({
  className,
  children,
  label,
  ...rest
}: PropsWithChildren<{ label: string; className?: string }>) {
  return (
    <label className={['base-label', className].join(' ').trim()} {...rest}>
      {label}
      {children}
    </label>
  );
}
