import { useTransition, animated } from 'react-spring';
import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

type PropsType = {
  children: ReactNode;
};

export const BaseAnimation: FC<PropsType> = ({ children }) => {
  const location = useLocation();
  const transitions = useTransition(location, {
    from: {
      transition: '.15s',
      transform: 'translateX(-10px)',
      opacity: 0.75,
    },
    enter: {
      transition: '.15s',
      transform: 'translateX(0px)',
      opacity: 1,
    },
  });
  return transitions((props, item) => (
    <animated.div style={props}>{children}</animated.div>
  ));
};
