import React, { useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";

import { Cropper, EventForm } from "src/content";
import { LayoutAccount } from "src/layouts";
import { BaseButton } from "src/components/base/BaseButton";
import { BaseImage } from "src/components/base/BaseImage";
import { Button } from "src/components/base/Button";

import {
  EventType,
  useCreateEvent,
  useEventStatuses,
  useUploadImage,
} from "src/api/hooks/eventsHooks";
import { EventScheme } from "src/common/validation";
import { OptionType } from "src/common/types/option-type";
import { CloseEventModal } from "src/modals/CloseEventModal";
import { useModal } from "src/hooks/useModal";
import imageGopnikCreateEvent from "src/common/images/image-gopnik-create-event.webp";
import styles from "./EventCreate.module.scss";
import useUploadCover from "src/hooks/useUploadCover";

const EventCreatePage = () => {
  const navigate = useNavigate();
  const {
    uploadedCover,
    croppedCover,
    handleDeleteCropped,
    handleEditCover,
    handleUploadCover,
    handleUploadCropped,
    isOpenCropper,
    toggleCropper,
  } = useUploadCover();

  // Form initialize
  const form = useForm<EventType>({
    defaultValues: {
      title: "",
      text: "",
      address: "",
      maxParticipants: undefined,
      isFree: false,
      cost: 0,
    },
    resolver: yupResolver(EventScheme),
  });

  // Form state
  const errors = form.formState.errors;
  const state = form.watch();

  // Form state handler
  const onHandleState = React.useCallback(
    (
      key: keyof EventType,
      value: string | number | OptionType | OptionType[] | boolean,
    ) => {
      form.setValue(key, value, {
        shouldValidate: true,
      });
    },
    [],
  );

  React.useEffect(() => {
    if (state.isFree) {
      form.reset({
        ...state,
        cost: 0,
        vendorCode: "",
        ticketUrl: "",
      });
    }
  }, [state.isFree]);

  // Requests to api
  const { data: eventStatuses } = useEventStatuses();
  const createEvent = useCreateEvent();
  const uploadImage = useUploadImage();

  const [isLoading, setIsLoading] = React.useState(false);

  // Event status id state
  const [eventStatusId, setEventStatusId] = React.useState<
    string | undefined
  >();

  // Form submit handler
  const onSubmitHandler = async (data: EventType) => {
    if (!eventStatusId) return;

    const formData = new FormData();

    try {
      setIsLoading(true);

      const createdEvent = await createEvent.mutateAsync({
        ...data,
        eventStatusId,
      });

      // If cropped image exists
      if (croppedCover) {
        formData.set("cover", croppedCover as Blob);

        await uploadImage.mutateAsync({
          id: createdEvent.id,
          formData,
        });

        formData.delete("cover");
      }

      toast.success("Запись успешно создана");
      navigate(-1);
    } catch (e) {
      toast.error("Не удалось создать запись");
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const publish = eventStatuses ? eventStatuses[0]?.id : "";
  const archive = eventStatuses ? eventStatuses[1]?.id : "";

  const { toggle, isShowing } = useModal();

  return (
    <>
      <LayoutAccount
        title="Создать мероприятие"
        isArrowGoBack={true}
        onClick={toggle}
      >
        <div
          className={styles.EventCreatePage}
          onSubmit={form.handleSubmit(onSubmitHandler)}
        >
          <form
            className={styles.EventCreatePage__form}
            id="event-create-form-id"
          >
            <EventForm
              onHandleState={onHandleState}
              onUploadCover={handleUploadCover}
              onDeleteCover={handleDeleteCropped}
              onEditCover={handleEditCover}
              croppedCover={croppedCover}
              errors={errors}
              state={state}
            />
            <div className={styles.EventCreatePage__buttons}>
              <Button
                className={styles.EventCreatePage__button}
                onClick={() => setEventStatusId(publish)}
                fullWidth={true}
                disabled={isLoading}
                variant="contained_nuar"
                form="event-create-form-id"
                type="submit"
              >
                Опубликовать
              </Button>
              <BaseButton
                className={styles.EventCreatePage__button}
                onClick={() => setEventStatusId(archive)}
                variant="base-text-btn"
                disabled={isLoading}
                form="event-create-form-id"
                type="submit"
              >
                Скрыть
              </BaseButton>
            </div>
          </form>
          <BaseImage
            className={styles.EventCreatePage__image}
            src={imageGopnikCreateEvent}
            height="516"
            width="407.15"
          />
        </div>
      </LayoutAccount>
      <Cropper
        isOpen={isOpenCropper}
        toggle={toggleCropper}
        file={uploadedCover as File}
        onChange={handleUploadCropped}
        onCancel={toggleCropper}
      />
      <CloseEventModal isOpen={isShowing} toggle={toggle} />
    </>
  );
};

export default EventCreatePage;
