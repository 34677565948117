import { useNavigate } from "react-router-dom";

import { BaseTypography } from "src/components/base/BaseTypography";
import { BaseImage } from "src/components/base/BaseImage";

import svgArrowBack from "src/common/svg/svg-arrow-back.svg";
import styles from "./GoBack.module.scss";
import clsx from "clsx";

type PropsType = {
  className?: string;
  title?: string;
  to?: string;
  onClick?: () => void;
};

const GoBack = ({
  className = "",
  to,
  title = "Назад",
  onClick,
}: PropsType) => {
  const navigate = useNavigate();
  return (
    <div
      className={clsx({
        [styles.GoBack]: true,
        [className]: !!className,
      })}
      onClick={
        onClick ? () => onClick() : () => (to ? navigate(to) : navigate(-1))
      }
    >
      <BaseImage className={styles.GoBack__image} src={svgArrowBack} />
      <BaseTypography className={styles.GoBack__text} variant="p">
        {title}
      </BaseTypography>
    </div>
  );
};

export default GoBack;
