import { FC, ReactNode } from 'react';

import { BaseTypography } from '../../components/base/BaseTypography';

import { checkScreenWidth } from '../../common/functions/titleFunctions';
import './index.css';

type PropsType = {
  title: string;
  children: ReactNode;
  style?: React.CSSProperties | undefined;
};

export const CredentialsForm: FC<PropsType> = ({ title, children, style }) => {
  return (
    <div className="CredentialsForm">
      <BaseTypography
        fontWeight={400}
        fontSize={checkScreenWidth(32, 32, 26, 20)}
        variant="h2"
      >
        {title}
      </BaseTypography>
      <div className="CredentialsForm__content" style={style}>
        {children}
      </div>
    </div>
  );
};
