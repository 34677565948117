import toast from "react-hot-toast";
import clsx from "clsx";

import { Button } from "src/components/base/Button";

import { EventActionType } from "src/common/types/event-action.type";
import { useCheckOrder } from "src/api/hooks/ordersHooks";
import styles from "./EventPreview.module.scss";

export type EventControlType = {
  onAddUserToParticipantsList: (eventId: string) => Promise<void>;
  onAddUserToWaitersList: (eventId: string) => Promise<void>;
  onLeaveEvent: (eventId: string) => Promise<void>;
};

export type ButtonSwitchType = EventControlType & {
  className?: string;
  personId?: string;
  isParticipant: boolean;
  isPlaceLeft: boolean;
  isComplited: boolean;
  isWaiter: boolean;
  isOwner: boolean;
  onGoToDetails: () => void;
  onGetId?: (eventId: string) => void;
  onOpenTelegramModal: () => void;
  toggle?: () => void;
  eventId: string;
  telegramLink?: string;
  onGetEventAction: (eventAction: EventActionType) => void;
  ownerId: string;
  vendorCode?: string;
  ticketUrl?: string;
  isFree?: boolean;
};

export default function EventButtonSwitch({
  className = "",
  ...rest
}: ButtonSwitchType) {
  const checkOrder = useCheckOrder();

  if (rest.isOwner) {
    return (
      <>
        <Button
          className={clsx({
            [styles.EventButtonSwitch]: true,
            [className]: !!className,
          })}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            rest.onGoToDetails();
          }}
          variant="contained_nuar"
        >
          Детали
        </Button>
      </>
    );
  } else if (rest.isComplited) {
    return <></>;
  } else if (
    !rest.isOwner &&
    rest.isPlaceLeft &&
    !rest.isParticipant &&
    !rest.isWaiter
  ) {
    return (
      <>
        <Button
          className={clsx({
            [styles.EventButtonSwitch]: true,
            [className]: !!className,
          })}
          variant="contained_nuar"
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();

            if (rest.personId) {
              if (rest.isFree) {
                rest.onAddUserToParticipantsList(rest.eventId);
                rest.onOpenTelegramModal();
              } else {
                try {
                  const ticketBought = await checkOrder.mutateAsync({
                    eventId: rest.eventId,
                  });

                  if (ticketBought) {
                    rest.onAddUserToParticipantsList(rest.eventId);
                    rest.onOpenTelegramModal();
                  } else {
                    window.location.href = rest.ticketUrl!;
                  }
                } catch (e) {
                  toast.error("Что-то пошло не так");
                  return;
                }
              }
            } else {
              rest.onGetEventAction &&
                rest.onGetEventAction({
                  eventId: rest.eventId,
                  eventAction: "participants",
                  ownerId: rest.ownerId,
                  telegramLink: rest.telegramLink,
                  vendorCode: rest.vendorCode,
                  isFree: rest.isFree,
                  ticketUrl: rest.ticketUrl,
                });
              rest.toggle && rest.toggle();
            }
          }}
        >
          Участвовать
        </Button>
      </>
    );
  } else if (
    !rest.isOwner &&
    !rest.isParticipant &&
    !rest.isParticipant &&
    !rest.isWaiter
  ) {
    return (
      <>
        <Button
          className={clsx({
            [styles.EventButtonSwitch]: true,
            [className]: !!className,
          })}
          variant="contained_nuar"
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();

            if (rest.personId) {
              rest.onAddUserToWaitersList(rest.eventId);
              // if (rest.isFree) {
              //   rest.onAddUserToWaitersList(rest.eventId);
              // } else {
              //   try {
              //     const ticketBought = await checkOrder.mutateAsync({
              //       eventId: rest.eventId,
              //     });

              //     if (ticketBought) {
              //       rest.onAddUserToWaitersList(rest.eventId);
              //     } else {
              //       window.location.href = rest.ticketUrl!;
              //     }
              //   } catch (e) {
              //     toast.error("Что-то пошло не так");
              //     return;
              //   }
              // }
            } else {
              rest.onGetEventAction &&
                rest.onGetEventAction({
                  eventId: rest.eventId,
                  eventAction: "waiters",
                  ownerId: rest.ownerId,
                  telegramLink: undefined,
                  vendorCode: rest.vendorCode,
                  isFree: rest.isFree,
                  ticketUrl: rest.ticketUrl,
                });
              rest.toggle && rest.toggle();
            }
          }}
        >
          В очередь
        </Button>
      </>
    );
  } else if (!rest.isOwner && (rest.isParticipant || rest.isWaiter)) {
    return (
      <>
        <Button
          className={clsx({
            [styles.EventButtonSwitch]: true,
            [className]: !!className,
          })}
          variant="contained_nuar"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            if (rest.personId) {
              rest.onLeaveEvent(rest.eventId);
            } else {
              rest.onGetId && rest.onGetId(rest.eventId);
              rest.toggle && rest.toggle();
            }
          }}
        >
          Передумал
        </Button>
      </>
    );
  } else {
    return <>Упс...</>;
  }
}
