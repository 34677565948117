import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";

import { EventButtonSwitch } from "src/content/EventPreview";
import { BaseTypography } from "src/components/base/BaseTypography";
import { BaseLoader } from "src/components/base/BaseLoader";
import { BaseImage } from "src/components/base/BaseImage";
import { BaseIcon } from "src/components/base/BaseIcon";
import { GoBack } from "src/content";
import { Tag } from "src/components/base/Tag";
import { AuthModal } from "src/modals/AuthModal";

import { useDaysTillEvent } from "src/hooks/useDaysTillEvent";
import { useEventConditions } from "src/hooks/useEventConditions";
import { useEventHandlers } from "src/hooks/useEventHandlers";
import { formatDate } from "src/common/functions/formatDate";
import { QueriesEnum, RoutesEnum } from "src/common/enums";
import { useEvent } from "src/api/hooks/eventsHooks";
import { useEffectAfterMount } from "src/hooks/useEffectAfterMount";
import { useModal } from "src/hooks/useModal";
import { EventActionType } from "src/common/types/event-action.type";
import { formatEnding } from "src/common/functions/formatEnding";
import imageNotUploaded from "src/common/images/event-photo-not-found.webp";
import styles from "./Event.module.scss";
import { useMe } from "src/api/hooks/personsHooks";

export default function EventPage() {
  const { isShowing, toggle } = useModal();
  const navigate = useNavigate();
  const { data: user } = useMe();
  const { id } = useParams();
  const { data, isLoading, isError, refetch } = useEvent({ id });

  // Event button handlers
  const { onAddUserToParticipantsList, onAddUserToWaitersList, onLeaveEvent } =
    useEventHandlers({
      handleClick: () => refetch(),
    });

  // Days till event
  const daysTillEvent = useDaysTillEvent(data?.meetingAt, [data]);

  // Conditions
  const {
    isParticipant,
    isPlaceLeft,
    isComplited,
    isWaiter,
    isOwner,
    placesLeft,
  } = useEventConditions({ ...data, personIdFromJWT: user?.id } as any);

  const [isCopied, setIsCopied] = React.useState(false);

  useEffectAfterMount(() => {
    if (!isCopied) return;
    const timerId = setTimeout(() => {
      setIsCopied(false);
    }, 1500);
    return () => clearTimeout(timerId);
  }, [isCopied]);

  // Ref to text with telegram link
  const ref = React.createRef<HTMLDivElement>();

  const handleCopyLink = () => {
    if (!ref.current) return;
    navigator.clipboard.writeText(ref.current.dataset.link as string);
    setIsCopied(true);
  };

  const [eventAction, setEventAction] = React.useState<EventActionType>({
    eventId: undefined,
    eventAction: undefined,
    ownerId: undefined,
    telegramLink: undefined,
  });

  if (isLoading) {
    return (
      <main className={styles.EventPageLoader}>
        <BaseLoader />
      </main>
    );
  }

  if (!data || isError) {
    return (
      <Navigate
        to={RoutesEnum.EVENTS}
        replace
        state={{
          path: location.pathname,
        }}
      />
    );
  }

  // The function navigate to event details
  const onGoToDetails = () => navigate(RoutesEnum.EVENTS_UPDATE + "/" + id);

  // The function open telegram chat
  const onOpenTelegramModal = () => {
    if (!data.telegramLink) return;
    window.open(`${data.telegramLink}`);
  };

  return (
    <>
      <main className={styles.EventPage}>
        <div className={styles.EventPage__block_go_back}>
          <GoBack
            title="Все мероприятия"
            to={RoutesEnum.EVENTS + QueriesEnum.ALL_EVENTS}
          />
        </div>
        <div className={styles.EventPage__block_title}>
          <BaseTypography className={styles.EventPage__title} variant="h2">
            {data?.title}
          </BaseTypography>
        </div>
        <div className={styles.EventPage__block_cover}>
          <BaseImage
            className={styles.EventPage__image}
            lazyLoading={true}
            src={
              data?.multimedia?.path ? data.multimedia.path : imageNotUploaded
            }
          />
        </div>
        <div className={styles.EventPage__block_info}>
          <div className={styles.EventPage__tags}>
            {data?.eventType?.map(({ name, id }) => (
              <Tag
                className={styles.EventPage__tag}
                variant="event_details"
                key={id}
              >
                {name}
              </Tag>
            ))}
          </div>
          <div className={styles.EventPage__text}>{data?.text}</div>
          <ul className={styles.EventPage__params}>
            <li className={styles.EventPage__param}>
              <BaseIcon
                className={styles.EventPage__param_icon}
                viewBox="0 0 20 20"
                height="20"
                width="20"
                icon="CALENDAR"
              />
              <span className={styles.EventPage__param_text}>
                {formatDate(data?.meetingAt)}
                {" / "}
                {data.time}
              </span>
              <span className={styles.EventPage__param_issue}>
                {daysTillEvent > 1
                  ? "Через " + formatEnding(daysTillEvent)
                  : daysTillEvent === 1
                  ? "Завтра"
                  : daysTillEvent === 0
                  ? "Сегодня"
                  : "Завершилось"}
              </span>
            </li>
            <li className={styles.EventPage__param}>
              <BaseIcon
                className={styles.EventPage__param_icon}
                viewBox="0 0 15 15"
                height="15"
                width="15"
                icon="PARTICIPANT"
              />
              <span className={styles.EventPage__param_text}>
                {data?.maxParticipants} человек
              </span>
              <span className={styles.EventPage__param_issue}>
                Осталось {placesLeft} мест
              </span>
            </li>
            <li className={styles.EventPage__param}>
              <BaseIcon
                className={styles.EventPage__param_icon}
                viewBox="0 0 13 16"
                height="16"
                width="13"
                icon="POSITION"
              />
              <span className={styles.EventPage__param_text}>
                {data?.address}
              </span>
            </li>
            <li className={styles.EventPage__param}>
              <BaseIcon
                className={styles.EventPage__param_icon}
                viewBox="0 0 20 19"
                height="19"
                width="20"
                icon="PC"
              />
              <span className={styles.EventPage__param_text}>Оффлайн</span>
            </li>
            <li className={styles.EventPage__param}>
              <BaseIcon
                className={styles.EventPage__param_icon}
                viewBox="0 0 18 19"
                height="19"
                width="18"
                icon="FLAG"
              />
              <span className={styles.EventPage__param_text}>
                Организатор: {data.organizer}
              </span>
            </li>
            <li className={styles.EventPage__param}>
              <BaseIcon
                className={styles.EventPage__param_icon}
                viewBox="0 0 17 17"
                height="17"
                width="17"
                icon="ROUBLE"
              />
              <span className={styles.EventPage__param_text}>
                {data.isFree || (!data.isFree && data.cost === 0) ? (
                  "Бесплатно"
                ) : (
                  <>
                    {data.cost}{" "}
                    <span className={styles.EventPage__sign_rub}>₽</span>
                  </>
                )}
              </span>
            </li>
          </ul>
          <div className={styles.EventPage__controls}>
            <EventButtonSwitch
              className={styles.EventPage__button}
              personId={user?.id}
              ownerId={data?.id}
              isParticipant={isParticipant}
              isPlaceLeft={isPlaceLeft}
              isComplited={isComplited}
              isWaiter={isWaiter}
              isOwner={isOwner}
              toggle={toggle}
              onGetEventAction={(eventAction) => {
                setEventAction(() => eventAction);
              }}
              onGoToDetails={onGoToDetails}
              onOpenTelegramModal={onOpenTelegramModal}
              onAddUserToParticipantsList={onAddUserToParticipantsList}
              onAddUserToWaitersList={onAddUserToWaitersList}
              onLeaveEvent={onLeaveEvent}
              eventId={data.id}
              telegramLink={data.telegramLink}
              vendorCode={data.vendorCode}
              ticketUrl={data.ticketUrl}
              isFree={data.isFree}
            />
            <div
              className={styles.EventPage__link}
              onClick={handleCopyLink}
              data-link={location.href}
              ref={ref}
            >
              Поделиться мероприятием
              {isCopied && (
                <ToolType className={styles.EventPage__tooltype}>
                  Ссылка на мероприятие
                  <br /> скопирована
                </ToolType>
              )}
              <BaseIcon
                className={styles.EventPage__chain_icon}
                viewBox="0 0 20 20"
                height="20"
                width="20"
                icon="CHAIN"
              />
            </div>
          </div>
        </div>
      </main>
      <AuthModal
        onAddUserToParticipantsList={onAddUserToParticipantsList}
        onAddUserToWaitersList={onAddUserToWaitersList}
        eventAction={eventAction}
        isOpen={isShowing}
        toggle={toggle}
      />
    </>
  );
}

type ToolTypePropsType = {
  className?: string;
  children: React.ReactNode;
};

const ToolType = ({ className = "", children }: ToolTypePropsType) => {
  return (
    <div
      className={clsx({
        [styles.ToolType]: true,
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};
