import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { BaseTypography } from "src/components/base/BaseTypography";
import { BaseImage } from "src/components/base/BaseImage";
import { BaseIcon } from "src/components/base/BaseIcon";
import { Tag } from "src/components/base/Tag";
import EventStatus from "./EventStatus";

import { RoutesEnum } from "src/common/enums";
import { useEventConditions } from "src/hooks/useEventConditions";
import { useDaysTillEvent } from "src/hooks/useDaysTillEvent";
import { formatDate } from "src/common/functions/formatDate";
import { EventType } from "src/api/hooks/eventsHooks";
import EventButtonSwitch, { EventControlType } from "./EventButtonSwitch";
import imageNotUploaded from "src/common/images/event-photo-not-found.webp";
import { EventActionType } from "src/common/types/event-action.type";
import { formatEnding } from "src/common/functions/formatEnding";
import styles from "./EventPreview.module.scss";

type PropsType = EventType &
  EventControlType & {
    personIdFromJWT: string | undefined;
    toggle: () => void;
    onGetEventAction: (eventAction: EventActionType) => void;
  };

const EventPreview = (props: PropsType) => {
  const navigate = useNavigate();

  // Days till event
  const daysTillEvent = useDaysTillEvent(props?.meetingAt, [props]);

  // Event conditions
  const {
    isParticipant,
    isPlaceLeft,
    isComplited,
    isWaiter,
    isOwner,
    placesLeft,
  } = useEventConditions({ ...props });

  // The function navigate to event details
  const onGoToDetails = () =>
    navigate(RoutesEnum.EVENTS_UPDATE + "/" + props.id);

  // The Short preview of the text
  const text: string =
    props.title.length >= 48
      ? props.title.slice(0, 48).trim() + "..."
      : props.title.trim();

  const onOpenTelegramModal = () => {
    if (!props.telegramLink) return;
    window.open(`${props.telegramLink}`);
  };

  return (
    <Link to={"events/" + props?.id}>
      <section className={`${styles.EventPreview1} ${styles.EventPreview}`}>
        <div className={styles.EventPreview__header}>
          <BaseImage
            className={styles.EventPreview__preview}
            lazyLoading={true}
            src={
              props?.multimedia?.path ? props.multimedia.path : imageNotUploaded
            }
            height="35"
            width="137"
          />
          <Tag
            className={clsx({
              [styles.EventPreview__date]: true,
              [styles.EventPreview__date_hide]:
                daysTillEvent > 1 || daysTillEvent < -0,
            })}
            variant="event_date"
            style={{ background: daysTillEvent === 0 ? "#ffffff" : "#FDFFA8" }}
          >
            {daysTillEvent === 0 && "Сегодня"}
            {daysTillEvent === 1 && "Завтра"}
          </Tag>
          <div className={styles.EventPreview__tags}>
            {props.eventType?.map(({ name, id }) => (
              <Tag
                className={styles.EventPreview__tag}
                variant="event_preview"
                key={id}
              >
                {name}
              </Tag>
            ))}
          </div>
        </div>
        <div className={styles.EventPreview__body}>
          <BaseTypography className={styles.EventPreview__title} variant="h3">
            {text}
          </BaseTypography>
          <ul className={styles.EventPreview__list}>
            <li className={styles.EventPreview__item}>
              <BaseIcon
                className={styles.EventPreview__icon}
                viewBox="0 0 20 20"
                height="20"
                width="20"
                icon="CALENDAR"
              />
              <span className={styles.EventPreview__item_text}>
                {formatDate(props.meetingAt)}
                {" / "} {props.time}
              </span>
              <span className={styles.EventPreview__item_issue}>
                {daysTillEvent > 1
                  ? "Через " + formatEnding(daysTillEvent)
                  : daysTillEvent === 1
                  ? "Завтра"
                  : daysTillEvent === 0
                  ? "Сегодня"
                  : "Завершилось"}
              </span>
            </li>
            <li className={styles.EventPreview__item}>
              <BaseIcon
                className={styles.EventPreview__icon}
                viewBox="0 0 13 16"
                height="16"
                width="13"
                icon="POSITION"
              />
              <span className={styles.EventPreview__item_text}>
                {props.address}
              </span>
            </li>
            <li className={styles.EventPreview__item}>
              <BaseIcon
                className={styles.EventPreview__icon}
                viewBox="0 0 15 15"
                height="15"
                width="15"
                icon="PARTICIPANT"
              />
              <span className={styles.EventPreview__item_text}>
                {props.maxParticipants} участников
              </span>
              <span className={styles.EventPreview__item_issue}>
                Осталось {placesLeft} мест
              </span>
            </li>
            <li className={styles.EventPreview__item}>
              <BaseIcon
                className={styles.EventPreview__icon}
                viewBox="0 0 17 17"
                height="17"
                width="17"
                icon="ROUBLE"
              />
              <span className={styles.EventPreview__item_text}>
                {props.isFree || (!props.isFree && props.cost === 0) ? (
                  "Бесплатно"
                ) : (
                  <>
                    {props.cost}{" "}
                    <span className={styles.EventPreview__sign_rub}>₽</span>
                  </>
                )}
              </span>
            </li>
          </ul>
          <div className={styles.EventPreview__controls}>
            <EventStatus
              className={styles.EventPreview__status}
              isOwner={isOwner}
              status={props.eventStatus.name}
            />
            <EventButtonSwitch
              isParticipant={isParticipant}
              isPlaceLeft={isPlaceLeft}
              personId={props.personIdFromJWT}
              ownerId={props.personId}
              isComplited={isComplited}
              isWaiter={isWaiter}
              isOwner={isOwner}
              onGoToDetails={onGoToDetails}
              onGetEventAction={props.onGetEventAction}
              toggle={props.toggle}
              onOpenTelegramModal={onOpenTelegramModal}
              onAddUserToParticipantsList={props.onAddUserToParticipantsList}
              onAddUserToWaitersList={props.onAddUserToWaitersList}
              onLeaveEvent={props.onLeaveEvent}
              eventId={props.id}
              telegramLink={props.telegramLink}
              vendorCode={props.vendorCode}
              ticketUrl={props.ticketUrl}
              isFree={props.isFree}
            />
          </div>
        </div>
      </section>
    </Link>
  );
};

export default EventPreview;
