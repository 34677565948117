import { useState } from 'react';

export const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  const toggle = () => {
    setIsShowing((prev) => !prev);
  };

  const toggleValue = (value: boolean) => {
    setIsShowing(value);
  };

  return {
    isShowing,
    toggleValue,
    toggle,
  };
};
