import { FC, forwardRef, InputHTMLAttributes } from 'react';

import { CheckedIcon } from '../BaseIcons';

import './index.scss';

type Attributes = InputHTMLAttributes<HTMLInputElement>;
type PropsType = {
  checked: boolean | undefined;
};

export const BaseCheckbox: FC<PropsType & Attributes> = forwardRef(
  ({ className = '', checked, ...rest }, ref: React.ForwardedRef<any>) => {
    return (
      <div className={['base-input-checkbox', className].join(' ').trim()}>
        {checked ? <CheckedIcon className="base-input-checkbox__icon" /> : null}
        <input
          className="base-input-checkbox__input"
          {...rest}
          type="checkbox"
          ref={ref}
        />
      </div>
    );
  },
);
