import React, { ReactChildren } from "react";
import clsx from "clsx";

import { CheckedIcon } from "../BaseIcons";

import './index.scss';

type PropsType = {
  type?: 'checkbox' | 'radio';
  answerChecked: boolean | undefined;
  children?: ReactChildren;
  answerTitle: string;
  onChange: (value: boolean) => void;
  name?: string;
  error?: boolean;
  disabled?: boolean;
  className?: string;
}

function BaseCheckboxItem({ type = 'checkbox', answerChecked, children, error, className = '', disabled, answerTitle, onChange, ...rest  }: PropsType) {
  return (
    <div
      className={clsx({
        "base-checkbox-item": true,
        "base-checkbox-item_checked": answerChecked,
        "base-checkbox-item_required": error,
        "base-checkbox-item_disabled": disabled,
        [className]: !!className
      })}

    >
      <label className="base-checkbox-item__label">
        <div
          className={`base-checkbox-item__text ${answerChecked ? 'base-checkbox-item__text_checked' : ''
          }`}
        >
          {answerTitle}
        </div>
        <div className="base-checkbox-item__checkbox">
          <input
            className="base-checkbox-item__input"
            type={type}
            onChange={(e) => {
              onChange(e.target.checked)
            }}
            disabled={disabled}
            checked={answerChecked}
            {...rest}
          />
        </div>
        <div
          className={`base-checkbox-item__icon ${answerChecked ? 'base-checkbox-item__icon_checked' : ''
          }`}
        >
          {answerChecked ? <CheckedIcon /> : null}
        </div>
      </label>
      {answerChecked && children ? (
        <div className="base-checkbox-item__textarea">{children}</div>
      ) : null}
    </div>
  );
}

export default BaseCheckboxItem;