import React from "react";

import { EventStatusEnum } from "src/common/enums";
import { EventType } from "src/api/hooks/eventsHooks";

type PropsType = EventType & {
  personIdFromJWT: string | undefined;
};

export function useEventConditions({ personIdFromJWT, ...rest }: PropsType) {
  const placesLeft: number =
    rest?.eventParticipants?.length <= rest?.maxParticipants
      ? rest?.maxParticipants - rest?.eventParticipants.length
      : 0;

  // If places in paricipant list is free
  const isPlaceLeft: boolean = placesLeft > 0;

  // If user is owner of event
  const isOwner: boolean = personIdFromJWT === rest.personId;

  // If user is participant of event
  const isParticipant: boolean = !!rest.eventParticipants?.find(
    ({ personId }) => personId === personIdFromJWT,
  );

  // If user in waiting list
  const isWaiter: boolean = !!rest.eventWaiters?.find(
    ({ personId }) => personId === personIdFromJWT,
  );

  // If event was completed
  const isComplited: boolean =
    rest.eventStatus?.name === EventStatusEnum["Завершено"];

  return {
    placesLeft,
    isPlaceLeft,
    isOwner,
    isParticipant,
    isWaiter,
    isComplited,
  };
}
