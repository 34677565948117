import { BaseSlider } from "src/components/base/BaseSlider";

import "./AgeSelector.scss";

type PropsType = {
  value:
    | {
        from: number;
        till: number;
      }
    | undefined;
  onChange: (
    event: Event,
    value: number | number[],
    activeThumb: number,
  ) => void;
  disabled?: boolean;
};

function AgeSelector({
  value = { from: 20, till: 35 },
  onChange,
  ...rest
}: PropsType) {
  return (
    <div className="AgeSelector">
      <BaseSlider
        className={rest.disabled ? "AgeSelectorDisabled" : ""}
        getAriaLabel={() => "Age"}
        value={[value.from, value.till]}
        onChange={onChange}
        // getAriaValueText={valuetext}
        valueLabelDisplay="on"
        valueLabelFormat={(value) => (value === 56 ? "56+" : value)}
        // marks={marks}
        min={18}
        step={1}
        max={56}
        {...rest}
      />
    </div>
  );
}

export default AgeSelector;
