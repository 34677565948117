import logo from "../../common/images/svg/logo.svg";
import tg from "../../common/images/svg/tg.svg";
import styles from "./IconList.module.scss";
import { Link } from "react-router-dom";
export const IconList = () => {
  return (
    <div className={styles.iconlist__wrapper}>
      <Link to="/">
        <img src={logo} alt="" className={styles.iconlist__logo} />
      </Link>
      <a href="https://t.me/terkatalk" target="_blank">
        <img src={tg} alt="" className={styles.iconlist__telegram} />
      </a>
    </div>
  );
};
