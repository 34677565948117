import { queryClient, request } from "../request";
import { PaginatedRequestDto } from "../commons/types";
import { useMutation, useQuery } from "react-query";

export enum OrderStatusEnum {
  "Новый",
  "В пути",
  "Выполнен",
}

export type OrderType = {
  id: number; // айди заказа
  amount: number; // стоимость заказа
  isNew: boolean; // новый товар?
  deliveryWay: string; // способ доставки
  deliveryCost: number; // стоимость доставки
  createdAt: string; // когда создан заказ
  phone: string; // в ордер форму заказа
  address: string; // в ордер форму заказа
  status: {
    id: number; // просто айди связанный с статусом заказа 1 . 2 . 3
    name: keyof typeof OrderStatusEnum; // статус заказа имя
  };
  user: {
    id: number; // не нужна
    firstName: string; // да в строку
    lastName: string; // да в строку
    email: string; // не нужна
    phone: string; // не нужна
    address: string; // не нужна
  };
  items: [
    {
      id: number; //
      vendorCode: string; // артикул 'alt?'
      name: string; // имя в строку
      colorName: string; // надо
      colorCode: string; // надо
      price: number; // // надо
      imageSrc: string; // ? надо // надо
      quantity: number; // // надо
      size: string; // // надо
      return: {
        // возврат...
        id: number; // модалка с возвратом я хз пока как делать
        createdAt: string; //модалка с возвратом я хз пока как делать
        returnAt: string; //модалка с возвратом я хз пока как делать
      };
    },
  ];
  giftCard: {
    id: number; // не нужно пока что
    usedSumm: number; // не нужно пока что
  };
};

export type GetOrderResponseType = {
  totalPages: number;
  totalQuantity: number;
  totalCount: number;
  item: OrderType[];
};

export type CheckOrderRequestDto = {
  eventId: string;
};

export type CheckOrderResponseDto = boolean;

type GetOrderPaylodType = PaginatedRequestDto & { order: "ASC" | "DESC" };

export const getOrders = async (payload: GetOrderPaylodType) => {
  const { data } = await request<GetOrderResponseType>({
    url: "/orders",
    method: "GET",
    params: payload,
  });

  return data;
};

export const useOrders = ({ page, limit, order }: GetOrderPaylodType) =>
  useQuery<GetOrderResponseType>(
    ["orders", page, limit, order],
    () =>
      getOrders({
        page,
        limit,
        order,
      }),
    {
      notifyOnChangeProps: "tracked",
      refetchOnMount: true,
      // staleTime: 5000, // в мс протухание данных, становятся неактуальными
    },
  );

export const checkOrder = async (payload: CheckOrderRequestDto) => {
  const { data } = await request<CheckOrderResponseDto>({
    url: `/events/${payload.eventId}/actions/check-order`,
    method: "POST",
    data: {},
  });

  return data;
};

export const useCheckOrder = () =>
  useMutation(["events"], checkOrder, {
    onSettled: () => {
      queryClient.invalidateQueries("events");
    },
  });
