import { queryClient, request } from '../request';
import { useMutation, useQuery } from 'react-query';
import { PersonType } from './personsHooks';
import { PlaceType } from './placesHooks';
import {
  GetAllMatchesDto,
  PaginatedDto,
  PaginatedRequestDto,
} from '../commons/types';

export function calculateAge(birthday: Date) {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

enum MatchStatus {
  MEETING_CREATED = 'MEETING_CREATED',
  MEETING_APPOINTMENT = 'MEETING_APPOINTMENT',
  MEETING_HELD = 'MEETING_HELD',
}

export type MatchStatusType = keyof typeof MatchStatus;

export type MatchType = {
  id: string;
  invite?: {
    id: string;
    place: PlaceType;
    invitedPerson: string;
    isAccepted: boolean;
  };
  pair: Array<{
    id: string;
    isNew: true;
    status: MatchStatusType;
    people: PersonType;
    favouriteHobbies: Array<string>;
    interestingAreas: Array<string>;
    liveInKaliningrad: boolean;
  }>;
  feedbacks: Array<{
    remeeting: string;
    feedback: string;
    comment: string;
    person: string;
    score: string;
    qualities: QualityType[];
  }>;
  people: string[];
  createdAt: string;
};

export type MatchLineStatisticsType = {
  id: string;
  color?: string; // "hsl(33, 70%, 50%)"
  data: Array<{
    x: string;
    y: number;
  }>;
};

export type MatchPieStatisticsType = {
  data: Array<{
    id: string;
    label: string;
    value: number;
    color?: string; // "hsl(33, 70%, 50%)"
  }>;
  fill: Array<{
    match: {
      id: string;
    };
    id: string;
  }>;
};

export type QualityType = {
  id: string;
  name: string;
};

export type FeedbackType = {
  score: number;
  qualities: string[];
  comment: string;
};

const getMatches = async ({
  page,
  limit,
  personId,
}: PaginatedRequestDto & GetAllMatchesDto): Promise<
  PaginatedDto<MatchType>
> => {
  const { data } = await request<PaginatedDto<MatchType>>({
    url: `/matches`,
    params: {
      page,
      limit,
      personId,
    },
  });
  return data;
};

export const useMatches = ({
  page,
  limit,
  personId,
}: PaginatedRequestDto & GetAllMatchesDto) =>
  useQuery<PaginatedDto<MatchType>>(
    ['matches', page, limit, personId],
    () =>
      getMatches({
        page,
        limit,
        personId,
      }),
    {
      enabled: !!personId,
      staleTime: 5000,
      cacheTime: 5000,
    },
  );

const updateMatchView = async ({
  id,
  pairId,
}: {
  id: string;
  pairId: string;
}) => {
  const { data } = await request<MatchType>({
    url: `/matches/${id}/actions/view`,
    method: 'PATCH',
    data: {
      pairId,
    },
  });

  return data;
};

const updateMatchStatus = async ({
  id,
  pairId,
  status,
}: {
  id: string;
  pairId: string;
  status: MatchStatusType;
}) => {
  const { data } = await request<MatchType>({
    url: `/matches/${id}/actions/update_status`,
    method: 'PATCH',
    data: {
      pairId,
      status,
    },
  });

  return data;
};

const updateMatchFeedback = async ({
  matchId,
  ...rest
}: FeedbackType & { matchId: string; pairId: string }) => {
  const { data } = await request({
    url: `/matches/${matchId}/feedback`,
    method: 'PATCH',
    data: rest,
  });

  return data;
};

const getQualities = async () => {
  const { data } = await request<Array<QualityType>>({
    url: `/matches/qualities`,
    method: 'GET',
  });

  return data;
};

export const useUpdateMatchView = () =>
  useMutation(['matches'], updateMatchView, {
    onSettled: () => {
      queryClient.invalidateQueries('matches');
    },
  });

export const useUpdateMatchStatus = () =>
  useMutation(['matches'], updateMatchStatus, {
    onSettled: () => {
      queryClient.invalidateQueries('matches');
    },
  });

export const useUpdateMatchFeedback = () =>
  useMutation(['matches'], updateMatchFeedback, {
    onSettled: () => {
      queryClient.invalidateQueries('matches');
    },
  });

export const useQualities = () =>
  useQuery<Array<QualityType>>(['qualities'], () => getQualities());
