import { Route, Routes } from "react-router-dom";
import { createContext, useState } from "react";

import { Credentials } from "../../layouts/Credentials";
import { FormSchema1DataType, SignupStep1 } from "./signup-step-1";
import { FormSchema2DataType, SignupStep2 } from "./signup-step-2";
import { FormSchema3DataType, SignupStep3 } from "./signup-step-3";
import { SignupStep4 } from "./signup-step-4";

export type SignUpRoutesStateType = {
  step1: FormSchema1DataType | null;
  step2: FormSchema2DataType | null;
  step3: FormSchema3DataType | null;
};

export const SignUpRoutesContext = createContext<any>({});

export const SignupRoutes = () => {
  const [state, setState] = useState<SignUpRoutesStateType>({
    step1: null,
    step2: null,
    step3: null,
  });

  const handleState = (data: any) => {
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  return (
    <>
      <SignUpRoutesContext.Provider
        value={{
          state,
          handleState,
        }}
      >
        <Routes>
          <Route path="/form/" element={<Credentials />}>
            <Route path="step/1" element={<SignupStep1 />} />
            <Route path="step/2" element={<SignupStep2 />} />
            <Route path="step/3" element={<SignupStep3 />} />
            <Route path="step/4" element={<SignupStep4 />} />
          </Route>
        </Routes>
      </SignUpRoutesContext.Provider>
    </>
  );
};
