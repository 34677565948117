import { Route, Routes } from 'react-router-dom';

import { SigninResetPassword } from './signin-reset-password';
import { SigninEnterPassword } from './signin-enter-password';
import { SigninConfirmEmail } from './signin-confirm-email';
import { Credentials } from '../../layouts/Credentials';
import { Signin } from './signin';

export const SigninRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Credentials />}>
        <Route path="/" element={<Signin />} />
        <Route path="reset-password" element={<SigninResetPassword />} />
        <Route path="confirm-email/:email" element={<SigninConfirmEmail />} />
        <Route path="enter-password/:code" element={<SigninEnterPassword />} />
      </Route>
    </Routes>
  );
};
