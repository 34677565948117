import { useToaster, ToastType } from 'react-hot-toast/headless';
import toastHeadless from 'react-hot-toast/headless';

import { SuccessIcon, XIcon } from '../../components/base/BaseIcons';

import styles from './styles.module.scss';
import { useWindowSize } from 'src/hooks/useWindowSize';

const Notification = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;
  const [width] = useWindowSize();

  const typeIcon = (type: ToastType) => {
    switch (type) {
      case 'success':
        return <SuccessIcon className={styles.icon} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((toast) => {
        const offset = calculateOffset(toast, {
          reverseOrder: false,
          gutter: 8,
        });

        const ref = (el: HTMLDivElement) => {
          if (el && !toast.height) {
            const height = el.getBoundingClientRect().height;
            updateHeight(toast.id, height);
          }
        };
        return (
          <div
            key={toast.id}
            ref={ref}
            className={styles.toast}
            style={{
              opacity: toast.visible ? 1 : 0,
              transform:
                width > 480
                  ? `translateY(${offset}px) translateX(calc(50vw - 245px)`
                  : `translateY(${offset}px) translateX(calc(50vw - 170px)`,
            }}
            {...toast.ariaProps}
          >
            <div className={styles.content}>
              {typeIcon(toast.type)}
              {toast.message}
            </div>
            {
              <XIcon
                className={styles.x}
                onClick={() => {
                  toastHeadless.dismiss(toast.id);
                }}
              />
            }
          </div>
        );
      })}
    </div>
  );
};

export default Notification;
