import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";

import { Cropper, EventForm, EventList, Preloader, Table } from "../../content";
import { BaseTypography } from "src/components/base/BaseTypography";
import { LayoutAccount } from "src/layouts";
import { BaseToggle } from "src/components/base";
import { Tab, Tabs } from "src/content";
import { Button } from "src/components/base/Button";
import useUploadCover from "src/hooks/useUploadCover";

import {
  EventParticipantType,
  EventPayStatus,
  EventType,
  EventWaiterType,
  useEvent,
  useEventStatuses,
  useParticipants,
  useUpdateEvent,
  useUpdatePayStatus,
  useUpdateStatus,
  useUploadImage,
  useWaiters,
} from "src/api/hooks/eventsHooks";
import { BaseTableColumns } from "src/content/Table/Table";
import { EventStatusEnum, QueriesEnum, RoutesEnum } from "src/common/enums";
import { useWindowSize } from "src/hooks/useWindowSize";
import { useJwtPayload } from "src/hooks/useJwtPayload";
import { EventScheme } from "src/common/validation";
import { OptionType } from "src/common/types/option-type";
import styles from "./EventUpdate.module.scss";
import { ConfirmPayStatusModal } from "src/modals/ConfirmPayStatusModal/ConfirmPayStatusModal";
import { useModal } from "src/hooks/useModal";

const EventUpdatePage = () => {
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const user = useJwtPayload();
  const {
    uploadedCover,
    croppedCover,
    handleDeleteCropped,
    handleEditCover,
    handleUploadCover,
    handleUploadCropped,
    isOpenCropper,
    toggleCropper,
  } = useUploadCover();

  // Tabs state
  const [value, setValue] = React.useState(0);

  // Tabs change handler
  const onHandleTabs = (index: number) => setValue(() => index);

  // Form initialize
  const form = useForm<EventType>({
    defaultValues: {
      isFree: false,
    },
    resolver: yupResolver(EventScheme),
  });

  // Form state
  const errors = form.formState.errors;
  const state = form.watch();

  // Form state handler
  const onHandleState = React.useCallback(
    (
      key: keyof EventType,
      value: string | number | OptionType | OptionType[] | boolean,
    ) => {
      form.setValue(key, value, {
        shouldValidate: true,
      });
    },
    [],
  );

  React.useEffect(() => {
    if (state.isFree) {
      form.reset({
        ...state,
        cost: 0,
        vendorCode: "",
        ticketUrl: "",
      });
    }
  }, [state.isFree]);

  const params = useParams();
  const id = params?.id ? params.id : "";

  const { data: event, isLoading: eventLoader } = useEvent({ id });

  React.useEffect(() => {
    if (!event) return;

    for (const key in event) {
      if (key === "telegramLink" && !event[key]) continue;
      form.setValue(
        key as keyof EventType,
        (event as EventType)[key as keyof EventType],
      );
    }

    if (event?.multimedia?.path) {
      fetch(event?.multimedia?.path)
        .then((res) => res.blob())
        .then((blob) => {
          if (event?.multimedia?.fileName) {
            const file = new File([blob], event?.multimedia?.fileName, blob);
            handleUploadCover(file, false);
            handleUploadCropped(file);
          }
        })
        .catch((e) => {
          console.error("FETCH EVENT IMAGE ERROR: ", e);
        });
    }
  }, [event]);

  // Update status
  const { data: participants, isLoading: participantsLoading } =
    useParticipants({ id });
  const { data: waiters, isLoading: waitersLoading } = useWaiters({ id });
  const { data: statuses } = useEventStatuses();
  const updateStatus = useUpdateStatus();

  const isNoParticipants = participants && participants.length === 0;
  const isNoWaiters = waiters && waiters.length === 0;

  // The function updates status of the event
  const onUpdateStatus = async (id: string, statusName?: string) => {
    if (!statuses) return;

    const foundStatus = statuses.find((status) => status.name === statusName);

    if (!foundStatus) return;

    try {
      await updateStatus.mutateAsync({ id, statusId: foundStatus.id });

      foundStatus.name === EventStatusEnum["Опубликовано"]
        ? toast.success("Запись успешно опубликована")
        : toast.success("Запись успешно скрыта");

      navigate(-1);
    } catch (e) {
      foundStatus.name === EventStatusEnum["Опубликовано"]
        ? toast.error("Не удалось опубликовать запись")
        : toast.error("Не удалось скрыть запись");
    }
  };

  const updateEvent = useUpdateEvent();
  const uploadImage = useUploadImage();

  const onSubmit = async (data: EventType) => {
    // if (!data.eventStatusId) return;

    try {
      const updatedEvent = await updateEvent.mutateAsync({
        ...data,
      });

      // If cropped image exists
      if (croppedCover) {
        const formData = new FormData();
        formData.set("cover", croppedCover as Blob);

        await uploadImage.mutateAsync({
          id: updatedEvent.id,
          formData,
        });

        formData.delete("cover");
      }

      toast.success("Запись успешно обновлена");
      navigate(RoutesEnum.EVENTS + QueriesEnum.MY_EVENTS);
    } catch (e) {
      toast.error("Не удалось обновить запись");
      console.error(e);
    }
  };

  /**
   * Update payments status
   */

  // Modal with confirmation pay status
  const { isShowing, toggle } = useModal();
  const [newPayStatus, setNewPayStatus] = React.useState<
    EventPayStatus | undefined
  >();
  const [personId, setPersonId] = React.useState<string | undefined>();
  const [cb, setCb] = React.useState<any | undefined>();

  const updatePayStatus = useUpdatePayStatus();

  const handleUpdatePayment = async () => {
    if (!personId || !newPayStatus || !cb) return;

    cb(newPayStatus === "PAID");

    try {
      await updatePayStatus.mutateAsync({
        personId: personId,
        status: newPayStatus,
        id,
      });

      setNewPayStatus(undefined);
      setPersonId(undefined);
      setCb(undefined);
      toggle();
      toast.success("Статус оплаты обновлен");
    } catch (e) {
      console.error(e);
      toast.error("Не удалось обновить статус оплаты");
    }
  };

  const handleToggle = (
    status: EventPayStatus,
    personId: string,
    cb: (value: boolean) => void,
  ) => {
    setNewPayStatus(status);
    setPersonId(personId);
    setCb(() => cb);
    toggle();
  };

  const handleCancel = () => {
    setNewPayStatus(undefined);
    setPersonId(undefined);
    setCb(undefined);
    toggle();
  };

  return width <= 767 ? (
    <>
      <LayoutAccount
        title="Детали мероприятия"
        isArrowGoBack={true}
        to={RoutesEnum.EVENTS + QueriesEnum.ALL_EVENTS}
      >
        <div className={styles.EventUpdatePage}>
          <form
            className={styles.EventUpdatePage__form}
            onSubmit={form.handleSubmit(onSubmit)}
            id="event-update-mobile"
          >
            <Tabs
              className={styles.EventUpdatePage__tabs}
              onChange={(_, index) => onHandleTabs(index)}
              items={items}
              value={value}
            />
            <Tab
              className={[
                styles.EventUpdatePage__tab,
                styles.EventUpdatePage__inputs,
              ]
                .join(" ")
                .trim()}
              value={value}
              index={0}
            >
              <EventForm
                onHandleState={onHandleState}
                onUploadCover={handleUploadCover}
                onDeleteCover={handleDeleteCropped}
                onEditCover={handleEditCover}
                croppedCover={croppedCover}
                errors={errors}
                state={state}
              />
            </Tab>
            <Tab
              className={[
                styles.EventUpdatePage__tab,
                styles.EventUpdatePage__list,
              ]
                .join(" ")
                .trim()}
              value={value}
              index={1}
            >
              <EventList
                className={styles.EventUpdatePage__participants}
                label="Участники"
              >
                <Table
                  isLoading={participantsLoading}
                  hideHead={isNoParticipants}
                  columns={columns({ handleToggle })}
                  data={participants ?? []}
                />
              </EventList>
              <EventList
                className={styles.EventUpdatePage__waiters}
                label="Список ожидания"
              >
                <Table
                  isLoading={waitersLoading}
                  hideHead={isNoWaiters}
                  columns={columns({ handleToggle })}
                  data={waiters ?? []}
                />
              </EventList>
            </Tab>
          </form>
          <div
            className={styles.EventUpdatePage__buttons}
            style={
              event?.personId !== user?.personId ? { display: "none" } : {}
            }
          >
            <Button
              className={styles.EventUpdatePage__button}
              variant="contained_nuar"
              type="submit"
              form="event-update-mobile"
            >
              Сохранить
            </Button>
            {event?.eventStatus?.name === EventStatusEnum["Опубликовано"] ? (
              <>
                <Button
                  className={styles.EventUpdatePage__button}
                  onClick={() => onUpdateStatus(id, EventStatusEnum["Архив"])}
                  variant="contained_nuar"
                  // type="submit"
                >
                  Скрыть
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={styles.EventUpdatePage__button}
                  onClick={() =>
                    onUpdateStatus(id, EventStatusEnum["Опубликовано"])
                  }
                  variant="contained_nuar"
                  // type="submit"
                >
                  Опубликовать
                </Button>
              </>
            )}
            <BaseTypography
              className={styles.EventUpdatePage__button}
              variant="p"
            >
              {event?.eventStatus?.name.toLocaleLowerCase() ===
              EventStatusEnum["Архив"].toLocaleLowerCase()
                ? "Скрыто"
                : event?.eventStatus?.name}
            </BaseTypography>
          </div>
        </div>
      </LayoutAccount>
      {uploadedCover && (
        <Cropper
          isOpen={isOpenCropper}
          toggle={toggleCropper}
          file={uploadedCover as File}
          onChange={handleUploadCropped}
          onCancel={toggleCropper}
        />
      )}
      <ConfirmPayStatusModal
        toggle={toggle}
        isOpen={isShowing}
        handleCancel={handleCancel}
        handleUpdatePayment={handleUpdatePayment}
      />
    </>
  ) : (
    <>
      <LayoutAccount title="Детали мероприятия" isArrowGoBack={true}>
        <div className={styles.EventUpdatePage}>
          <form
            className={styles.EventUpdatePage__form}
            onSubmit={form.handleSubmit(onSubmit)}
            id="event-update"
          >
            <Preloader isLoading={eventLoader}>
              <EventForm
                onHandleState={onHandleState}
                onUploadCover={handleUploadCover}
                onDeleteCover={handleDeleteCropped}
                onEditCover={handleEditCover}
                croppedCover={croppedCover}
                errors={errors}
                state={state}
              />
            </Preloader>
            <div
              className={styles.EventUpdatePage__buttons}
              style={
                event?.personId !== user?.personId ? { display: "none" } : {}
              }
            >
              <Button
                className={styles.EventUpdatePage__button}
                variant="contained_nuar"
                type="submit"
                form="event-update"
              >
                Сохранить
              </Button>
              {event?.eventStatus?.name === EventStatusEnum["Опубликовано"] ? (
                <>
                  <Button
                    className={styles.EventUpdatePage__button}
                    onClick={() => onUpdateStatus(id, EventStatusEnum["Архив"])}
                    variant="contained_nuar"
                    // type="submit"
                  >
                    Скрыть
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={styles.EventUpdatePage__button}
                    onClick={() =>
                      onUpdateStatus(id, EventStatusEnum["Опубликовано"])
                    }
                    variant="contained_nuar"
                    // type="submit"
                  >
                    Опубликовать
                  </Button>
                </>
              )}
              <BaseTypography
                className={styles.EventUpdatePage__button}
                variant="p"
              >
                {event?.eventStatus?.name.toLocaleLowerCase() ===
                EventStatusEnum["Архив"].toLocaleLowerCase()
                  ? "Скрыто"
                  : event?.eventStatus?.name}
              </BaseTypography>
            </div>
          </form>
          <div className={styles.EventUpdatePage__list}>
            <EventList
              className={styles.EventUpdatePage__participants}
              label="Участники"
            >
              <Table
                isLoading={participantsLoading}
                hideHead={isNoParticipants}
                columns={columns({ handleToggle })}
                data={participants ?? []}
              />
            </EventList>
            <EventList
              className={styles.EventUpdatePage__waiters}
              label="Список ожидания"
            >
              <Table
                isLoading={waitersLoading}
                hideHead={isNoWaiters}
                columns={columns({ handleToggle })}
                data={waiters ?? []}
              />
            </EventList>
          </div>
        </div>
      </LayoutAccount>
      {uploadedCover && (
        <Cropper
          isOpen={isOpenCropper}
          toggle={toggleCropper}
          file={uploadedCover as File}
          onChange={handleUploadCropped}
          onCancel={toggleCropper}
        />
      )}
      <ConfirmPayStatusModal
        toggle={toggle}
        isOpen={isShowing}
        handleCancel={handleCancel}
        handleUpdatePayment={handleUpdatePayment}
      />
    </>
  );
};

type ColumnsPayload = {
  handleToggle: (
    status: EventPayStatus,
    personId: string,
    cb: (value: boolean) => void,
  ) => void;
};

const columns = ({
  handleToggle,
}: ColumnsPayload): BaseTableColumns<
  EventParticipantType | EventWaiterType
> => [
  {
    name: "",
    selector: (row, index) => <>{index + 1 + "."}</>,
    style: {
      minWidth: "10px",
    },
  },
  {
    name: "Фамилия",
    selector: (row) => (
      <>
        {!row?.person?.lastName || row?.person?.lastName?.trim()?.length === 0
          ? "Новый"
          : row.person.lastName}
      </>
    ),
  },
  {
    name: "Имя",
    selector: (row) => (
      <>
        {!row?.person?.firstName || row?.person?.firstName?.trim()?.length === 0
          ? "Пользователь"
          : row.person.firstName}
      </>
    ),
  },
  {
    name: "Telegram",
    selector: (row) => (
      <>{row?.person?.username ? "@" + row.person.username : "Не установлен"}</>
    ),
  },
  {
    name: "Оплачен",
    selector: (row) => {
      const status = (row as EventParticipantType)?.status;

      return status ? (
        <TogglePayment
          onChange={(newStatus, cb) =>
            handleToggle(newStatus, row.personId, cb)
          }
          value={status}
        />
      ) : (
        <></>
      );
    },
  },
];

type TogglePaymentPropsType = {
  onChange: (value: EventPayStatus, cb: (value: boolean) => void) => void;
  value: EventPayStatus;
};

const dictionaty: Record<EventPayStatus, EventPayStatus> = {
  NOT_PAID: "NOT_PAID",
  PAID: "PAID",
};

function TogglePayment(props: TogglePaymentPropsType) {
  const initValue = React.useMemo(
    () => props.value === dictionaty.PAID,
    [props.value],
  );

  const [checked, setChecked] = React.useState(initValue);

  React.useEffect(() => {
    setChecked(initValue);
  }, [initValue]);

  return (
    <BaseToggle
      checked={checked}
      onChange={(checked) => {
        props.onChange(
          checked ? dictionaty.PAID : dictionaty.NOT_PAID,
          (value: boolean) => setChecked(value),
        );
      }}
    />
  );
}

const items = [
  {
    id: "1",
    name: "Детали мероприятия",
  },
  {
    id: "2",
    name: "Участники",
  },
];

export default EventUpdatePage;
