import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { CredentialsForm } from "../../../layouts/CredentialsForm";
import { BaseInputError } from "../../../components/base/BaseInputError";
import { BaseButton } from "../../../components/base/BaseButton";
import { BaseInput } from "../../../components/base/BaseInput";
import { Stack } from "../../../components/base/Stack";
import BaseValidation from "../../../components/base/BaseValidation/BaseValidation";

import { EnterPasswordValidation } from "src/common/validation/index";
import { useValidatePassword } from "../../../hooks/useValidatePassword";
import { updatePassword } from "../../../api/hooks/personsHooks";
import "./index.css";

type SigninEnterPasswordType = {
  password: string;
  confirmPassword: string;
};

export const SigninEnterPassword = () => {
  const { code } = useParams<{
    code: string;
  }>();
  const navigate = useNavigate();
  if (!code) {
    navigate("/signin");
    return null;
  }

  const form = useForm<SigninEnterPasswordType>({
    resolver: yupResolver(EnterPasswordValidation),
  });
  const { errors } = form.formState;
  const { password, confirmPassword } = form.watch();
  const [isValidLength, isValidSymbols, isEquals] = useValidatePassword({
    password,
    confirmPassword,
  });

  const onSubmit = async (data: SigninEnterPasswordType) => {
    try {
      await updatePassword({ password: data.password, code });
      navigate("/signin");
    } catch (e) {
      form.setError("confirmPassword", {
        message: "Произошла ошибка",
      });
    }
  };

  return (
    <CredentialsForm title="Новый пароль">
      <div className="SigninEnterPassword">
        <form
          className="SigninEnterPassword__form"
          onSubmit={form.handleSubmit(onSubmit)}
          id="form-signin-enter-password-id"
        >
          <Stack space={10}>
            <Stack space={10}>
              <BaseInput
                className="SigninEnterPassword__input"
                placeholder="Пароль"
                type="password"
                {...form.register("password", {
                  onChange: () => {
                    form.trigger("password");
                  },
                })}
              />
              <BaseInputError<SigninEnterPasswordType>
                errors={errors}
                field="password"
              />
            </Stack>
            <Stack space={10}>
              <BaseInput
                className="SigninEnterPassword__input"
                placeholder="Повторите пароль"
                type="password"
                {...form.register("confirmPassword", {
                  onChange: () => {
                    form.trigger("confirmPassword");
                  },
                })}
              />
              <BaseInputError<SigninEnterPasswordType>
                errors={errors}
                field="confirmPassword"
              />
            </Stack>
          </Stack>
        </form>
        <div className="SigninEnterPassword__control">
          <BaseButton
            className="SigninEnterPassword__button"
            type="submit"
            form="form-signin-enter-password-id"
          >
            Подтвердить
          </BaseButton>
        </div>
        <div className="SigninEnterPassword__validations">
          <BaseValidation valid={isValidLength} text="Не менее 8 символов" />
          <BaseValidation valid={isValidSymbols} text="Латиница и цифры" />
          <BaseValidation valid={isEquals} text="Пароли должны совпадать" />
        </div>
      </div>
    </CredentialsForm>
  );
};
