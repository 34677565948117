import styles from "./FooterHome.module.scss";
import footerimage from "../../common/images/svg/footer-img.svg";
import firstSemechka from "../../common/images/svg/semechka2.svg";
import secondSemechka from "../../common/images/svg/semechka3.svg";

export const FooterHome = () => {
  return (
    <footer className={styles.footer_home}>
      <div className={styles.footer_home__main_wrapper}>
        <div className="container">
          <div className={styles.footer_home__wrapper}>
            <div className={styles.footer_home__left}>
              <div className={styles.footer_home__text}>
                Не знаю как это работает, но Тёрка подбирает того человека,
                который нужен тебе именно сейчас. Важно в нём это разглядеть.
              </div>
            </div>

            <div className={styles.footer_home__image_wrapper}>
              <img
                src={footerimage}
                alt=""
                className={styles.footer_home__image}
              />

              <div className={styles.footer_home__semechka_wrapper_first}>
                <img
                  src={firstSemechka}
                  alt=""
                  className={styles.footer_home__semechka_first}
                />
              </div>
              <div className={styles.footer_home__semechka_wrapper_second}>
                {/* <Image
                  priority
                  src={semechka}
                  alt='image'
                  className='footer__semechka second'
                /> */}
                <img
                  src={secondSemechka}
                  alt=""
                  className={styles.footer_home__semechka_second}
                />
              </div>
            </div>

            <div className={styles.footer_home__right}>
              <div className={styles.footer_home__text}>
                Наверное, Тёрку делают колдуны и ведьмы!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer_home__bottom_wrapper}>
        <p className={styles.footer_home__copyright}>(С) Тёрка, 2020-2024</p>
        <a href="tel:+79520559846">
          <p className={styles.footer_home__number}>+7 952 055 98 46 </p>
        </a>
      </div>
    </footer>
  );
};
