import { FormSchema3DataAllType } from "./index";
import { SignUpRoutesStateType } from "../index";

export const preparePackageData = (data: any, state: SignUpRoutesStateType) => {
  const commonData = {
    firstName: state.step1?.firstName,
    lastName: state.step1?.lastName,
    email: state.step1?.email,
    password: state.step1?.password,
    wasAcceptedPolicy: state.step1?.wasAcceptedPolicy,
  };

  const meetingData = {
    birthday: state.step2?.birthday,
    gender: state.step2?.gender,
    poll: {
      purpose: {
        meeting: state.step2?.purpose.meeting,
        events: state.step2?.purpose.events,
        all: state.step2?.purpose.all,
      },
      communication: {
        proffessional: data.poll?.communication?.professional,
        romantic: data.poll?.communication?.romantic,
        chat: data.poll?.communication?.chat,
      },
      gender: {
        male: data.poll?.gender?.male,
        female: data.poll?.gender?.female,
      },
      age: {
        from: data.age?.from,
        till: data.age?.till === 56 ? 100 : data.age?.till,
      },
      aboutMe: state.step2?.aboutMe,
    },
    socials: {
      telegram: data.socials?.telegram,
      vk: data.socials?.vk,
      whatsapp: data.socials?.whatsapp,
      facebook: data.socials?.facebook,
      instagram: data.socials?.instagram,
    },
  };

  const eventsData = {
    isNotifyByEvents: data.isNotifyByEvents,
    socials: {
      telegram: data.socials?.telegram,
    },
    poll: {
      purpose: {
        meeting: state.step2?.purpose.meeting,
        events: state.step2?.purpose.events,
        all: state.step2?.purpose.all,
      },
    },
  };

  const res = {
    ...commonData,
    ...(state.step2?.purpose.meeting || state.step2?.purpose.all
      ? meetingData
      : eventsData),
    isStore: state.step1?.isStore,
  };

  return res;
};

export const preparePackageDataUpdateProfile = (
  data: FormSchema3DataAllType,
  state: SignUpRoutesStateType,
) => {
  return {
    birthday: state.step2?.birthday ? state.step2.birthday : null,
    gender: state.step2?.gender ? state.step2?.gender : null,
    isNotifyByEvents: data.isNotifyByEvents ? data.isNotifyByEvents : false,
    personSocials: {
      telegram:
        data.socials !== undefined && data.socials.telegram !== undefined
          ? data.socials.telegram
          : "",
      vk:
        data.socials !== undefined && data.socials.vk !== undefined
          ? data.socials.vk
          : "",
      whatsapp:
        data.socials !== undefined && data.socials.whatsapp !== undefined
          ? data.socials.whatsapp
          : "",
      facebook:
        data.socials !== undefined && data.socials.facebook !== undefined
          ? data.socials.facebook
          : "",
      instagram:
        data.socials !== undefined && data.socials.instagram !== undefined
          ? data.socials.instagram
          : "",
    },
    personPolls: {
      purpose: {
        meeting: state.step2?.purpose.meeting,
        events: state.step2?.purpose.events,
        all: state.step2?.purpose.all,
      },
      communication: {
        proffessional:
          data.poll !== undefined && data.poll.communication.professional > 0
            ? data.poll.communication.professional
            : 0,
        romantic:
          data.poll !== undefined && data.poll.communication.romantic > 0
            ? data.poll.communication.romantic
            : 0,
        chat:
          data.poll !== undefined && data.poll.communication.chat > 0
            ? data.poll.communication.chat
            : 0,
      },
      gender: {
        male:
          data.poll !== undefined && data.poll.gender.male
            ? data.poll.gender.male
            : false,
        female:
          data.poll !== undefined && data.poll.gender.female
            ? data.poll.gender.female
            : false,
      },
      age: {
        from: data.age !== undefined && data.age.from ? data.age.from : null,
        till:
          data.age !== undefined && data.age.till
            ? data.age.till === 56
              ? 100
              : data.age.till
            : null,
      },
      aboutMe: state.step2?.aboutMe !== undefined ? state.step2?.aboutMe : null,
    },
  };
};

export const preparePackageMigrateUser = (
  data: FormSchema3DataAllType,
  state: SignUpRoutesStateType,
) => {
  const newDataMigrate =
    state.step2?.firstName && state.step2?.lastName
      ? {
          firstName: state.step2?.firstName,
          lastName: state.step2?.lastName,
        }
      : null;

  const personSocials = data.socials
    ? {
        telegram:
          data.socials.telegram !== undefined ? data.socials.telegram : "",
        vk: data.socials.vk !== undefined ? data.socials.vk : "",
        whatsapp:
          data.socials.whatsapp !== undefined ? data.socials.whatsapp : "",
        facebook:
          data.socials.facebook !== undefined ? data.socials.facebook : "",
        instagram:
          data.socials.instagram !== undefined ? data.socials.instagram : "",
      }
    : null;

  const personPurpose = {
    purpose: {
      meeting: state.step2?.purpose.meeting,
      events: state.step2?.purpose.events,
      all: state.step2?.purpose.all,
    },
  };

  const personPollTwo =
    state.step2?.birthday && state.step2?.gender
      ? {
          birthday: state.step2?.birthday ? state.step2.birthday : null,
          gender: state.step2?.gender ? state.step2?.gender : null,
        }
      : null;

  const personPollThree = {
    communication: {
      proffessional:
        data.poll !== undefined && data.poll.communication.professional > 0
          ? data.poll.communication.professional
          : 0,
      romantic:
        data.poll !== undefined && data.poll.communication.romantic > 0
          ? data.poll.communication.romantic
          : 0,
      chat:
        data.poll !== undefined && data.poll.communication.chat > 0
          ? data.poll.communication.chat
          : 0,
    },
    gender: {
      male:
        data.poll !== undefined && data.poll.gender.male
          ? data.poll.gender.male
          : false,
      female:
        data.poll !== undefined && data.poll.gender.female
          ? data.poll.gender.female
          : false,
    },
    age: {
      from: data.age !== undefined && data.age.from ? data.age.from : null,
      till:
        data.age !== undefined && data.age.till
          ? data.age.till === 56
            ? 100
            : data.age.till
          : null,
    },
    aboutMe: state.step2?.aboutMe !== undefined ? state.step2?.aboutMe : null,
  };

  console.log(personPollThree);

  return {
    ...newDataMigrate,
    ...(personPollTwo !== null ? personPollTwo : null),
    personPolls: {
      ...personPurpose,
      ...personPollThree,
    },
    personSocials,
    isOld: false,
  };
};
