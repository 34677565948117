import React from "react";

import { Modal } from "src/components/base/Modal";
import { BaseButton } from "../../components/base/BaseButton";
import { BaseTypography } from "../../components/base/BaseTypography";
import { useJwtPayload } from "../../hooks/useJwtPayload";
import { LINK_TO_BOT } from "../../common/consts/bot";

import styles from "./TelegramCheckModal.module.scss";

type PropsType = {
  open: boolean;
  onClose: () => void;
}

function TelegramCheckModal({ open, onClose }: PropsType) {
  const user = useJwtPayload();

  const handleFinalStep = () => {
    if (user && ("personId" in user)) {
      window.open(LINK_TO_BOT + "?start=" + user?.personId);
    }
  }

  return (
    <Modal isOpen={open}
           toggle={onClose}
           className={styles.TelegramCheckModal}
    >
      <BaseTypography
        className={styles.TelegramCheckModal__title}
        variant={'p'}
      >
        Свяжите ваш профиль с Telegram
      </BaseTypography>
      <BaseTypography
        className={styles.TelegramCheckModal__text}
        variant={'p'}
      >
        Если после активации сообщение не пропало - обновите, пожалуйста, страницу.
      </BaseTypography>
        <BaseButton
          className={styles.TelegramCheckModal__button}
          variant="base-button-new-style-black"
          onClick={handleFinalStep}
        >
          В Telegram
        </BaseButton>
    </Modal>
  );
}

export default TelegramCheckModal;