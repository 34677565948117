import { useNavigate } from "react-router-dom";

import { BaseIcon } from "src/components/base/BaseIcon";
import { Button } from "src/components/base/Button";
import { Modal } from "src/components/base/Modal";

import { QueriesEnum, RoutesEnum } from "src/common/enums";
import styles from "./CloseEventModal.module.scss";

type PropsType = {
  isOpen: boolean;
  toggle: () => void;
};

export default function CloseEventModal({ isOpen, toggle }: PropsType) {
  const navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className={styles.CloseEventModal}>
        <div className={styles.CloseEventModal__header}>
          <div className={styles.CloseEventModal__title}>
            Данные будут потеряны, если закрыть страницу
          </div>
          <BaseIcon
            className={styles.CloseEventModal__icon}
            onClick={toggle}
            viewBox="0 0 16 16"
            height="16"
            width="16"
            fill="none"
            icon="TICK"
          />
        </div>
        <div className={styles.CloseEventModal__buttons}>
          <Button
            className={styles.CloseEventModal__button_cancel}
            onClick={toggle}
            fullWidth={true}
            variant="contained_nuar"
          >
            Отмена
          </Button>
          <div
            className={styles.CloseEventModal__button_close}
            onClick={() => navigate(RoutesEnum.EVENTS + QueriesEnum.ALL_EVENTS)}
          >
            Закрыть
          </div>
        </div>
      </div>
    </Modal>
  );
}
