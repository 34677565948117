import React from "react";
import clsx from "clsx";

import { useCloseByOutsideClick } from "src/hooks/useCloseByOutsideClick";
import { OptionType } from "src/common/types/option-type";
import { BaseIcon } from "src/components/base/BaseIcon";

import { useModal } from "src/hooks/useModal";
import styles from "./SelectTags.module.scss";

type PropsType = {
  className?: string;
  placeholder?: string;
  options: OptionType[] | undefined;
  value: OptionType[] | undefined;
  onChange: (options: OptionType[]) => void;
  maxLenght?: number;
  disabled?: boolean;
};

export default function SelectTags({
  className = "",
  placeholder,
  options = [],
  value = [],
  onChange,
  maxLenght,
  disabled,
}: PropsType) {
  const { toggle, toggleValue, isShowing } = useModal();
  const { ref } = useCloseByOutsideClick(toggleValue);

  const handlePropagation = (
    e: React.MouseEvent<HTMLInputElement | HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  // To open drop down menu by click on select
  const handleOpenSelect = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    toggle();
  };

  // To handle remove tag from value list
  const handleRemoveTag =
    (choseId: string) =>
    (
      e:
        | React.MouseEvent<HTMLOrSVGElement, MouseEvent>
        | React.SyntheticEvent<Element, Event>,
    ) => {
      if (disabled) return;
      e.stopPropagation();

      const result = value.filter((item) => item.id !== choseId);
      onChange(result);
    };

  // To handle select of the tags
  const handleSelectTag =
    (choseItem: OptionType) =>
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!value || value?.length === 0) {
        onChange([choseItem]);
        return;
      }

      const foundItem = value?.find((item) => item.id === choseItem.id);

      if (foundItem) {
        const result = value.filter((item) => item.id !== choseItem.id);
        onChange(result);
      } else {
        // If chose more
        if (maxLenght && maxLenght === value.length) return;
        onChange([...value, choseItem]);
      }
    };

  // Ref for search input
  const searchInputRef = React.createRef<HTMLInputElement>();

  const [search, setSerch] = React.useState("");

  React.useEffect(() => {
    if (!searchInputRef?.current) return;
    if (!isShowing) return;

    searchInputRef.current.focus();

    // Reset search state
    return () => setSerch("");
  }, [isShowing]);

  // Filter by options
  const filteredOptions = React.useMemo(() => {
    return options.filter((option) =>
      option.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    );
  }, [search, options]);

  // Find checked option
  const isCheckedOption = React.useMemo(
    () => (checkedId: string) => {
      return !!value.find(({ id }) => id === checkedId);
    },
    [filteredOptions, value],
  );

  return (
    <div
      className={clsx({
        [styles.SelectTags]: true,
        [className]: !!className,
      })}
      onClick={handleOpenSelect}
      ref={ref}
    >
      <div className={styles.SelectTags__input}>
        <div className={styles.SelectTags__content}>
          {(!value || value?.length === 0) && !isShowing ? (
            <div className={styles.SelectTags__placeholder}>{placeholder}</div>
          ) : (
            value?.map(({ id, name }, key) => (
              <div className={styles.SelectTags__tag} key={key}>
                <div className={styles.SelectTags__tag_name}>{name}</div>
                <BaseIcon
                  className={styles.SelectTags__tag_icon}
                  onClick={handleRemoveTag(id)}
                  viewBox="0 0 14 14"
                  height="14"
                  width="14"
                  icon="TICK_SMALL"
                />
              </div>
            ))
          )}
          <input
            className={clsx({
              [styles.SelectTags__placeholder]: true,
              [styles.SelectTags__placeholder_hide]: !isShowing,
            })}
            placeholder={"Поиск"}
            onChange={(e) => setSerch(e.target.value)}
            onClick={handlePropagation}
            value={search}
            ref={searchInputRef}
          />
        </div>
        <BaseIcon
          className={clsx({
            [styles.SelectTags__arrow_icon]: true,
            [styles.SelectTags__arrow_icon_active]: isShowing,
          })}
          viewBox="0 0 14 9"
          height="9"
          width="14"
          icon="ARROW_DOWN_OUTLINED"
        />
      </div>
      <div
        className={clsx({
          [styles.SelectTags__options]: true,
          [styles.SelectTags__options_hide]: isShowing,
        })}
        onClick={handlePropagation}
      >
        {filteredOptions.length > 0 ? (
          filteredOptions.map((item, key) => (
            <div
              className={clsx({
                [styles.SelectTags__option]: true,
                [styles.SelectTags__option_active]: isCheckedOption(item.id),
              })}
              onClick={handleSelectTag(item)}
              key={key}
            >
              <div className={styles.SelectTags__option_text}>{item.name}</div>
              <BaseIcon
                className={styles.SelectTags__option_icon}
                viewBox="0 0 18 13"
                height="13"
                width="18"
                fill="none"
                icon="CHECK"
              />
            </div>
          ))
        ) : (
          <div className={styles.SelectTags__not_found}>Нет результатов</div>
        )}
      </div>
    </div>
  );
}
