import { LayoutAccount } from "src/layouts";

import { Meeting } from "../../content";

import { useMatches } from "../../api/hooks/matchesHooks";
import { useMe } from "../../api/hooks/personsHooks";
import styles from "./Meetings.module.scss";

const MeetingsPage = () => {
  const { data: me } = useMe();
  const { data: matches } = useMatches({ personId: me?.id ?? undefined });

  const isMoreThenZero = matches && matches.items.length > 0;

  return (
    // TODO ДОБАВИТ ИЗОБРАЖЕНИЕ ГОПНИКА КАК НА ДИЗАЙНЕ
    <>
      <LayoutAccount title="Мои встречи">
        <div className={styles.Meetings}>
          {isMoreThenZero ? (
            matches.items.map((match, i) => (
              <Meeting personId={me?.id} match={match} key={i} />
            ))
          ) : (
            <div>Встреч пока нет :(</div>
          )}
        </div>
      </LayoutAccount>
    </>
  );
};

export default MeetingsPage;
