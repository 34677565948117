import { FormControlLabel, Switch, SwitchProps, styled } from "@mui/material";

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "250ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        background: "#303030",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    background: "#FFFFFF",
    borderRadius: "50%",
    boxShadow:
      "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.08);",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 12,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    background: "rgba(121, 121, 131, 0.05)",
    border: "0.5px solid rgba(121, 121, 131, 0.3)",
  },
}));

type PropsType = SwitchProps & {
  label?: string;
};

export default function BaseSwitch({
  label = "",
  className = "",
  ...rest
}: PropsType) {
  return (
    <FormControlLabel
      className={className}
      sx={{
        "& > .MuiTypography-root": {
          fontFamily: "Proxima Nova",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          marginLeft: "12px",
          color: "#303030",
        },
      }}
      control={<StyledSwitch defaultChecked {...rest} />}
      label={label}
    />
  );
}
