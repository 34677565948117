import React, { ReactNode } from 'react';

import { BaseImage } from "../../components/base/BaseImage";
import PurchasesAndNetworking from 'src/common/images/purchases_and_networking.webp';

import style from './CheckboxNetworking.module.scss';

type PropsType = {
  children: ReactNode;
}

const CheckboxNetworking = ({children}: PropsType) => {

  return (
    <div className={style.CheckboxNetworking}>
      {children}
      <div className={style.CheckboxNetworking__title}>
        Хочу все возможности Тёрки
        <br className={style.CheckboxNetworking__br}/>
        (заполнить анкету)
      </div>
      <div className={style.CheckboxNetworking__sign}>
        Заполнить свой профиль вы сможете в Личном кабинете в любое время
      </div>
      <BaseImage
        className={style.CheckboxNetworking__image}
        src={PurchasesAndNetworking}
        alt={''}/>
    </div>
  );
};

export default CheckboxNetworking;
