import { SignUpRoutesStateType } from "../index";

export const preparePackageFromStore = (state: SignUpRoutesStateType) => {
  return {
    firstName: state.step1?.firstName,
    lastName: state.step1?.lastName,
    email: state.step1?.email,
    password: state.step1?.password,
    wasAcceptedPolicy: state.step1?.wasAcceptedPolicy,
    isStore: state.step1?.isStore,
  };
}