import { BaseIcon } from "src/components/base/BaseIcon";
import { Button } from "src/components/base/Button";
import { Modal } from "src/components/base/Modal";

import styles from "./ConfirmPayStatusModal.module.scss";

type PropsType = {
  isOpen: boolean;
  toggle: () => void;
  handleCancel: () => void;
  handleUpdatePayment: () => Promise<void>;
};

export function ConfirmPayStatusModal({
  isOpen,
  toggle,
  handleCancel,
  handleUpdatePayment,
}: PropsType) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className={styles.ConfirmPayStatusModal}>
        <BaseIcon
          className={styles.ConfirmPayStatusModal__Icon}
          onClick={handleCancel}
          viewBox="0 0 16 16"
          height="16"
          width="16"
          fill="none"
          icon="TICK"
        />
        <div className={styles.ConfirmPayStatusModal__Content}>
          <p className={styles.ConfirmPayStatusModal__Text}>Вы уверены?</p>
          <div className={styles.ConfirmPayStatusModal__Buttons}>
            <Button
              className={styles.ConfirmPayStatusModal__Button}
              onClick={handleUpdatePayment}
              fullWidth={true}
              variant="outlined_nuar"
            >
              Принять
            </Button>
            <Button
              className={styles.ConfirmPayStatusModal__Button}
              onClick={handleCancel}
              fullWidth={true}
              variant="contained_nuar"
            >
              Отмена
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
