import { z } from "zod";
import { nameValidate } from "../CommonFormSchema.schema";

export const FormSchema1Step = z.object({
  firstName: nameValidate,
  lastName: nameValidate,
  email: z
    .string({ required_error: "обязательно" })
    .email({ message: "Incorrect e-mail" }),
  password: z
    .string({ required_error: "обязательно" })
    //.refine((value) => /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/gi.test(value), "8смвл, цифры, буквы"),
    .min(8, "минимум 8 символов")
    .refine((value) => /\d/.test(value),"цифры")
    .refine((value) => /[a-zA-Z]/.test(value),"буквы"),
  wasAcceptedPolicy: z
    .boolean()
    .refine((value) => value, {message: 'Примите условия использования'}),
  wannaSignTerkaFull: z.boolean(),
  isStore: z.boolean(),
});
