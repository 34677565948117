import { FC, useState } from 'react';

import './index.scss';

type PropsType = {
  currentScore: number;
  maxScore: number;
  onChange: (value: number) => void;
};

export const BaseScore: FC<PropsType> = ({
  currentScore,
  maxScore,
  onChange,
}) => {
  const [counter, setCounter] = useState(currentScore);

  const onClickHandler = ({
    target,
  }: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const value = (target as HTMLLIElement).value;
    setCounter(value);
    onChange(value);
  };

  const setActiveClassName = (i: number) =>
    `${i + 1 <= counter ? 'base-score__item_active' : ''}`;

  return (
    <div className="base-score">
      <ul className="base-score__list">
        {Array(maxScore)
          .fill(null)
          .map((_: unknown, i: number) => (
            <li
              className={`base-score__item ${setActiveClassName(i)}`}
              onClick={onClickHandler}
              value={i + 1}
              key={i}
            ></li>
          ))}
      </ul>
      <div className="base-score__counter">
        {counter}/{maxScore}
      </div>
    </div>
  );
};
