import React from "react";

import { getFromStorage } from "src/common/local-storage";

export type UserStateType = {
  personId: string;
};

export const useJwtPayload = (): UserStateType | undefined => {
  const [user, setUser] = React.useState<UserStateType | undefined>(undefined);

  React.useEffect(() => {
    const token = getFromStorage<string>("ACCESS_TOKEN");

    if (!token) return;

    const data = JSON.parse(window.atob(token?.split(".")[1]));

    setUser({ personId: data.id });
  }, []);

  return user;
};
