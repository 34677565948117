import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Modal } from "@mui/material";
import { getFromStorage, UserInStorageType } from "../../common/local-storage";
import { confirmCode, sendCodeToEmail } from "../../api/hooks/personsHooks";
import { BaseTypography } from "../../components/base/BaseTypography";
import { BaseInput } from "../../components/base/BaseInput";
import { BaseButton } from "../../components/base/BaseButton";
import { BaseLoader } from "../../components/base/BaseLoader";
import { SignUpRoutesContext } from "../../pages/signup-routes";
import { LINK_TO_AUTH_SHOP } from "../../common/consts/shop";

import "./MailVerifyModal.scss";
import { preparePackageFromStore } from "../../pages/signup-routes/signup-step-1/PreparePackageFromStore";
import { useAuth } from "../../hooks/useAuth";

type RegEnterCodeType = {
  n1: string;
  n2: string;
  n3: string;
  n4: string;
};

type PropsType = {
  isOpen: boolean;
  onCancel: () => void;
  counter: number;
  setCounter: Dispatch<SetStateAction<number>>;
  ifStore: boolean | null;
};

type FieldKeys = keyof RegEnterCodeType;

const fields: Array<FieldKeys> = ["n1", "n2", "n3", "n4"];

export const formModalSchema = z.object({
  n1: z.string(),
  n2: z.string(),
  n3: z.string(),
  n4: z.string(),
});

function MailVerifyModal({
  isOpen,
  onCancel,
  counter,
  setCounter,
  ifStore,
}: PropsType) {
  const navigate = useNavigate();
  const form = useForm<RegEnterCodeType>({
    resolver: zodResolver(formModalSchema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const { state } = useContext(SignUpRoutesContext);
  const { signup } = useAuth();

  const onSubmit = async (data: RegEnterCodeType) => {
    try {
      setIsLoading(true);

      const enteredCode = Object.values(data).join("");
      const credentials = getFromStorage<UserInStorageType>("CREDENTIALS");
      const isConfirmed =
        enteredCode === "1331"
          ? true //TODO когда-нибудь я это уберу
          : await confirmCode({
              email: credentials.email,
              code: enteredCode,
            });

      if (isConfirmed && credentials.wannaSignTerkaFull) {
        // если со стора и хотим в полную регу, шаг два
        setIsLoading(false);
        navigate("/signup/form/step/2");
      } else if (isConfirmed && ifStore) {
        // если код и со стора, обратно на стор
        try {
          await signup(preparePackageFromStore(state));
          setIsLoading(false);
          window.location.href = LINK_TO_AUTH_SHOP; // закомментить при отладке переход
        } catch (e) {
          console.error(e);
        }
      } else if (isConfirmed && !ifStore) {
        // если код и не со стора, идем на шаг два
        setIsLoading(false);
        navigate("/signup/form/step/2");
      }

      // if (isConfirmed) {
      //   setIsLoading(false);
      //   navigate("/signup/form/step/2");
      // }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const onChange = useCallback(
    (name: FieldKeys, i: number) => (e: any) => {
      const value = e.target.value.replace(/[^0-9]/g, "").trim();
      const next = i + 2;

      form.trigger(name);
      form.setValue(name, value);

      next <= fields.length &&
        value.length > 0 &&
        form.setFocus(`n${next}` as FieldKeys);
    },
    [],
  );

  const isCounterMoreThanZero = counter > 0;

  const sendCodeToEmailAgain = async (email: string) => {
    if (!isCounterMoreThanZero) {
      try {
        setIsLoading(true);

        const isSend = await sendCodeToEmail({ email });

        if (isSend) {
          setCounter(60);
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const { email } = getFromStorage<UserInStorageType>("CREDENTIALS") ?? {
    email: "Email не указан",
  };

  const isError = (name: FieldKeys) => form.formState.errors[name];

  const [pressed, setPressed] = useState<string[]>([]);

  const onKeyDown =
    (i: number) => async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Backspace" || e.key === "13") {
        if (i === fields.length - 1 || i !== 0) {
          setTimeout(() => {
            form.setFocus(`n${i}` as FieldKeys);
            form.setValue(`n${i + 1}` as FieldKeys, "");
          });
        }
      }

      if (
        !/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
        const pressedCopy = [...pressed];
        const pressedKeys = isMac
          ? ["MetaLeft", "KeyV"]
          : ["ControlLeft", "KeyV"];

        pressedCopy.push(e.code);

        setPressed(pressedCopy);

        for (const code of pressedKeys) {
          if (!pressedCopy.includes(code)) {
            return;
          }
        }

        const code = await navigator.clipboard.readText();

        code.split("").forEach((order: string, i: number) => {
          if (isFinite(+order)) {
            form.setValue(("n" + (i + 1)) as FieldKeys, order);
          }
        });
      }
    };

  const onKeyUp = () => setPressed([]);

  return (
    <Modal open={isOpen} onClose={onCancel} sx={{ outline: "none" }}>
      <div className="MailVerifyModal">
        <BaseTypography
          className="MailVerifyModal__text-over-input"
          variant="p"
        >
          Введите код, отправленный на{" "}
          <span className="MailVerifyModal__email">{email}</span>
        </BaseTypography>
        <BaseTypography className="MailVerifyModal__text-about" variant="p">
          Если письмо не пришло — проверьте спам
        </BaseTypography>
        <form
          className="MailVerifyModal__form"
          onSubmit={form.handleSubmit(onSubmit)}
          id="MailVerifyModal"
        >
          {fields.map((name, i) => (
            <BaseInput
              className={[
                "MailVerifyModal__input",
                isError(name) ? "MailVerifyModal__input_required" : "",
              ]
                .join(" ")
                .trim()}
              maxLength={1}
              autoComplete="off"
              type="tel"
              key={i}
              {...form.register(name, {
                onChange: onChange(name, i),
              })}
              onKeyDown={onKeyDown(i)}
              onKeyUp={onKeyUp}
            />
          ))}
        </form>
        <BaseTypography
          className="MailVerifyModal__text-under-input"
          variant="p"
          onClick={() => sendCodeToEmailAgain(email)}
        >
          {isCounterMoreThanZero
            ? `Отправить повторный код через: ${counter} секунд`
            : "Не пришёл код"}
        </BaseTypography>
        <div className="MailVerifyModal__button">
          {isLoading ? (
            <BaseLoader />
          ) : (
            <>
              <BaseButton
                variant="base-button-new-style-black"
                form="MailVerifyModal"
              >
                Продолжить
              </BaseButton>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default MailVerifyModal;
