import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { CredentialsForm } from "src/layouts/CredentialsForm";
import { BaseCheckbox } from "src/components/base/BaseCheckbox";
import { BaseLoader } from "src/components/base/BaseLoader";
import { BaseButton } from "src/components/base/BaseButton";
import { BaseInput } from "src/components/base/BaseInput";
import { Stack } from "src/components/base/Stack";
import { CheckedIcon } from "../../../components/base/BaseIcons";
import MailVerifyModal from "../../../content/MailVerifyModal/MailVerifyModal";
import { setToStorage } from "../../../common/local-storage";
import { BaseInputPassword } from "../../../components/base";
import { BaseInputError } from "../../../components/base/BaseInputError";
import { SignUpRoutesContext } from "../index";
import { checkEmail, sendCodeToEmail } from "../../../api/hooks/personsHooks";
import { toast } from "react-hot-toast/headless";
import { useCounter } from "../../../hooks/useCounter";
import { CheckboxNetworking } from "../../../content/CheckboxNetworking";

import { FormSchema1Step } from "./FormSchema1Step.schema";
import "./index.scss";
import { useQueryIsStore } from "../../../hooks/useQuery";

export type FormSchema1DataType = z.infer<typeof FormSchema1Step>;

export const SignupStep1 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleState } = useContext(SignUpRoutesContext);
  const [passValidation, setPassValidation] = useState({
    passValid: {
      isMoreThan8: false,
      hasNumber: false,
      hasLetter: false,
      isMoreThan0: false,
    },
  });

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormSchema1DataType>({
    mode: "onBlur",
    resolver: zodResolver(FormSchema1Step),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      wasAcceptedPolicy: false,
      wannaSignTerkaFull: false,
      isStore: false,
    },
  });

  const isStore = useQueryIsStore(); // hook говорит true если со стора
  useEffect(() => {
    if (isStore) {
      //если есть квери параметр со стора, задаем isStore
      setValue("isStore", true);
    }
  }, [isStore]);

  const { counter, setCounter } = useCounter(0);

  const onSubmit = async (data: FormSchema1DataType) => {
    try {
      setIsLoading(true);

      //валидируем эмейл
      const validateEmail = await checkEmail({ email: data.email });
      if (validateEmail.isExists) {
        toast.error("Пользователь с таким email уже существует");
        return;
      }

      //сетим эмейл в сторейдж
      setToStorage("CREDENTIALS", data);

      //если счетчик больше нуля открываем модалку без отправки
      if (counter > 0) {
        setIsOpenVerifyModal(true);
        return;
      }

      // если счетчик 0 отправляем письмо
      const isSend = await sendCodeToEmail({ email: data.email });

      // если отправилось ставим счетчик в 60
      if (isSend) {
        handleState({ step1: data });
        setIsOpenVerifyModal(true);
        setCounter(60);
      }
    } catch (e: any) {
      //const parseAsync = await FormSchema1Step.parseAsync(data);
      //console.log(parseAsync);
    } finally {
      setIsLoading(false);
    }
  };

  const [isOpenVerifyModal, setIsOpenVerifyModal] = React.useState(false);
  const toggleVerify = () => setIsOpenVerifyModal((prev) => !prev);

  return (
    <CredentialsForm title="Регистрация">
      <div className="signup-step-1">
        <form
          className="signup-step-1__form"
          onSubmit={handleSubmit(onSubmit)}
          id="signup-step-1"
        >
          <Stack space={20} className="signup-step-1__gap14">
            {/*имя фамилия*/}
            <div className="signup-step-1__box">
              <Stack space={10}>
                <BaseInput
                  className={clsx({
                    "signup-step-1__input": true,
                    "signup-step-1__input-first-name": true,
                  })}
                  placeholder="Имя"
                  maxLength={50}
                  {...register("firstName")}
                  error={!!errors?.firstName?.message}
                />
                {/*<BaseInputError errors={errors as any} field="firstName" />*/}
              </Stack>
              <Stack space={10}>
                <BaseInput
                  className="signup-step-1__input signup-step-1__input-last-name"
                  placeholder="Фамилия"
                  maxLength={50}
                  {...register("lastName")}
                  error={!!errors?.lastName?.message}
                />
                {/*<BaseInputError errors={errors as any} field="lastName" />*/}
              </Stack>
            </div>

            {/*мейл*/}
            <div className="signup-step-1__box signup-step-1__mail-box">
              <Stack space={10}>
                <BaseInput
                  className="signup-step-1__input"
                  placeholder="Ваша электронная почта"
                  {...register("email")}
                  error={!!errors?.email?.message}
                />
                {/*<BaseInputError errors={form.formState.errors} field="email" />*/}
              </Stack>
              Укажите действующий адрес, вышлем на него код подтверждения
            </div>

            <Stack space={10}>
              <Controller
                name={"password"}
                defaultValue={watch("password")}
                control={control}
                render={({ field }) => (
                  <BaseInputPassword
                    className="signup-step-1__input"
                    placeholder="Пароль"
                    value={field.value}
                    error={!!errors?.password?.message}
                    onChange={(e) => {
                      field.onChange(e);

                      const isMoreThan8 = e.target.value.length >= 8;
                      const isMoreThan0 = e.target.value.length > 0;
                      const hasNumber = /\d/.test(e.target.value);
                      const hasLetter = /[a-zA-Z]/.test(e.target.value);

                      setPassValidation({
                        ...passValidation,
                        passValid: {
                          isMoreThan8,
                          hasNumber,
                          hasLetter,
                          isMoreThan0,
                        },
                      });
                    }}
                  />
                )}
              />
              <span className="signup-step-1__valid-span">
                <div className="signup-step-1__valid-checker">
                  <CheckedIcon
                    className="signup-step-1__valid-icon"
                    color={
                      !passValidation.passValid.isMoreThan0
                        ? "#C7C7C7"
                        : passValidation.passValid.isMoreThan8
                        ? "#16A249"
                        : "#E90000"
                    }
                  />
                  8+ символов
                </div>
                <div className="signup-step-1__valid-checker">
                  <CheckedIcon
                    className="signup-step-1__valid-icon"
                    color={
                      !passValidation.passValid.isMoreThan0
                        ? "#C7C7C7"
                        : passValidation.passValid.hasNumber
                        ? "#16A249"
                        : "#E90000"
                    }
                  />
                  Цифры
                </div>
                <div className="signup-step-1__valid-checker">
                  <CheckedIcon
                    className="signup-step-1__valid-icon"
                    color={
                      !passValidation.passValid.isMoreThan0
                        ? "#C7C7C7"
                        : passValidation.passValid.hasLetter
                        ? "#16A249"
                        : "#E90000"
                    }
                  />
                  Буквы
                </div>
              </span>
            </Stack>
            {/*<BaseInputError errors={errors} field="password" />*/}

            {/*если со стора*/}
            {isStore && (
              <CheckboxNetworking>
                <BaseCheckbox
                  className="privacy-policy__checkbox"
                  checked={watch("wannaSignTerkaFull")}
                  {...register("wannaSignTerkaFull")}
                />
              </CheckboxNetworking>
            )}

            {/*private policy*/}
            <Stack space={10}>
              <div className="signup-step-1__privacy-policy privacy-policy">
                <BaseCheckbox
                  className="privacy-policy__checkbox"
                  checked={watch("wasAcceptedPolicy")}
                  {...register("wasAcceptedPolicy")}
                />
                <p className="privacy-policy__text">
                  Я соглашаюсь с{" "}
                  <a
                    className="privacy-policy__link"
                    href="/privacy.pdf"
                    target="_blank"
                  >
                    правилами сайта и на обработку персональных данных
                  </a>
                </p>
              </div>
              <BaseInputError errors={errors} field="wasAcceptedPolicy" />
            </Stack>
          </Stack>
        </form>
        {isLoading ? (
          <BaseLoader />
        ) : (
          <>
            <BaseButton
              className="signup-step-1__button"
              variant="base-button-new-style-black"
              form="signup-step-1"
            >
              Продолжить
            </BaseButton>
          </>
        )}
      </div>
      <MailVerifyModal
        isOpen={isOpenVerifyModal}
        onCancel={toggleVerify}
        counter={counter}
        setCounter={setCounter}
        ifStore={watch("isStore")}
      />
    </CredentialsForm>
  );
};
