import { ButtonHTMLAttributes } from "react";
import { clsx } from "clsx";

import style from "./Button.module.scss";
import { CircularProgress } from "@mui/material";

type PropsType = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  variant: "contained_nuar" | "outlined_nuar";
  fullWidth?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
};

export default function Button({
  className = "",
  children,
  fullWidth = false,
  variant,
  isLoading = false,
  disabled = false,
  ...rest
}: PropsType) {
  return (
    <button
      className={clsx({
        [style["Button_" + variant]]: !!variant,
        [style.Button_full_width]: !!fullWidth,
        [style.Button]: true,
        [className]: !!className,
      })}
      aria-label="Button for click"
      type="button"
      disabled={disabled || isLoading}
      {...rest}
    >
      {isLoading ? (
        <CircularProgress className={style.Button__loader} />
      ) : (
        children
      )}
    </button>
  );
}
