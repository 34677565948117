import React, { useEffect, useState } from "react";
import toast from "react-hot-toast/headless";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";

import { Stack } from "../../../components/base/Stack";
import {
  SelectContained,
  SubscribeMeets,
  SurveySubtitle,
  SurveyTitle,
} from "../../../content";
import { BaseButton } from "../../../components/base/BaseButton";
import { BaseTypography } from "../../../components/base/BaseTypography";
import { BaseIcon } from "../../../components/base/BaseIcon";
import { BaseInput } from "../../../components/base/BaseInput";
import { BaseTextarea } from "../../../components/base/BaseTextarea";
import BaseCheckboxItem from "../../../components/base/BaseCheckboxItem/BaseCheckboxItem";
import AgeSelector from "../../../content/AgeSelector/AgeSelector";
import { BaseInputSocials } from "../../../components/base";
import { useJwtPayload } from "../../../hooks/useJwtPayload";
import {
  getProfile,
  ProfileType,
  updateEventsInProfile,
  updateProfile,
  useGetAllowedParticipants,
  useGetProfile,
} from "../../../api/persons";
import { LINK_TO_BOT } from "../../../common/consts/bot";
import AccountFillFull from "./AccountFillFull";

import { FormAccountSchema } from "../FormAccount.schema";
import styles from "./AccountMeets.module.scss";
import clsx from "clsx";
import { StackCategoryWrapper } from "src/content/StackCategoryWrapper/StackCategoryWrapper";
import { StackCategory } from "src/content/StackCategory/StackCategory";
import { CategorySelector } from "src/content/CategorySelector";
import { StackRomantic } from "src/content/StackRomantic/StackRomantic";

type UpdateFormType = {
  personSocials: {
    telegram: string;
    vk: string;
    whatsapp: string;
    facebook: string;
    instagram: string;
  };
  personPolls: {
    purpose: {
      meeting: boolean;
      events: boolean;
      all: boolean;
    };
    communication: {
      proffessional: number;
      romantic: number;
      chat: number;
    };
    gender: {
      male: boolean;
      female: boolean;
    };
    age: {
      from: number | null;
      till: number | null;
    };
    aboutMe: string;
  };
};

type MeetsType = {
  formDisable: boolean;
};

const DEFAUL_VALUE = {
  birthday: "--.--.----",
  gender: "Не заполнено",
  personPolls: {
    purpose: {
      meeting: false,
      events: false,
      all: false,
    },
    communication: {
      proffessional: 0,
      romantic: 0,
      chat: 0,
    },
    aboutMe: "Не заполнено",
  },

  personSocials: {
    telegram: "Не заполнено",
    vk: "",
    whatsapp: "",
    facebook: "",
    instagram: "",
  },
};

const AccountMeets = ({ formDisable }: MeetsType) => {
  const user = useJwtPayload();

  const [readOnly, setReadOnly] = useState(true);

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ProfileType>({
    mode: "onSubmit",
    resolver: zodResolver(FormAccountSchema),
    defaultValues: DEFAUL_VALUE,
  });

  const { data: myProfile, refetch } = useGetProfile();

  //обновление при каждом редактировании
  useEffect(() => {
    if (!myProfile) return;

    setValue("isNotifyByEvents", myProfile.isNotifyByEvents);
    setValue(
      "birthday",
      myProfile.birthday && new Date(myProfile.birthday).toString(),
    );
    setValue("gender", myProfile.gender);
    setValue(
      "personPolls.aboutMe",
      myProfile.personPolls.aboutMe
        ? myProfile.personPolls.aboutMe
        : "Не заполнено",
    );
    setValue("personPolls.purpose", myProfile.personPolls.purpose);
    setValue("personPolls.communication", myProfile.personPolls.communication);
    setValue(
      "personPolls.gender.male",
      myProfile.personPolls.gender?.male ?? false,
    );
    setValue(
      "personPolls.gender.female",
      myProfile.personPolls.gender?.female ?? false,
    );
    setValue("personPolls.age", {
      from: myProfile.personPolls.age?.from ?? 18,
      till: myProfile.personPolls.age?.till ?? 100,
    });

    setValue(
      "personSocials.telegram",
      myProfile.personSocials?.telegram
        ? myProfile.personSocials?.telegram
        : "Не заполнено",
    );
    setValue("personSocials.vk", myProfile.personSocials?.vk ?? "");
    setValue("personSocials.whatsapp", myProfile.personSocials?.whatsapp ?? "");
    setValue("personSocials.facebook", myProfile.personSocials?.facebook ?? "");
    setValue(
      "personSocials.instagram",
      myProfile.personSocials?.instagram ?? "",
    );
  }, [readOnly, myProfile]);

  const stateWatch = watch();

  const showAge = stateWatch?.personPolls?.communication?.romantic > 0;

  const communicationLabel =
    stateWatch?.personPolls?.communication?.proffessional ||
    stateWatch?.personPolls?.communication?.romantic ||
    stateWatch?.personPolls?.communication?.chat
      ? `${
          stateWatch?.personPolls?.communication?.proffessional
            ? "Профессиональные"
            : ""
        }${
          stateWatch?.personPolls?.communication?.romantic
            ? "Романтические"
            : ""
        }${
          stateWatch?.personPolls?.communication?.chat
            ? `${
                stateWatch?.personPolls?.communication?.proffessional
                  ? ", "
                  : ""
              }Просто общение`
            : ""
        }`
      : "Не заполнено";

  useEffect(() => {
    (async () => {
      const pack = { isNotifyByEvents: stateWatch.isNotifyByEvents };
      if (pack.isNotifyByEvents === undefined) return;
      if (pack) {
        await updateEventsInProfile(pack);
      }
    })();
  }, [stateWatch.isNotifyByEvents]);

  useEffect(() => {
    if (stateWatch?.personPolls?.purpose?.meeting === true) {
      setValue("isNotifyByEvents", false);
    }
  }, [stateWatch?.personPolls?.purpose?.meeting]);

  function formatDate(dateString: string) {
    const inputDate = new Date(dateString);

    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1;
    const year = inputDate.getFullYear();

    return (
      (day < 10 ? "0" + day : day) +
      "." +
      (month < 10 ? "0" + month : month) +
      "." +
      year
    );
  }

  const openTelegram = () => {
    if (user && "personId" in user) {
      window.open(LINK_TO_BOT + "?start=" + user?.personId);
    }
  };

  const onSubmit = async (data: UpdateFormType) => {
    try {
      const payload: UpdateFormType = {
        ...data,
        personPolls: {
          ...data.personPolls,
          age:
            data.personPolls.communication.romantic > 0
              ? data.personPolls.age
              : {
                  from: null,
                  till: null,
                },
        },
      };

      await updateProfile(payload);
      setReadOnly(true);
      refetch();
      toast.success("Все изменения сохранены", { duration: 3000 });
    } catch (e) {
      console.error(e);
    }
  };

  const { data } = useGetAllowedParticipants();
  const counterAllowPeople = data;
  const allowed = ["Доступен", "Доступно", "Доступно"];
  const participant = ["собеседник", "собеседника", "собеседников"];

  const definition = (number: number, txt: string[]) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return txt[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  };

  return (
    <>
      <Stack space={30}>
        {formDisable && <AccountFillFull />}
        <span
          className={clsx({
            [styles.Poll__line]: true,
            [styles.AccountMeets__disabled]:
              !stateWatch?.personPolls?.purpose?.meeting &&
              !stateWatch?.personPolls?.purpose?.events &&
              !stateWatch?.personPolls?.purpose?.all,
          })}
        >
          <div
            className={clsx({
              [styles.AccountMeets__disabled]:
                stateWatch?.personPolls?.purpose?.meeting,
            })}
          >
            {/*{!stateWatch?.personPolls?.purpose?.meeting && (*/}
            <Controller
              name={"isNotifyByEvents"}
              control={control}
              // defaultValue={stateWatch.isNotifyByEvents}
              render={({ field }) => (
                <SubscribeMeets
                  className={styles.Poll__notify}
                  title={"Уведомления по мероприятиям"}
                  checked={stateWatch.isNotifyByEvents}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
            {/*)}*/}
          </div>
          <BaseButton
            variant={"base-text-btn"}
            className={styles.Poll__line}
            type="button"
            onClick={openTelegram}
          >
            <BaseTypography
              variant={"p"}
              format={"profile-sub-title"}
              className={styles.Poll__text}
            >
              <span className={styles.Poll__hide_text}>Перейти</span> в Telegram
            </BaseTypography>
            <BaseIcon
              icon={"EXT_LINK"}
              viewBox={"0 0 24 24"}
              className={styles.Poll__icon}
            />
          </BaseButton>
        </span>
        <form
          className={clsx({
            [styles.AccountMeets__form]: true,
            [styles.AccountMeets__disabled]: formDisable,
          })}
          onSubmit={handleSubmit(onSubmit)}
          id="account-form"
        >
          <Stack space={30}>
            <div className={styles.Poll__grid_box}>
              <BaseTypography
                variant={"h2"}
                format={"landing-title-lvl-4"}
                className={styles.AccountMeets__title}
              >
                Параметры встреч
              </BaseTypography>
              <div className={styles.AccountMeets__counter}>
                {!formDisable && ( // было readOnly &&
                  <span className={styles.AccountMeets__counter}>
                    (
                    {counterAllowPeople !== undefined
                      ? definition(counterAllowPeople, allowed)
                      : null}{" "}
                    <span style={{ color: "#024BCD", fontWeight: 600 }}>
                      {counterAllowPeople}
                    </span>{" "}
                    {counterAllowPeople !== undefined
                      ? definition(counterAllowPeople, participant)
                      : null}
                    )
                  </span>
                )}
              </div>
              {readOnly && (
                <BaseButton
                  variant={"base-text-btn"}
                  className={styles.Poll__grid_button}
                  onClick={() => {
                    setReadOnly(false);
                    // console.log("включить редактирование", readOnly);
                  }}
                  type="button"
                >
                  <BaseTypography
                    variant={"p"}
                    format={"profile-sub-title"}
                    className={styles.Poll__hide_text}
                  >
                    Редактировать
                  </BaseTypography>
                  <BaseIcon
                    icon={"EDIT"}
                    viewBox={"0 0 24 24"}
                    className={styles.Poll__icon}
                  />
                </BaseButton>
              )}
            </div>
            <Stack space={30}>
              <div className={styles.Poll__row}>
                <Stack space={16}>
                  <BaseTypography variant={"p"} format={"profile-sub-title"}>
                    Что вы хотите от Тёрки?
                  </BaseTypography>
                  {readOnly ? (
                    <BaseInput
                      disabled={true}
                      value={
                        stateWatch?.personPolls?.purpose?.all
                          ? "Мне интересны и встречи и мероприятия"
                          : stateWatch?.personPolls?.purpose?.events
                          ? "Мне интересны только мероприятия"
                          : stateWatch?.personPolls?.purpose?.meeting
                          ? "Мне интересны только встречи"
                          : "Я покупатель Тёрка store"
                      }
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={"personPolls.purpose"}
                      render={({ field }) => {
                        const foundValue = field.value
                          ? Object.entries(field.value).find((item) => {
                              return item[1];
                            })
                          : null;
                        const foundFirstOption = firstOptions.find((item) => {
                          return item.id === foundValue?.[0];
                        });

                        return (
                          <SelectContained
                            className={styles.Poll__input}
                            options={firstOptions}
                            value={foundFirstOption}
                            onChange={(value) => {
                              if (!Array.isArray(value)) {
                                const newValue = {
                                  meeting: false,
                                  events: false,
                                  all: false,
                                };

                                field.onChange({
                                  ...newValue,
                                  [value.id]: true,
                                });
                              }
                            }}
                            multiple={false}
                          />
                        );
                      }}
                    />
                  )}
                  <Stack space={8}>
                    <BaseTypography
                      className={styles.AccountMeets__CategoryTitle}
                      variant={"p"}
                    >
                      Категории общения
                    </BaseTypography>
                    <BaseTypography
                      className={styles.AccountMeets__CategorySubtitle}
                      variant={"p"}
                    >
                      Укажите, насколько вы заинтересованы в категории по шкале
                      до 10 и свои предпочтения
                    </BaseTypography>
                  </Stack>
                </Stack>
                {/* <Stack space={16}>

                  {readOnly ? (
                    <BaseInput disabled={true} value={communicationLabel} />
                  ) : (
                    <Controller
                      control={control}
                      name={"personPolls.communication"}
                      render={({ field }) => {
                        const result = Object.entries(field.value)
                          .filter(([key, value]) => value)
                          .map(([key, value]) => ({
                            name: (communicationDictionary as any)[key],
                            id: key,
                          }));

                        return (
                          <SelectContained
                            className={styles.Poll__input}
                            options={secondOptions}
                            value={result}
                            error={!!errors.personPolls?.communication}
                            onChange={(value) => {
                              const result = {
                                proffessional: false,
                                romantic: false,
                                chat: false,
                              };

                              // const foundOldProffessional = Object.entries(
                              //   stateWatch.personPolls.communication,
                              // ).find(
                              //   ([key, value]) =>
                              //     key === "proffessional" && value === true,
                              // );
                              // const foundOldRomantic = Object.entries(
                              //   stateWatch.personPolls.communication,
                              // ).find(
                              //   ([key, value]) =>
                              //     key === "romantic" && value === true,
                              // );
                              // const foundOldChat = Object.entries(
                              //   stateWatch.personPolls.communication,
                              // ).find(
                              //   ([key, value]) =>
                              //     key === "chat" && value === true,
                              // );

                              const foundNewProffessional = value.find(
                                ({ id }) => id === "proffessional",
                              );
                              const foundNewRomantic = value.find(
                                ({ id }) => id === "romantic",
                              );
                              const foundNewChat = value.find(
                                ({ id }) => id === "chat",
                              );

                              for (const item of value) {
                                (result as any)[item.id] = true;
                              }

                              // if (
                              //   (foundOldRomantic && foundNewProffessional) ||
                              //   (foundOldRomantic && foundNewChat)
                              // ) {
                              //   result.romantic = false;
                              // } else if (
                              //   (foundOldChat && foundNewRomantic) ||
                              //   (foundOldProffessional && foundNewRomantic) ||
                              //   (foundOldChat &&
                              //     foundOldProffessional &&
                              //     foundNewRomantic)
                              // ) {
                              //   result.proffessional = false;
                              //   result.chat = false;
                              // }

                              field.onChange(result);
                              // console.log("test result", result);
                            }}
                            multiple={true}
                          />
                        );
                      }}
                    />
                  )}
                </Stack> */}
              </div>
              <div>
                <StackCategoryWrapper space={24}>
                  <Stack space={24}>
                    <StackCategory label="Профессиональные">
                      <Controller
                        name="personPolls.communication.proffessional"
                        control={control}
                        defaultValue={
                          stateWatch.personPolls?.communication?.proffessional
                        }
                        render={({ field }) => (
                          <CategorySelector
                            value={field.value}
                            onChange={(_, value) => {
                              field.onChange(value);
                            }}
                            label={"professional"}
                            disabled={readOnly}
                          />
                        )}
                      />
                    </StackCategory>
                    <StackCategory label="Просто общение">
                      <Controller
                        name="personPolls.communication.chat"
                        control={control}
                        defaultValue={
                          stateWatch.personPolls?.communication?.chat
                        }
                        render={({ field }) => (
                          <CategorySelector
                            value={field.value}
                            onChange={(_, value) => {
                              field.onChange(value);
                            }}
                            label={"chat"}
                            disabled={readOnly}
                          />
                        )}
                      />
                    </StackCategory>
                  </Stack>
                  <Stack space={4}>
                    <SurveyTitle className={styles.Poll__gender_title}>
                      Я готов(-а) общаться с:
                    </SurveyTitle>
                    <Stack columns={2} space={9}>
                      <Controller
                        name={"personPolls.gender.male"}
                        control={control}
                        defaultValue={stateWatch.personPolls?.gender?.male}
                        render={({ field }) => (
                          <BaseCheckboxItem
                            answerTitle={"Мужчина"}
                            answerChecked={field.value}
                            error={!!errors?.personPolls?.gender}
                            onChange={(e: boolean) => {
                              field.onChange(e);
                            }}
                            disabled={readOnly}
                          />
                        )}
                      />
                      <Controller
                        name={"personPolls.gender.female"}
                        control={control}
                        defaultValue={stateWatch.personPolls?.gender?.female}
                        render={({ field }) => (
                          <BaseCheckboxItem
                            answerTitle={"Женщина"}
                            answerChecked={field.value}
                            error={!!errors?.personPolls?.gender}
                            onChange={(e: boolean) => {
                              field.onChange(e);
                            }}
                            disabled={readOnly}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </StackCategoryWrapper>
                <StackCategoryWrapper space={24}>
                  <StackRomantic description="Сердечко рядом с именем означает, что собеседник не против романтического общения">
                    <Controller
                      name="personPolls.communication.romantic"
                      control={control}
                      defaultValue={
                        stateWatch.personPolls?.communication?.romantic
                      }
                      render={({ field }) => (
                        <CategorySelector
                          value={field.value}
                          onChange={(_, value) => {
                            field.onChange(value);

                            if (value === 0) {
                              setValue("personPolls.age", {
                                from: 18,
                                till: 56,
                              });
                            }
                          }}
                          label={"romantic"}
                          disabled={readOnly}
                        />
                      )}
                    />
                  </StackRomantic>
                  {showAge && (
                    <Stack>
                      <SurveySubtitle className="signup-step-3__label">
                        Укажите возраст
                      </SurveySubtitle>
                      <Controller
                        name={"personPolls.age"}
                        control={control}
                        defaultValue={stateWatch.personPolls.age}
                        render={({ field }) => (
                          <AgeSelector
                            value={stateWatch.personPolls.age}
                            onChange={(event, value) => {
                              if (Array.isArray(value)) {
                                field.onChange({
                                  from: value[0],
                                  till: value[1],
                                });
                              }
                            }}
                            disabled={readOnly}
                          />
                        )}
                      />
                    </Stack>
                  )}
                </StackCategoryWrapper>
              </div>
              {/* <Stack space={16}>
                <BaseTypography variant={"p"} format={"profile-sub-title"}>
                  С людьми какого пола и возраста вы готовы общаться?
                </BaseTypography>
                {readOnly ? (
                  <BaseInput disabled={true} value={genderWithAgeInput} />
                ) : (
                  <Stack space={22}>
                    <Stack columns={2} space={9}>
                      <Controller
                        name={"personPolls.gender.male"}
                        control={control}
                        // defaultValue={stateWatch.personPolls?.gender?.male}
                        render={({ field }) => (
                          <BaseCheckboxItem
                            className={styles.Poll__input}
                            answerTitle={"Мужчина"}
                            answerChecked={stateWatch.personPolls.gender.male}
                            error={!!errors?.personPolls?.gender}
                            onChange={(e: boolean) => {
                              field.onChange(e);
                            }}
                            disabled={genderDisable.Male}
                          />
                        )}
                      />
                      <Controller
                        name={"personPolls.gender.female"}
                        control={control}
                        // defaultValue={stateWatch.personPolls?.gender?.female}
                        render={({ field }) => (
                          <BaseCheckboxItem
                            className={styles.Poll__input}
                            answerTitle={"Женщина"}
                            answerChecked={stateWatch.personPolls.gender.female}
                            error={!!errors?.personPolls?.gender}
                            onChange={(e: boolean) => {
                              field.onChange(e);
                            }}
                            disabled={genderDisable.Female}
                          />
                        )}
                      />
                    </Stack>

                    <Controller
                      name={"personPolls.age"}
                      control={control}
                      defaultValue={stateWatch.personPolls.age}
                      render={({ field }) => (
                        <AgeSelector
                          value={stateWatch.personPolls.age}
                          onChange={(event, value) => {
                            if (Array.isArray(value)) {
                              field.onChange({
                                from: value[0],
                                till: value[1],
                              });
                            }
                          }}
                        />
                      )}
                    />
                  </Stack>
                )}
              </Stack> */}
              <Stack space={16}>
                <BaseTypography variant={"p"} format={"profile-sub-title"}>
                  Расскажите о себе
                </BaseTypography>
                <BaseTextarea
                  className="signup-step-3__about-info-field"
                  placeholder="Постарайтесь рассказать все максимально подробно"
                  {...register("personPolls.aboutMe")}
                  error={!!errors?.personPolls?.aboutMe?.message}
                  maxLength={500}
                  disabled={readOnly}
                />
              </Stack>
              <div className={styles.Poll__grid}>
                <Stack space={16}>
                  <BaseTypography variant={"p"} format={"profile-sub-title"}>
                    Пол
                  </BaseTypography>
                  <BaseInput disabled={true} value={stateWatch?.gender} />
                </Stack>
                <Stack space={16}>
                  <BaseTypography variant={"p"} format={"profile-sub-title"}>
                    Дата рождения
                  </BaseTypography>

                  <BaseInput
                    disabled={true}
                    // value={formatDate(stateWatch?.birthday)}
                    value={
                      stateWatch?.birthday
                        ? formatDate(stateWatch?.birthday)
                        : "--.--.----"
                    }
                  />
                </Stack>
              </div>
              <Stack space={16}>
                <BaseTypography variant={"p"} format={"profile-sub-title"}>
                  Как с вами может связаться ваш собеседник
                </BaseTypography>
                <div className={styles.Poll__grid}>
                  <Controller
                    name={"personSocials.telegram"}
                    control={control}
                    render={({ field }) => (
                      <BaseInputSocials
                        variant={"TG"}
                        placeholder="@username"
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        value={stateWatch.personSocials?.telegram}
                        //error={!!errors?.personSocials?.telegram?.message}
                        disabled={true}
                      />
                    )}
                  />
                  {stateWatch.personSocials?.vk.length > 0 || !readOnly ? (
                    <Controller
                      name={"personSocials.vk"}
                      control={control}
                      render={({ field }) => (
                        <BaseInputSocials
                          variant={"VK"}
                          placeholder="VK"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={stateWatch.personSocials?.vk}
                          disabled={readOnly}
                        />
                      )}
                    />
                  ) : null}
                  {stateWatch.personSocials.whatsapp.length > 0 || !readOnly ? (
                    <Controller
                      name={"personSocials.whatsapp"}
                      control={control}
                      render={({ field }) => (
                        <BaseInputSocials
                          variant={"WA"}
                          placeholder="Whatsapp"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={stateWatch.personSocials?.whatsapp}
                          disabled={readOnly}
                        />
                      )}
                    />
                  ) : null}
                  {stateWatch.personSocials.facebook.length > 0 || !readOnly ? (
                    <Controller
                      name={"personSocials.facebook"}
                      control={control}
                      render={({ field }) => (
                        <BaseInputSocials
                          variant={"FB"}
                          placeholder="Facebook"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={stateWatch.personSocials?.facebook}
                          disabled={readOnly}
                        />
                      )}
                    />
                  ) : null}
                  {stateWatch.personSocials?.instagram.length > 0 ||
                  !readOnly ? (
                    <Controller
                      name={"personSocials.instagram"}
                      control={control}
                      render={({ field }) => (
                        <BaseInputSocials
                          variant={"IG"}
                          placeholder="Instagram"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={stateWatch.personSocials?.instagram}
                          disabled={readOnly}
                        />
                      )}
                    />
                  ) : null}
                </div>
              </Stack>
            </Stack>
            {!readOnly && (
              <div className={styles.AccountMeets__box_buttons}>
                <BaseButton
                  className={styles.AccountMeets__button}
                  variant={"base-text-btn"}
                  type="reset"
                  onClick={() => {
                    setReadOnly(true);
                  }}
                >
                  Отмена
                </BaseButton>
                <BaseButton
                  className={styles.AccountMeets__button}
                  variant={"base-button-new-style-black"}
                  form={"account-form"}
                  // type="submit"
                >
                  Сохранить
                </BaseButton>
              </div>
            )}
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default AccountMeets;

const firstOptions = [
  { name: "Встречи 1 на 1", id: "meeting" },
  { name: "Хочу быть участником мероприятий", id: "events" },
  { name: "Мне интересны и встречи и мероприятия", id: "all" },
];

const secondOptions = [
  { name: "Профессиональные", id: "proffessional" },
  { name: "Романтические", id: "romantic" },
  { name: "Просто общение", id: "chat" },
];

const communicationDictionary = {
  proffessional: "Профессиональные",
  romantic: "Романтические",
  chat: "Просто общение",
};
