import { useQuery } from "react-query";
import { getFromStorage } from "src/common/local-storage";
import { request } from "../request";

export type PersonType = {
  id: string;
  tgId: string;
  username: string;
  firstName: string;
  lastName: string;
  favouriteHobbies: Array<string>;
  interestingAreas: Array<string>;
  liveInKaliningrad: boolean;
  hasCompletedPoll: boolean;
  gender: string;
  age: number;
  score: Array<number>;
  city: string;
  otherCity: boolean;
  aboutMe: string;
  isStopped: boolean;
  social: string;
  email: string;
  createdAt: string;
  birthday: string;
  readyToChatOnline: boolean;
  // matches: Array<MatchType>;
};

type CheckEmailResponseType = {
  isExists: boolean;
};

type PersonHasPollAnswerType = {
  isExists: boolean;
};

type SendCodeToEmailType = {
  email: string;
};

type ConfirmCodeType = {
  email: string;
  code: string;
};

type CheckTelegramResponseType = {
  isExists: boolean;
};

type TelegramExistsResponseType = {
  isExists: boolean;
};

export type GetCountsResponseType = {
  hasCompletedPoll: number;
  hasNotComplitedPoll: number;
};

export const personHasPoll = async () => {
  const { data } = await request<PersonHasPollAnswerType>({
    url: "/persons/actions/has_poll",
    method: "POST",
  });

  return data;
};

export const getMe = async () => {
  const { data } = await request<PersonType>({
    url: "/persons/me",
  });

  return data;
};

export const checkToken = async () => {
  const { data } = await request({
    url: "/persons/check-token",
    method: "POST",
  });

  return data;
};

export const resetPassword = async (payload: Pick<PersonType, "email">) => {
  const { data } = await request<void>({
    url: "/persons/actions/reset_password",
    method: "POST",
    data: payload,
  });

  return data;
};

export const updatePassword = async (payload: {
  code: string;
  password: string;
}) => {
  const { data } = await request<void>({
    url: "/persons/actions/update_password/" + payload.code,
    method: "POST",
    data: { newPassword: payload.password },
  });

  return data;
};

export const personHasUsername = async (username: string) => {
  const { data } = await request<boolean>({
    url: "/persons/actions/has_username/",
    method: "POST",
    data: {
      username,
    },
  });

  return data;
};

export const checkEmail = async (payload: { email: string }) => {
  const { data } = await request<CheckEmailResponseType>({
    url: "/auth/validate/email",
    method: "POST",
    data: payload,
  });

  return data;
};

export const checkTelegram = async (payload: { telegram: string }) => {
  const { data } = await request<CheckTelegramResponseType>({
    url: "/auth/validate/telegram",
    method: "POST",
    data: payload,
  });

  return data;
};

export const sendCodeToEmail = async (payload: SendCodeToEmailType) => {
  const { data } = await request<boolean>({
    url: "/auth/actions/send-code",
    method: "POST",
    data: payload,
  });

  return data;
};

export const confirmCode = async (payload: ConfirmCodeType) => {
  const { data } = await request<boolean>({
    url: "/auth/actions/confirm-code",
    method: "POST",
    data: payload,
  });

  return data;
};

export const telegramExists = async () => {
  const { data } = await request<TelegramExistsResponseType>({
    url: "/persons/check-tg",
    method: "GET",
  });

  return data;
};

export const getCounts = async () => {
  const { data } = await request<GetCountsResponseType>({
    url: "persons/counts",
  });

  return data;
};

export const checkAccessToken = async () => {
  const { data } = await request<{ isValid: boolean }>({
    url: "auth/validate/auth",
    data: {
      accessToken: getFromStorage("ACCESS_TOKEN") ?? "",
    },
    method: "POST",
  });

  return data;
};

//HOOKS

export const useMe = () =>
  useQuery<PersonType>(["user"], async () => await getMe(), {
    enabled: !!getFromStorage("ACCESS_TOKEN"),
    staleTime: 5000,
    cacheTime: 5000,
    notifyOnChangeProps: "tracked",
    refetchOnWindowFocus: true,
  });

export const useGetCounts = () =>
  useQuery<GetCountsResponseType>(
    ["users_counts"],
    async () => await getCounts(),
    {
      notifyOnChangeProps: "tracked",
    },
  );

export const useTelegramExists = () =>
  useQuery<TelegramExistsResponseType>(
    ["user_telegram"],
    async () => await telegramExists(),
    {
      enabled: !!getFromStorage("ACCESS_TOKEN"),
      refetchOnWindowFocus: true,
      notifyOnChangeProps: "tracked",
    },
  );
