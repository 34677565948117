import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';

export default function Signout() {
  const { signout } = useAuth();

  useEffect(() => {
    const signoutFunc = async () => {
      await signout();
    };

    signoutFunc();
  }, []);

  return <Outlet />;
}
