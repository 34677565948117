import React, { useEffect, useState } from "react";

import { telegramExists } from "../../api/hooks/personsHooks";
import { useModal } from "../../hooks/useModal";
import { TelegramCheckModal } from "../../modals/TelegramCheckModal";
import { BaseImage } from "../../components/base/BaseImage";
import { AccountTabs } from "../../content";
import {
  AccountCredentials,
  AccountMeets,
  AccountOrders,
} from "./AccountBlock";
import { getHasCompletedPoll, getProfile } from "../../api/persons";
import { MigrateUserModal } from "../../modals/MigrateUserModal";

import imageGopnikCreateEvent from "../../common/images/image-gopnik-create-event.webp";
import styles from "./styles.module.scss";

export default function Account() {
  const { isShowing, toggle } = useModal();

  const [isShowMigrate, setIsShowMigrate] = useState(false); //todo стейт на модалку миграции

  const [formDisable, setFormDisable] = useState(false);

  console.log(isShowMigrate);

  useEffect(() => {
    // проверка на хэс комплит полл
    (async () => {
      const hasCompletedPoll = await getHasCompletedPoll();
      if (hasCompletedPoll?.hasCompletedPoll === false) {
        setFormDisable(true);
      }
    })();
  }, []);

  useEffect(() => {
    // проверка на привязку телеги
    (async () => {
      const profile = await getProfile();
      const tgExist = await telegramExists();

      if (profile?.isOld === true) {
        setIsShowMigrate(true);
        return;
      }

      if (
        !tgExist?.isExists &&
        !isShowMigrate &&
        (profile.personPolls.purpose.meeting ||
          profile.personPolls.purpose.events ||
          profile.personPolls.purpose.all)
      ) {
        toggle();
      }
    })();
  }, []);

  const handleClose = async () => {
    try {
      const result = await telegramExists();
      if (!result.isExists) return;
      toggle();
    } catch (e: any) {
      console.error(e);
    }
  };

  // //todo ниже все по тестовым кнопкам
  // const [showTestButtons, setShowTestButtons] = useState(false) // тестовые всё кнопочки
  // const handler = () => {
  //   setShowTestButtons(!showTestButtons)
  // }
  //
  // const changeFormDisable = () => {
  //   setFormDisable(!formDisable)
  // }
  //
  // const getMyProfile = async () => {
  //   try {
  //     console.log("запросить полные данные");
  //     const data = await getProfile();
  //     console.log("данные пришли", data);
  //   } catch (error) {
  //     // Обработка ошибки
  //     console.error("Произошла ошибка:", error);
  //   }
  // };
  //
  // const getMyMe = async () => {
  //   try {
  //     console.log("запросить кусочек данных");
  //     const data = await getMe();
  //     console.log("данные пришли", data);
  //   } catch (error) {
  //     // Обработка ошибки
  //     console.error("Произошла ошибка:", error);
  //   }
  // };
  //
  // const getMyHasCompletedPoll = async () => {
  //   try {
  //     console.log("запрос из аккаунта")
  //     const tgExist = await telegramExists();
  //     const hasCompletedPoll = await getHasCompletedPoll();
  //     const profile = await getProfile();
  //
  //     console.log("результ из аккаунта");
  //     console.log("tgExist: ", tgExist);
  //     console.log("hasCompletedPoll: ", hasCompletedPoll);
  //     console.log("purpose: ", profile.personPolls.purpose);
  //
  //   } catch (error) {
  //     // Обработка ошибки
  //     console.error("Произошла ошибка:", error);
  //   }
  // }

  return (
    <section className={styles.Account}>
      <div className={styles.Account__blocks}>
        {/*ЛИЧНЫЕ ДАННЫЕ БЛОК*/}
        <AccountCredentials />
        {/*  */}
        <AccountTabs>
          {/*ВСТРЕЧИ БЛОК*/}
          <AccountMeets formDisable={formDisable} />
          {/*  */}
          {/*Заказы блок*/}
          <AccountOrders />
          {/*  */}
        </AccountTabs>
      </div>

      <div className={styles.Account__space}></div>
      <BaseImage
        className={styles.Account__image}
        src={imageGopnikCreateEvent}
        height="516"
        width="407.15"
      />
      <TelegramCheckModal open={isShowing} onClose={handleClose} />

      <MigrateUserModal
        open={isShowMigrate}
        onClose={() => {
          console.log();
        }} //TODO модалка на мигрейт старых юзеров
      />

      {/*Тестовые всё кнопочки*/}
      {/*<button onClick={handler} className={styles.TestButtons__showbutton}/>*/}
      {/*<div className={clsx({*/}
      {/*  [styles.TestButtons]: true,*/}
      {/*  [styles.TestButtons_active]: showTestButtons*/}
      {/*})}>*/}
      {/*  */}
      {/*  //TODO убрать тестовые кнопки*/}
      {/*  <Link to={"/signout"}>*/}
      {/*    <BaseButton>*/}
      {/*      logout*/}
      {/*    </BaseButton>*/}
      {/*  </Link>*/}

      {/*  <BaseButton*/}
      {/*    //handler*/}
      {/*    onClick={handler}*/}
      {/*    variant={"base-button-new-style-black"}*/}
      {/*  >*/}
      {/*    handler*/}
      {/*  </BaseButton>*/}

      {/*  <BaseButton*/}
      {/*    //order*/}
      {/*    onClick={() => {*/}
      {/*      console.log("пусто")*/}
      {/*    }}*/}
      {/*    variant={"base-button-new-style-black"}*/}
      {/*  >*/}
      {/*    orders*/}
      {/*  </BaseButton>*/}

      {/*  <BaseButton*/}
      {/*    //стейт незаполненности профиля и плашка*/}
      {/*    onClick={changeFormDisable}*/}
      {/*    variant={"base-button-new-style-black"}*/}
      {/*  >*/}
      {/*    {`form Disable ${formDisable}`}*/}
      {/*  </BaseButton>*/}

      {/*  /!*<BaseButton*!/*/}
      {/*  /!*  //отправить профиль с константой*!/*/}
      {/*  /!*  onClick={() => sendNewProfile(newData)}*!/*/}
      {/*  /!*  variant={"base-button-new-style-white"}*!/*/}
      {/*  /!*>*!/*/}
      {/*  /!*  send new*!/*/}
      {/*  /!*  Profile*!/*/}
      {/*  /!*</BaseButton>*!/*/}
      {/*  */}
      {/*  <BaseButton*/}
      {/*    //получить весь профиль с тем что есть в бд*/}
      {/*    onClick={getMyProfile}*/}
      {/*    variant={"base-button-new-style-white"}*/}
      {/*  >*/}
      {/*    get full*/}
      {/*    Profile*/}
      {/*  </BaseButton>*/}
      {/*  <BaseButton*/}
      {/*    //получить кусочек профиля*/}
      {/*    onClick={getMyMe}*/}
      {/*    variant={"base-button-new-style-white"}*/}
      {/*  >*/}
      {/*    get small*/}
      {/*    Profile*/}
      {/*  </BaseButton>*/}
      {/*  <BaseButton*/}
      {/*    //получить has-completed-poll*/}
      {/*    onClick={getMyHasCompletedPoll}*/}
      {/*    variant={"base-button-new-style-white"}*/}
      {/*  >*/}
      {/*    get profile*/}
      {/*    info*/}
      {/*  </BaseButton>*/}
      {/*</div>*/}
    </section>
  );
}
