import React, { FC, ForwardedRef, forwardRef, InputHTMLAttributes, useState } from "react";
import clsx from "clsx";

import { BaseIcon } from "../BaseIcon";

import "./BaseInputPassword.scss";

type PropsType = InputHTMLAttributes<HTMLInputElement> & { error?: boolean };

const BaseInputPassword: FC<PropsType> = forwardRef(
  ({ className = "", error, ...rest }, ref: ForwardedRef<any>) => {
    const [type, setType] = useState("password");

    return (
      <label className={clsx({
        "BaseInputPassword": true,
        "BaseInputPassword_required": error,
        [className]: !!className
      })}
      >
        <input
          className="input-password"
          type={type}
          autoComplete="off"
          {...rest}
          ref={ref}
        />
        <button className="show-input-button"
                type={"button"}
                onClick={() => setType(type === "password" ? "text" : "password")}
        >
          <BaseIcon
            viewBox="0 0 22 22"
            height="22"
            width="22"
            icon={type === "password" ? "EYE_OPEN" : "EYE_CLOSE"}
          />
        </button>
      </label>
    );
  }
);

export default BaseInputPassword;