import React from "react";
import { BaseLoader } from "src/components/base/BaseLoader";

import styles from "./Table.module.scss";

type PropsType<T> = {
    className?: string;
    columns: BaseTableColumns<T>;
    data: BaseTableData<T>;
    isLoading?: boolean;
    onClick?: <T extends number>(id: T) => void;
    hideHead?: boolean;
};

const Table = <T,>({
    className = '',
    columns,
    data,
    isLoading,
    hideHead,
    ...rest
}: PropsType<T>) => {
    return (
        <>
            <table className={[styles.Table, className].join(" ").trim()}>
                <thead className={styles.Table__head}>
                    <tr className={styles.Table__head_row}>
                        {!hideHead && <TableHeadColumns<T> columns={columns} />}
                    </tr>
                </thead>
                <tbody className={styles.Table__body}>
                    <TableBodyColumns<T>
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        {...rest}
                    />
                </tbody>
            </table>
        </>
    );
};

const TableHeadColumns = <T,>({
    columns,
}: {
    columns: BaseTableColumns<T>;
}) => {
    return (
        <>
            {columns.map(({ name }, key) => (
                <td className={styles.Table__head_cell} key={key}>
                    {name}
                </td>
            ))}
        </>
    );
};

const TableBodyColumns = <T,>({
    columns,
    data,
    isLoading,
    onClick,
}: PropsType<T>) => {
    return isLoading ? (
        <>
            <tr className={styles.Table__row}>
                <td
                    className={styles.Table__cell_loader}
                    colSpan={columns?.length}
                >
                    <BaseLoader />
                </td>
            </tr>
        </>
    ) : data?.length > 0 ? (
        <>
            {data.map((row: T, dataIndex: number) => (
                <tr className={styles.Table__row} key={dataIndex} onClick={() => onClick && onClick(((row as unknown as T)["id" as unknown as keyof T]) as unknown as number)}>
                    {columns.map(({ selector, ...rest }, columnIndex) => (
                        <td
                            className={styles.Table__cell}
                            key={columnIndex}
                            {...rest}
                        >
                            {selector && selector(row, dataIndex)}
                        </td>
                    ))}
                </tr>
            ))}
        </>
    ) : (
        <>
            <tr>
                <td
                    colSpan={columns?.length}
                    style={{ textAlign: "center" }}
                >

                </td>
            </tr>
        </>
    );
};

export type BaseTableColumns<T> = Array<{
    name: string;
    selector: (row: T, index: number) => React.ReactNode;
    style?: React.CSSProperties;
}>;

export type BaseTableData<T> = Array<T>;

export default Table;
