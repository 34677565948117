import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { CredentialsForm } from "../../../layouts/CredentialsForm";
import { BaseButton } from "src/components/base/BaseButton";
import { SurveyTitle } from "../../../content";
import { Stack } from "../../../components/base/Stack";
import BaseCheckboxItem from "../../../components/base/BaseCheckboxItem/BaseCheckboxItem";
import { SignUpRoutesContext } from "../index";
import { setToStorage } from "../../../common/local-storage";

import {
  AddCredentialsEventsFormSchema,
  AddCredentialsMeetingFormSchema,
  EventsFormSchema2Step,
  MeetingFormSchema2Step,
} from "./FormSchema2Step.schema";
import "./index.scss";
import { BaseTextarea } from "../../../components/base/BaseTextarea";
import { BaseSelect } from "../../../components/base/BaseSelect";
import { useQueryIsFillFull } from "../../../hooks/useQuery";
import { BaseInput } from "../../../components/base/BaseInput";
import { getProfile, useGetProfile } from "../../../api/persons";
import InputDateWithCalendar from "../../../components/base/InputDateWithCalendar/InputDateWithCalendar";

// export type FormSchema2DataType = z.infer<typeof FormSchema2Step>;

export type PurposeType = {
  meeting: boolean;
  events: boolean;
  all: boolean;
};

export type FormSchema2DataType = {
  purpose: PurposeType;
  gender?: string;
  birthday?: string;
  aboutMe?: string;
  fillFull?: boolean;
  firstName?: string;
  lastName?: string;
  isOld?: boolean;
  iWasAnonymous?: boolean;
};

export const SignupStep2 = () => {
  const navigate = useNavigate();
  const { state, handleState } = useContext(SignUpRoutesContext);

  //get profile hook for is old
  const { data } = useGetProfile();
  const [isOldUser, setIsOldUser] = useState(false);

  useEffect(() => {
    (async () => {
      const profile = await getProfile();
      if (profile.isOld) {
        setIsOldUser(true);
      }
    })();
  }, []);

  const iWasAnonymous = !(data?.firstName && data?.lastName);

  console.log("anon ", iWasAnonymous);
  console.log("im old", isOldUser);

  const [purpose, setPurpose] = useState({
    meeting: false,
    events: false,
    all: false,
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm<FormSchema2DataType>({
    resolver: zodResolver(
      iWasAnonymous && isOldUser
        ? purpose.events
          ? AddCredentialsEventsFormSchema
          : AddCredentialsMeetingFormSchema
        : purpose.events
        ? EventsFormSchema2Step
        : MeetingFormSchema2Step,
    ),
    mode: "all",
    reValidateMode: "onChange",

    defaultValues: {
      purpose: purpose,
      fillFull: false,
      isOld: isOldUser,
      iWasAnonymous: iWasAnonymous,
    },
  });

  useEffect(() => {
    if (isOldUser) {
      setValue("isOld", true);
    }
  }, [isOldUser]);

  const stateSelect = watch();

  const fillFull = useQueryIsFillFull();
  useEffect(() => {
    if (fillFull) {
      setValue("fillFull", true);
      // console.log("fillFull отрабатывает и записывает")
    }
  }, []);

  const [showStoreOnSelect, setShowStoreOnSelect] = useState(false); //показываем или не показываем селект опцию по стору
  useEffect(() => {
    if (state.step1 === null) return;
    if (state.step1.wannaSignTerkaFull) {
      setShowStoreOnSelect(true);
    }
  }, []);

  const changeState = (key: keyof PurposeType) => () => {
    // ревалидация от выбранного типа purpose
    for (const stateKey in stateSelect.purpose) {
      setValue(`purpose.${stateKey}` as any, stateKey === key, {
        shouldValidate: true,
      });
      clearErrors();
    }
    //console.log(stateSelect);
  };

  useEffect(() => {
    setPurpose(stateSelect.purpose);
  }, [
    stateSelect.purpose.all,
    stateSelect.purpose.events,
    stateSelect.purpose.meeting,
  ]);

  // min and current date
  // const minDate = new Date(1920, 0, 1, 0, 0, 0);
  // const eighteen = 18 * 365.3 * 24 * 60 * 60 * 1000;
  // const currentDate = momentTz.tz("Europe/Moscow").toDate();
  // const eighteenYearAgo = moment(currentDate.setTime(currentDate.getTime() - eighteen)
  // ).toDate();

  const onSubmit = async (data: FormSchema2DataType) => {
    setToStorage("CREDENTIALS", data);
    // console.log("data во втором шаге", data)
    handleState({ step2: data });
    navigate("/signup/form/step/3");
  };

  return (
    <CredentialsForm title="">
      <form
        className="signup-step-2"
        id="signup-step-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack space={28}>
          <SurveyTitle className="signup-step-2__title">
            Что вы хотите от Тёрки?
          </SurveyTitle>
          <Stack>
            <Stack space={14}>
              <BaseCheckboxItem
                answerTitle={"Встречи 1 на 1"}
                onChange={changeState("meeting")}
                answerChecked={stateSelect.purpose.meeting}
                // error={Object.entries(errors).length > 0}
                error={!!errors?.purpose}
              />
              <BaseCheckboxItem
                answerTitle={"Хочу быть участником мероприятий"}
                onChange={changeState("events")}
                answerChecked={stateSelect.purpose.events}
                // error={Object.entries(errors).length > 0}
                error={!!errors?.purpose}
              />
              <BaseCheckboxItem
                answerTitle={`Мне интересны и встречи и мероприятия ${
                  showStoreOnSelect ? "и покупки" : ""
                }`}
                onChange={changeState("all")}
                answerChecked={stateSelect.purpose.all}
                // error={Object.entries(errors).length > 0}
                error={!!errors?.purpose}
              />
              {showStoreOnSelect && (
                <BaseCheckboxItem
                  answerTitle={"Покупки в Тёрка сторе"}
                  disabled={true}
                  onChange={changeState("all")}
                  answerChecked={true}
                  // error={Object.entries(errors).length > 0}
                  // error={!!errors?.purpose}
                />
              )}
            </Stack>
            {(purpose.meeting || purpose.all || purpose.events) &&
              iWasAnonymous &&
              fillFull && (
                <Stack space={14}>
                  <SurveyTitle>Познакомимся?</SurveyTitle>

                  <Stack columns={2} space={31}>
                    <BaseInput
                      // className="signup-step-1__input signup-step-1__input-first-name"
                      placeholder="Имя"
                      maxLength={50}
                      {...register("firstName")}
                      error={!!errors?.firstName?.message}
                    />
                    <BaseInput
                      // className="signup-step-1__input signup-step-1__input-last-name"
                      placeholder="Фамилия"
                      maxLength={50}
                      {...register("lastName")}
                      error={!!errors?.lastName?.message}
                    />
                  </Stack>
                </Stack>
              )}
            {(purpose.meeting || purpose.all) && (
              <Stack space={14}>
                <SurveyTitle>Расскажите о себе</SurveyTitle>

                <BaseTextarea
                  className="signup-step-3__about-info-field"
                  placeholder="Постарайтесь рассказать все максимально подробно"
                  {...register("aboutMe")}
                  error={!!errors?.aboutMe?.message}
                  maxLength={500}
                />
                <Stack columns={2} space={31}>
                  <Controller
                    name={"gender"}
                    defaultValue={stateSelect.gender}
                    control={control}
                    render={({ field }) => (
                      <BaseSelect
                        placeholder="Пол"
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        error={!!errors?.gender?.message}
                        options={["Мужской", "Женский"]}
                      />
                    )}
                  />

                  <InputDateWithCalendar
                    className="signup-step-2__input"
                    {...register("birthday")}
                    error={!!errors?.birthday?.message}
                    onChange={(value) => {
                      setValue("birthday", value, {
                        shouldValidate: true,
                      });
                      clearErrors("birthday");
                    }}
                  />

                  {/*<BaseInputError errors={errors as any} field="birthday" />*/}
                </Stack>
              </Stack>
            )}
          </Stack>
          <div className="signup-step-2__button-box">
            <BaseButton
              className="signup-step-2__button"
              variant="base-button-new-style-black-with-white-arrow"
              type="submit"
              form="signup-step-2"
            >
              Продолжить
            </BaseButton>
          </div>
        </Stack>
      </form>
    </CredentialsForm>
  );
};
