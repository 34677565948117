import { Stack } from "src/components/base/Stack";
import { SurveySubtitle } from "..";

import styles from "./StackRomantic.module.scss";

type PropsType = {
  children: React.ReactNode;
  space?: number;
  description: string;
};

export function StackRomantic({
  children,
  space = 14,
  description,
}: PropsType) {
  return (
    <Stack space={space}>
      <Stack space={4}>
        <SurveySubtitle className={styles.StackRomantic__Label}>
          Романтические
        </SurveySubtitle>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            columnGap: "4px",
          }}
        >
          <HeartIcon />
          <SurveySubtitle className={styles.StackRomantic__Description}>
            {description}
            {/* Укажите, насколько вы заинтересованы в категории по шкале до 10 и
            свои предпочтения */}
          </SurveySubtitle>
        </div>
      </Stack>
      {children}
    </Stack>
  );
}

function HeartIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13C7.88393 13 7.78571 12.9609 7.70536 12.8828L3.52679 8.96354C3.48214 8.92882 3.42076 8.8724 3.34263 8.79427C3.26451 8.71615 3.14062 8.574 2.97098 8.36784C2.80134 8.16168 2.64955 7.95009 2.51562 7.73307C2.3817 7.51606 2.26228 7.25347 2.15737 6.94531C2.05246 6.63715 2 6.33767 2 6.04688C2 5.09201 2.28348 4.34549 2.85045 3.80729C3.41741 3.2691 4.20089 3 5.20089 3C5.47768 3 5.76004 3.04666 6.04799 3.13997C6.33594 3.23329 6.60379 3.35916 6.85156 3.51758C7.09933 3.676 7.3125 3.82465 7.49107 3.96354C7.66964 4.10243 7.83929 4.25 8 4.40625C8.16071 4.25 8.33036 4.10243 8.50893 3.96354C8.6875 3.82465 8.90067 3.676 9.14844 3.51758C9.39621 3.35916 9.66406 3.23329 9.95201 3.13997C10.24 3.04666 10.5223 3 10.7991 3C11.7991 3 12.5826 3.2691 13.1496 3.80729C13.7165 4.34549 14 5.09201 14 6.04688C14 7.00608 13.4888 7.98264 12.4665 8.97656L8.29464 12.8828C8.21429 12.9609 8.11607 13 8 13Z"
        fill="#797983"
      />
    </svg>
  );
}
