import { z } from "zod";

export const PollSchema = z.object({
  communication: z.object({
    professional: z.number(),
    romantic: z.number(),
    chat: z.number(),
  }),
  // .refine((value) => value.professional || value.romantic || value.chat),
  gender: z
    .object({
      male: z.boolean(),
      female: z.boolean(),
    })
    .refine((value) => value.male || value.female),
});

export const socialsSchema = z.object({
  telegram: z
    .string()
    .regex(
      /.*\B@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/,
      "некорректное",
    ),
  //.min(4, "min 4 symbols"),
  // .refine((value) => /.*\B@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/gi.test(value), "некорректное"),
  vk: z.string(),
  whatsapp: z.string(),
  facebook: z.string(),
  instagram: z.string(),
});

export const FormSchema3StepMeeting = z.object({
  age: z.object({
    from: z.number(),
    till: z.number(),
  }),
  poll: PollSchema,
  socials: socialsSchema,
});

export const FormSchema3StepEvents = z.object({
  isNotifyByEvents: z.boolean(),
  socials: socialsSchema,
});

export const FormSchema3StepAll = z.object({
  age: z.object({
    from: z.number(),
    till: z.number(),
  }),
  poll: PollSchema,
  socials: socialsSchema,
  isNotifyByEvents: z.boolean(),
});
