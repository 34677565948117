import { Stack } from "src/components/base/Stack";

import styles from "./StackCategoryWrapper.module.scss";
import clsx from "clsx";

export function StackCategoryWrapper({
  className,
  children,
  ...rest
}: {
  className?: string;
  children: React.ReactNode;
  space: number;
}) {
  return (
    <Stack className={clsx([styles.StackCategoryWrapper, className])} {...rest}>
      {children}
    </Stack>
  );
}
