import React from "react";

import imageNotUploaded from "src/common/images/image-preview.webp";
import styles from "./BaseImage.module.scss";

export type BaseImageLazyType = { lazyLoading?: boolean };

type PropsType =
  | (BaseImageLazyType & React.ImgHTMLAttributes<HTMLImageElement>)
  | (BaseImageLazyType & {
      src: File | string | undefined;
      className?: string;
    });

export const BaseImage = React.memo(
  ({ src, lazyLoading = false, ...rest }: PropsType) => {
    const url =
      typeof src === "object"
        ? URL.createObjectURL(src)
        : src ?? imageNotUploaded;

    // Loading state
    const [isLoaded, setIsLoaded] = React.useState(false);

    return lazyLoading ? (
      <>
        <div
          className={[rest.className, !isLoaded ? styles.BaseImage_loading : ""]
            .join(" ")
            .trim()}
        >
          <link rel="preload" as="image" href={url} />
          <img
            className={[rest.className, !isLoaded ? styles.BaseImage_hide : ""]
              .join(" ")
              .trim()}
            loading="lazy"
            style={!isLoaded ? { opacity: 0 } : {}}
            onLoad={() => setIsLoaded(() => true)}
            src={url}
            {...rest}
          />
        </div>
      </>
    ) : (
      <>
        <link rel="preload" as="image" href={url} />
        <img
          className="hand-with-clock__image"
          src={url}
          loading="lazy"
          {...rest}
        />
      </>
    );
  },
);
