import { Slider, styled } from "@mui/material";

export const BaseSlider = styled(Slider)({
  fontFamily: "Proxima Nova",
  marginBottom: "unset",
  marginTop: 30,
  color: "#000",
  height: 2,
  padding: "0 !important",

  "& .MuiSlider-rail": {
    color: "#000",
    opacity: 1,
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#000",
    border: "none",
    transition: "none",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-markLabel": {
    fontFamily: "Proxima Nova",
    color: "#000",
    bottom: 20,
    fontSize: 16,
    fontWeight: 400,
    top: "unset",
  },
  "& .MuiSlider-valueLabel": {
    fontFamily: "Proxima Nova",
    lineHeight: 1.2,
    fontSize: 16,
    fontWeight: 400,
    background: "none",
    padding: "0 25px",
    width: 19,
    height: 19,
    color: "#000",
    backgroundColor: "rgba(1, 1, 1, 0) !important",
  },
});
