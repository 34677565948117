import React from "react";
import { InfiniteData } from "react-query";

import { PaginatedDto } from "src/api/commons/types";

export const useIsInfinityEntriesExists = <T>(
  data: InfiniteData<PaginatedDto<T>> | undefined,
) => {
  const [isRecordsExists, setIsRecordsExists] = React.useState(false);
  const [isMayUpload, setIsMayUpload] = React.useState(false);

  React.useEffect(() => {
    if (!data) return;

    const result = data?.pages.reduce((pv, nv) => {
      const count = pv + nv?.items?.length ?? 0;

      if (nv?.count === count) {
        setIsMayUpload(false);
      } else if (nv?.count && count < nv.count) {
        setIsMayUpload(true);
      }

      return count;
    }, 0);

    setIsRecordsExists(() => result === 0);
  }, [data]);

  return { isMayUpload, isRecordsExists };
};
