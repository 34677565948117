import { useEffect, useState } from 'react';

export const useCounter = (initialValue: number) => {
  const [counter, setCounter] = useState(initialValue);

  useEffect(() => {
    const onCount = () => {
      setCounter((counter) => {
        if (counter > 0) {
          return counter - 1;
        } else {
          return 0;
        }
      });
    };

    const intervalID = setInterval(onCount, 1000);

    return () => clearInterval(intervalID);
  }, []);

  return { counter, setCounter };
};
