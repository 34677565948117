import { ReactNode } from "react";
import "./index.scss";

type PropsType = {
  className?: string;
  children: ReactNode;
  variant: "h1" | "h2" | "h3" | "p" | "span";
  format?:
    | "form-link"
    | "landing-title-lvl-1"
    | "landing-title-lvl-2"
    | "landing-title-lvl-3"
    | "landing-title-lvl-4"
    | "landing-subtitle-lvl-1"
    | "landing-text-lvl-1"
    | "profile-title"
    | "profile-fill-title"
    | "profile-fill-subtitle"
    | "profile-sub-title"
    | "profile-text"
    | "";
  fontWeight?: number;
  fontSize?: number;
  onClick?: () => void;
};

export const BaseTypography = ({
  className = "",
  variant,
  children,
  format = "",
  fontWeight,
  fontSize,
  onClick,
}: PropsType) => {
  const classNameStr = [`base-typography`, format, className].join(" ").trim();
  const style = {
    fontWeight,
    fontSize,
  };
  switch (variant) {
    case "h1":
      return (
        <h1 className={classNameStr} onClick={onClick} style={style}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 className={classNameStr} onClick={onClick} style={style}>
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3 className={classNameStr} onClick={onClick} style={style}>
          {children}
        </h3>
      );
    case "p":
      return (
        <p className={classNameStr} onClick={onClick} style={style}>
          {children}
        </p>
      );
    case "span":
      return (
        <span className={classNameStr} onClick={onClick} style={style}>
          {children}
        </span>
      );
  }
};
