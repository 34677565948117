export const formatEnding = (integer: number) => {
  const ds = "" + integer;
  //Вырезаем последнею цифру
  const dd = parseInt(ds.substr(ds.length - 1));
  //Склоняем слово ДЕНЬ
  let dayname;

  if (integer > 4 && integer < 21) dayname = integer + " дней";
  else if (dd == 1) dayname = integer + " день";
  else if (dd == 2 || dd == 3 || dd == 4) dayname = integer + " дня";
  else dayname = integer + " дней";

  return dayname;
};
