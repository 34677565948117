import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work

import { BaseTypography } from 'src/components/base/BaseTypography';
import { ModalScore } from '../ModalScore';
import { BaseButton } from 'src/components/base/BaseButton';
import { CopyIcon } from 'src/components/base/BaseIcons';
import { Tags } from '../Tags';

import imageUserIconFemale from '../../common/images/image-user-icon-female.webp';
import imageUserIconMale from '../../common/images/image-user-icon-male.webp';
import { useModal } from 'src/hooks/useModal';
import toast from 'react-hot-toast/headless';
import {
  useUpdateMatchStatus,
  calculateAge,
  MatchType,
} from 'src/api/hooks/matchesHooks';
import './index.scss';

type PropsType = {
  match: MatchType;
  personId: string | undefined;
};

export default function Meeting({ match, personId }: PropsType) {
  const pairFeedback = match.feedbacks.find(
    (feedback) => feedback.person !== personId,
  );
  const pair = match.pair.find((pair) => pair.people.id !== personId);
  const my = match.pair.find((pair) => pair.people.id === personId);

  if (!pair) {
    return <></>;
  }

  // const name = pair.people.firstName + ' ' + pair.people.lastName;
  const name = pair.people.firstName;
  // const age = calculateAge(new Date(pair.people.birthday));
  const createdAt = moment(new Date(match.createdAt)).locale('ru').format('L');
  const username = pair.people.username.includes('@')
    ? pair.people.username
    : `@${pair.people.username}`;

  const { toggle, isShowing } = useModal();
  const updateMatchStatus = useUpdateMatchStatus();

  const onHandleStatus = async () => {
    if (my?.status && match.id) {
      switch (my.status) {
        case 'MEETING_CREATED':
          toast('Отлично! Желаем продуктивного общения');

          await updateMatchStatus.mutateAsync({
            id: match.id,
            pairId: my.id,
            status: 'MEETING_APPOINTMENT',
          });

          break;
        case 'MEETING_APPOINTMENT':
          toggle();
          break;
      }
    }
  };

  const onHandleCopy = async () => {
    await navigator.clipboard.writeText(username);
    toast('Имя пользователя телеграм скопировано');
  };

  return (
    <>
      <section className="Meeting">
        <div className="Meeting__inner">
          <div className="Meeting__header">
            <div className="Meeting__user">
              <BaseTypography
                className="Meeting__title"
                variant="h3"
                fontSize={22}
                fontWeight={500}
              >
                {name}
              </BaseTypography>
              <div className="Meeting__details">
                {pair.people.gender === 'Мужской' ? (
                  <img
                    className="Meeting__details-item Meeting__image-user"
                    src={imageUserIconMale}
                  />
                ) : (
                  <img
                    className="Meeting__details-item Meeting__image-user"
                    src={imageUserIconFemale}
                  />
                )}
                {/* <BaseTypography
                  className="Meeting__details-item"
                  variant="p"
                  fontSize={18}
                  fontWeight={400}
                >
                  {age} Год
                </BaseTypography> */}
                <BaseTypography
                  className="Meeting__details-item"
                  variant="p"
                  fontSize={18}
                  fontWeight={400}
                >
                  {username}
                </BaseTypography>
                <CopyIcon
                  className="Meeting__details-item Meeting__copy-icon"
                  onClick={onHandleCopy}
                />
              </div>
            </div>
            <div className="Meeting__info">
              <BaseTypography
                className="Meeting__new"
                variant="p"
                fontSize={18}
                fontWeight={900}
              >
                {my?.isNew && 'Новое'}
              </BaseTypography>
              <BaseTypography
                className="Meeting__date"
                variant="p"
                fontSize={18}
                fontWeight={500}
              >
                {createdAt}
              </BaseTypography>
            </div>
          </div>
          <div className="Meeting__body">
            <BaseTypography
              className="Meeting__comment"
              variant="p"
              fontSize={14}
              fontWeight={400}
            >
              {my?.status === 'MEETING_HELD' &&
                pairFeedback?.comment &&
                pairFeedback?.comment.length > 241
                ? pairFeedback?.comment.slice(0, 241) + '...'
                : pairFeedback?.comment}
            </BaseTypography>
            <div className="Meeting__additional">
              {my?.status === 'MEETING_HELD' ? (
                <Tags
                  className="Meeting__tags"
                  tags={pairFeedback?.qualities}
                  onChange={() => { }}
                  blocked={true}
                />
              ) : (
                <BaseButton
                  className="Meeting__button"
                  variant="base-grey-btn"
                  onClick={onHandleStatus}
                >
                  {my?.status && statusText[my?.status]}
                </BaseButton>
              )}
            </div>
          </div>
        </div>
      </section>
      <ModalScore
        isShowing={isShowing}
        toggle={toggle}
        matchId={match.id}
        pairId={pair.people.id}
        myId={my?.id}
      />
    </>
  );
}

const statusText = {
  MEETING_CREATED: 'Договорились о встрече',
  MEETING_APPOINTMENT: 'Встреча прошла',
  MEETING_HELD: 'Оставить отзыв',
};
