import { yupResolver } from '@hookform/resolvers/yup';
import { FC, memo } from 'react';
import { useForm } from 'react-hook-form';

import { BaseInputError } from 'src/components/base/BaseInputError';
import { BaseTextarea } from 'src/components/base/BaseTextarea';
import { BaseButton } from 'src/components/base/BaseButton';
import { BaseLabel } from 'src/components/base/BaseLabel';
import { BaseModal } from 'src/components/base/BaseModal';
import { BaseScore } from 'src/components/base/BaseScore';
import { Tags } from '../Tags';

import { FeedbackValidation } from 'src/common/validation/index';
import {
  useUpdateMatchFeedback,
  useUpdateMatchStatus,
  FeedbackType,
  useQualities,
} from 'src/api/hooks/matchesHooks';
import './index.scss';

type PropsType = {
  isShowing: boolean;
  toggle: () => void;
  matchId: string;
  pairId: string;
  myId: string | undefined;
};

export const ModalScore: FC<PropsType> = memo(
  ({ isShowing, toggle, matchId, pairId, myId }) => {
    const updateMatchFeedback = useUpdateMatchFeedback();
    const updateMatchStatus = useUpdateMatchStatus();
    const qualities = useQualities();
    const form = useForm<FeedbackType>({
      resolver: yupResolver(FeedbackValidation),
    });

    const errors = form.formState.errors;

    const onSubmit = (data: FeedbackType) => {
      if (myId && data.qualities.length > 0) {
        updateMatchStatus.mutate({
          id: matchId,
          pairId: myId,
          status: 'MEETING_HELD',
        });
        updateMatchFeedback.mutate({ matchId, pairId, ...data });

        toggle();
      }
    };

    return (
      <BaseModal
        className="Modal-score"
        isShowing={isShowing}
        toggle={toggle}
        title="Оценить встречу"
      >
        <div className="Modal-score__inner">
          <form
            className="Modal-score__form"
            id="form-score-id"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <ul className="Modal-score__list">
              <li className="Modal-score__item">
                <BaseLabel
                  className="Modal-score__label"
                  label="Поставьте рейтинг собеседнику"
                />
                <BaseScore
                  currentScore={0}
                  maxScore={5}
                  onChange={(value: number) => {
                    form.setValue('score', value);
                  }}
                />
                <BaseInputError<FeedbackType>
                  errors={errors as any}
                  field={'score'}
                />
              </li>
              <li className="Modal-score__item">
                <BaseLabel
                  className="Modal-score__label"
                  label="Выделите качества собеседника"
                />
                <Tags
                  className="Modal-score__tags"
                  tags={qualities.data}
                  onChange={(value: string[]) => {
                    form.setValue('qualities', value);
                    form.trigger('qualities');
                  }}
                />
                <BaseInputError<FeedbackType>
                  errors={errors as any}
                  field={'qualities'}
                />
              </li>
              <li className="Modal-score__item">
                <BaseLabel
                  className="Modal-score__label"
                  label="Оставьте комментарий"
                />
                <BaseTextarea
                  className="Modal-score__comment"
                  placeholder="Комментарий"
                  cols={25}
                  rows={7}
                  {...form.register('comment', {
                    onChange: () => {
                      form.trigger('comment');
                    },
                  })}
                />
                <BaseInputError<FeedbackType>
                  errors={errors as any}
                  field={'comment'}
                />
              </li>
            </ul>
          </form>
          <BaseButton
            className="Modal-score__button"
            variant="base-black-btn"
            type="submit"
            form="form-score-id"
          >
            Отправить
          </BaseButton>
        </div>
      </BaseModal>
    );
  },
);
