import { DeepRequired, FieldError, FieldErrorsImpl } from 'react-hook-form';

import './index.scss';

type PropsType<T> = {
  className?: string;
  errors: {
    [K in keyof T]?: FieldError | undefined;
  } | FieldErrorsImpl<DeepRequired<T>>;
  field: keyof T;
};

export const BaseInputError = <T,>({
  className = '',
  errors,
  field,
}: PropsType<T>) => {
  return errors[field] ? (
    <span className={['input-error', className].join(' ').trim()}>
      {errors[field]?.message}
    </span>
  ) : null;
};
