import React from "react";
import { useNavigate } from "react-router-dom";

import { BaseButton } from "src/components/base/BaseButton";
import { BaseImage } from "src/components/base/BaseImage";
import imageGopnikZen from "../../../common/images/image-gopnik-zen.webp";
import { BaseTypography } from "../../../components/base/BaseTypography";
import { checkScreenWidth } from "../../../common/functions/titleFunctions";
import { useJwtPayload } from "../../../hooks/useJwtPayload";

import "./index.scss";

export const SignupStep4 = () => {
  const navigate = useNavigate();
  const user = useJwtPayload();

  const handleFinalStep = () => {
    if (user && ("personId" in user)) {
      // window.open(LINK_TO_BOT + "?start=" + user?.personId);
      window.open(`tg://resolve?domain=${process.env.REACT_APP_TG_BOT}&start=${user?.personId}`);
      navigate("/account");
    }
  };

  return (
    <section className="signup-step-4">
      <div className="signup-step-4__inner">
        <BaseImage
          className="signup-step-4__image"
          src={imageGopnikZen}
          alt="Дзен"
          width={"336"}
          height={"250"}
        />

        <BaseTypography
          className="signup-step-4__title"
          fontSize={checkScreenWidth(28, 28, 26, 16)}
          fontWeight={600}
          variant="h2"
        >
          Ты достиг!
        </BaseTypography>

        <BaseButton
          className="signup-step-4__button"
          variant="base-button-new-style-black"
          onClick={handleFinalStep}
        >
          Вперед!
        </BaseButton>
        <p
          className="signup-step-4__text"
        >
          Сейчас ты перейдёшь в
          <span className="signup-step-4__text_black">{" Telegram"}</span>
        </p>
        <div className="signup-step-4__subscribe-panel">
          Подпишись:
          <span>
            <a href="https://instagram.com/terkatalk.ru" target="_blank">IG</a>
          </span>
          <span>
            <a href="https://t.me/terkatalk" target="_blank">TG</a>
          </span>
          <span>
            <a href="https://vk.com/club219175543" target="_blank">VK</a>
          </span>
        </div>
      </div>
    </section>
  );
};
