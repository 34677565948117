import React from "react";

import "./BaseToggle.css";

type PropsType = {
  checked: boolean;
  onChange: (checked: boolean) => void;

}

function BaseToggle({ checked, onChange }: PropsType) {
  return (
    <label className="BaseToggle">
      <input
        type="checkbox"
        checked={checked}
        onChange={
          () => {
            onChange(!checked);
          }
        }
      />
      <span className="BaseToggle-slider"></span>
    </label>
  );
}

export default BaseToggle;