import React from "react";
import { CircularProgress } from "@mui/material";
import { useDropArea } from "react-use";
import toast from "react-hot-toast/headless";

import { BaseIcon } from "src/components/base/BaseIcon";

import { useWindowSize } from "src/hooks/useWindowSize";
import styles from "./ImageUploader.module.scss";

type PropsType = {
  onChange: (file: File) => void;
  isLoading?: boolean;
};

const ERROR_FILE_NO_UPLOADED = "Файл не загружен";

const ERROR_FILE_EXT =
  "Неверный формат изображения, должен быть .jpg, .jpeg, .png";

const ERROR_FILE_SIZE = "Размер файла не должен превышать 3MB";

function validateFileExt(file: File) {
  return (
    file && !!["jpg", "jpeg", "png"].find((ext) => file.type.includes(ext))
  );
}

function validateFileSize(file: File) {
  const fileSize = parseFloat((file.size / 1024).toFixed(2));
  const maxSize = 3072;
  return file && fileSize <= maxSize;
}

export default function ImageUploader({ isLoading, onChange }: PropsType) {
  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const [bond] = useDropArea({
    onFiles: (files) => {
      if (isLoading) return;

      if (!files || files?.length === 0) {
        toast.error(ERROR_FILE_NO_UPLOADED);
        return;
      }

      if (!validateFileExt(files[0])) {
        toast.error(ERROR_FILE_EXT);
        return;
      }

      if (!validateFileSize(files[0])) {
        toast.error(ERROR_FILE_SIZE);
        return;
      }

      onChange(files[0]);
    },
  });

  const onChangeHandler = (e: any) => {
    e.preventDefault();
    let files: File[] | undefined;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (!files || files?.length === 0) {
      toast.error(ERROR_FILE_NO_UPLOADED);
      return;
    }

    if (!validateFileSize(files[0])) {
      toast.error(ERROR_FILE_SIZE);
      return;
    }

    if (!validateFileExt(files[0])) {
      toast.error(ERROR_FILE_EXT);
      return;
    }

    onChange(files[0]);
  };

  const windowSize = useWindowSize();

  return (
    <div className={styles.ImageUploader} {...bond}>
      <input
        className={styles.ImageUploader__input}
        disabled={isLoading}
        onChange={onChangeHandler}
        multiple={true}
        hidden
        accept="image/jpg, image/jpeg, image/png"
        type="file"
        ref={inputFileRef}
      />
      {isLoading ? (
        <CircularProgress
          className={styles.ImageUploader__loader}
          color="inherit"
        />
      ) : (
        <div className={styles.ImageUploader__inner}>
          <div className={styles.ImageUploader__content}>
            <div className={styles.ImageUploader__title}>
              {windowSize[0] > 767
                ? "Перетащите или кликните для загрузки файла"
                : "Обзор"}
            </div>
            <BaseIcon
              className={styles.ImageUploader__icon}
              viewBox="0 0 45 45"
              height="45"
              width="45"
              icon="SEEDS_BAG"
            />
          </div>
          <div className={styles.ImageUploader__format}>jpg, jpeg, png</div>
        </div>
      )}
    </div>
  );
}
