import React from "react";
import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

export type StyledTabPropsType = {
  label: string;
};

export const CssTabs = styled((props: any) => <Tabs {...props} />)(
  ({theme}) => ({
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
      borderBottom: '1px solid #E8E8E8',
      // width: "100%",
      gap: "22px",
      [theme.breakpoints.down(768)]: {
        gap: "0",
      },
    },
    "& button:hover": {
      color: "#1d1d1d",
    },
    "& button": {
      transition: "0.25s",
      // maxWidth: "auto",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#000000",
    },
  })
);

export const CssTab = styled((props: StyledTabPropsType) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "inherit",
  transition: "none",
  color: "#000000",
  fontFamily: "unset",
  fontWeight: "400",

  fontSize: `28px`,

  width: '50%',

  [theme.breakpoints.down(768)]: {
    fontSize: `20px`,
  },
  "&.Mui-selected": {
    color: "#000000",
  },
  "&.MuiButtonBase-root": {
    padding: 0,
  },
}));

export function someProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
