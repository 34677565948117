import { useLocation } from "react-router-dom";

export const useQueryIsStore = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromStoreParam = searchParams.get("imFromStore");
  return fromStoreParam === "true"; //возвращаем true если "true" ну и бред, ну а че поделать))
};

export const useQueryIsFillFull = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromStoreParam = searchParams.get("FillFull");
  return fromStoreParam === "true"; //возвращаем true если "true" ну и бред, ну а че поделать))
}