import { ReactNode } from 'react';

import './index.css';

type PropsType = {
  className?: string;
  children: ReactNode;
  columns?: number;
  space?: number;
};

export const Stack = ({
  className = '',
  children,
  space = 10,
  columns = 1,
}: PropsType) => {
  return (
    <div
      className={['Stack', className].join(' ').trim()}
      style={{
        gap: space,
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
      }}
    >
      {children}
    </div>
  );
};
