import React from "react";

import { OptionType } from "src/common/types/option-type";

export function useFiltersBy() {
  // Array with checked ids of categories
  const [checkedIDs, setCheckedIDs] = React.useState<string[]>([]);

  // To handle check category
  const handleCheck = (checkedId: string) => {
    checkedIDs.includes(checkedId)
      ? setCheckedIDs((prev) => prev.filter((id) => id !== checkedId))
      : setCheckedIDs((prev) => [...prev, checkedId]);
  };

  // To reset checked categories
  const handleReset = () => setCheckedIDs(() => []);

  const isChecked = React.useMemo(
    () => (checkedId: string) => {
      return !!checkedIDs.find((id) => id === checkedId);
    },
    [checkedIDs],
  );

  return {
    handleCheck,
    handleReset,
    isChecked,
    checkedIDs,
  };
}
