import { z } from "zod";

const personSocials = z.object({
  telegram: z.string(),
  vk: z.string(),
  whatsapp: z.string(),
  facebook: z.string(),
  instagram: z.string(),
});

const personPolls = z.object({
  communication: z.object({
    proffessional: z.number(),
    romantic: z.number(),
    chat: z.number(),
  }),
  // }).refine((value) => value.proffessional || value.romantic || value.chat),
  purpose: z.object({
    meeting: z.boolean(),
    events: z.boolean(),
    all: z.boolean(),
  }),
  aboutMe: z
    .string()
    .min(5, "надо рассказать подробнее")
    .max(500, "максимум 500 символов"),
  gender: z
    .object({
      male: z.boolean(),
      female: z.boolean(),
    })
    .refine((value) => value.male || value.female),
  age: z.object({
    from: z.number().optional(),
    till: z.number().optional(),
  }),
});

export const FormAccountSchema = z.object({
  // gender: z.string(),
  // birthday: z.string(),
  personPolls: personPolls,
  personSocials: personSocials,
});
