import { request } from "./request";

export type SignupType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  wasAcceptedPolicy: boolean;
  isNotifyByEvents?: boolean;
  birthday?: string;
  gender?: string;
  poll?: {
    purpose: {
      meeting: boolean;
      events: boolean;
      all: boolean;
    };
    communication: {
      proffessional: number;
      romantic: number;
      chat: number;
    };
    gender: {
      male: boolean;
      female: boolean;
    };
    age: {
      from: number;
      till: number;
    };
    aboutMe: string;
  };
  socials?: {
    telegram: string;
    vk: string;
    whatsapp: string;
    facebook: string;
    instagram: string;
  };
  isStore: boolean;
};

export type SigninType = {
  email: string;
  password: string;
};

export type CredentialsResponseType = {
  accessToken: string;
};

export const authProvider = {
  signup: async (payload: SignupType) => {
    const { data } = await request<CredentialsResponseType>({
      url: "/auth/signup",
      method: "POST",
      data: payload,
    });

    return data;
  },
  signin: async (payload: SigninType) => {
    const { data } = await request<CredentialsResponseType>({
      url: "/auth/signin",
      method: "POST",
      data: payload,
    });

    return data;
  },
  signout: async () => {
    const { data } = await request<void>({
      url: "/auth/signout",
      method: "POST",
    });

    return data;
  },
  refresh: async () => {
    const { data } = await request<CredentialsResponseType>({
      url: "/auth/refresh",
      method: "POST",
    });

    return data;
  },
};
