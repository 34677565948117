import React from "react";
import DatePicker from "react-date-picker";
import momentTz from "moment-timezone";
import moment from "moment";
import clsx from "clsx";

import { BaseIcon } from "../BaseIcon";

import { startOfDay } from "date-fns";

import './DatePicker.scss';
import './Calendar.scss';

type PropsType = {
  className?: string;
  variant?: "small" | "full";
  onChange: (value: any) => void;
  minDate?: Date;
  maxDate?: Date;
  value?: Date | [Date | null, Date | null] | null | undefined | string;
  disabled?: boolean;
  error?: boolean;
};

const BaseDatePicker = ({
  className = '',
  variant = "small",
  onChange,
  minDate,
  maxDate,
  value,
  error,
  ...rest
}: PropsType) => {
  const onCalendarOpen = () => {
    if (variant !== "small") return;

    const navLabel = document.querySelector(
      ".react-calendar__navigation__label",
    );
    const navBlock = document.createElement("div");
    const inputs = document.querySelectorAll(
      ".react-date-picker__inputGroup__input",
    );

    if (navLabel) {
      navBlock.className = "react-calendar-block-label";
      navLabel.after(navBlock);
    }

    if (inputs) {
      (inputs[0] as any).disabled = true;
      (inputs[1] as any).disabled = true;
      (inputs[2] as any).disabled = true;
    }

    return () => navBlock.remove();
  };

  // Current date settings
  const dayMilliseconds = 24 * 60 * 60 * 1000;
  const currentDate = momentTz.tz("Europe/Moscow").toDate();
  const nextDay = moment(
    currentDate.setTime(currentDate.getTime() + dayMilliseconds),
  ).toDate();

  // Date value transform
  const dateValue =
    typeof value === "string"
      ? moment(value).tz("Europe/Moscow").toDate()
      : value;

  return (
    <>
      <DatePicker
        className={clsx({
          "react-date-picker_required": error,
          [className]: !!className,
        })}
        locale="ru"
        format={"dd.MM.yyyy"}
        onCalendarOpen={onCalendarOpen}
        // maxDetail="year"
        // calendarAriaLabel="Toggle calendar"
        // clearAriaLabel="Clear value"
        // calendarIcon={<CalendarIcon />}
        clearIcon={null}
        calendarIcon={
          <BaseIcon
            viewBox="0 0 20 20"
            height="20"
            width="20"
            icon="CALENDAR"
            fill="none"
          />
        }
        nextLabel={<NextIcon/>}
        next2Label={<NextIcon2/>}
        prevLabel={<PrevIcon/>}
        prev2Label={<PrevIcon2/>}
        // monthAriaLabel="Month"
        minDate={minDate ? minDate : nextDay}
        value={dateValue}
        defaultActiveStartDate={maxDate}
        onChange={(value: any) => {
          onChange(startOfDay(value).toString());
        }}
        maxDate={maxDate}
        {...rest}
      />
    </>
  );
};

const MemoizedBaseDatePicker = React.memo(BaseDatePicker);

export default MemoizedBaseDatePicker;


const NextIcon = () => {
  return (
    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_974_14377" fill="white">
        <path d="M2.00011 0L7.00011 5L2.00011 10L0.939453 8.93934L4.87879 5L0.939453 1.06066L2.00011 0Z"/>
      </mask>
      <path d="M2.00011 0L7.00011 5L2.00011 10L0.939453 8.93934L4.87879 5L0.939453 1.06066L2.00011 0Z" fill="white"/>
      <path
        d="M7.00011 5L8.06077 6.06066L9.12143 5L8.06077 3.93934L7.00011 5ZM2.00011 0L3.06077 -1.06066L2.00011 -2.12132L0.939453 -1.06066L2.00011 0ZM2.00011 10L0.939453 11.0607L2.00011 12.1213L3.06077 11.0607L2.00011 10ZM0.939453 1.06066L-0.121207 -1.67164e-07L-1.18187 1.06066L-0.121207 2.12132L0.939453 1.06066ZM0.939453 8.93934L-0.121207 7.87868L-1.18187 8.93934L-0.121207 10L0.939453 8.93934ZM4.87879 5L5.93945 6.06066L7.00011 5L5.93945 3.93934L4.87879 5ZM8.06077 3.93934L3.06077 -1.06066L0.939453 1.06066L5.93945 6.06066L8.06077 3.93934ZM3.06077 11.0607L8.06077 6.06066L5.93945 3.93934L0.939453 8.93934L3.06077 11.0607ZM0.939453 -1.06066L-0.121207 -1.67164e-07L2.00011 2.12132L3.06077 1.06066L0.939453 -1.06066ZM-0.121207 10L0.939453 11.0607L3.06077 8.93934L2.00011 7.87868L-0.121207 10ZM3.81813 3.93934L-0.121207 7.87868L2.00011 10L5.93945 6.06066L3.81813 3.93934ZM-0.121207 2.12132L3.81813 6.06066L5.93945 3.93934L2.00011 -1.58579e-08L-0.121207 2.12132Z"
        fill="black" fillOpacity="0.25" mask="url(#path-1-inside-1_974_14377)"/>
    </svg>

  );
};

const NextIcon2 = () => {
  return (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_517_3" fill="white">
        <path d="M8.00011 0L13.0001 5L8.00011 10L6.93945 8.93934L10.8788 5L6.93945 1.06066L8.00011 0Z"/>
      </mask>
      <path d="M8.00011 0L13.0001 5L8.00011 10L6.93945 8.93934L10.8788 5L6.93945 1.06066L8.00011 0Z" fill="white"/>
      <path
        d="M13.0001 5L14.0608 6.06066L15.1214 5L14.0608 3.93934L13.0001 5ZM8.00011 0L9.06077 -1.06066L8.00011 -2.12132L6.93945 -1.06066L8.00011 0ZM8.00011 10L6.93945 11.0607L8.00011 12.1213L9.06077 11.0607L8.00011 10ZM6.93945 1.06066L5.87879 -1.67164e-07L4.81813 1.06066L5.87879 2.12132L6.93945 1.06066ZM6.93945 8.93934L5.87879 7.87868L4.81813 8.93934L5.87879 10L6.93945 8.93934ZM10.8788 5L11.9395 6.06066L13.0001 5L11.9395 3.93934L10.8788 5ZM14.0608 3.93934L9.06077 -1.06066L6.93945 1.06066L11.9395 6.06066L14.0608 3.93934ZM9.06077 11.0607L14.0608 6.06066L11.9395 3.93934L6.93945 8.93934L9.06077 11.0607ZM6.93945 -1.06066L5.87879 -1.67164e-07L8.00011 2.12132L9.06077 1.06066L6.93945 -1.06066ZM5.87879 10L6.93945 11.0607L9.06077 8.93934L8.00011 7.87868L5.87879 10ZM9.81813 3.93934L5.87879 7.87868L8.00011 10L11.9395 6.06066L9.81813 3.93934ZM5.87879 2.12132L9.81813 6.06066L11.9395 3.93934L8.00011 -1.58579e-08L5.87879 2.12132Z"
        fill="black" fillOpacity="0.25" mask="url(#path-1-inside-1_517_3)"/>
      <mask id="path-3-inside-2_517_3" fill="white">
        <path d="M1.93957 0L6.93957 5L1.93957 10L0.878906 8.93934L4.81825 5L0.878906 1.06066L1.93957 0Z"/>
      </mask>
      <path d="M1.93957 0L6.93957 5L1.93957 10L0.878906 8.93934L4.81825 5L0.878906 1.06066L1.93957 0Z" fill="white"/>
      <path
        d="M6.93957 5L8.00023 6.06066L9.06089 5L8.00023 3.93934L6.93957 5ZM1.93957 0L3.00023 -1.06066L1.93957 -2.12132L0.878907 -1.06066L1.93957 0ZM1.93957 10L0.878906 11.0607L1.93957 12.1213L3.00023 11.0607L1.93957 10ZM0.878906 1.06066L-0.181754 -1.67164e-07L-1.24241 1.06066L-0.181754 2.12132L0.878906 1.06066ZM0.878906 8.93934L-0.181754 7.87868L-1.24241 8.93934L-0.181754 10L0.878906 8.93934ZM4.81825 5L5.87891 6.06066L6.93957 5L5.87891 3.93934L4.81825 5ZM8.00023 3.93934L3.00023 -1.06066L0.878906 1.06066L5.87891 6.06066L8.00023 3.93934ZM3.00023 11.0607L8.00023 6.06066L5.87891 3.93934L0.878906 8.93934L3.00023 11.0607ZM0.878907 -1.06066L-0.181754 -1.67164e-07L1.93957 2.12132L3.00023 1.06066L0.878907 -1.06066ZM-0.181754 10L0.878906 11.0607L3.00023 8.93934L1.93957 7.87868L-0.181754 10ZM3.75759 3.93934L-0.181754 7.87868L1.93957 10L5.87891 6.06066L3.75759 3.93934ZM-0.181754 2.12132L3.75759 6.06066L5.87891 3.93934L1.93957 -1.58579e-08L-0.181754 2.12132Z"
        fill="black" fillOpacity="0.25" mask="url(#path-3-inside-2_517_3)"/>
    </svg>
  );
};

const PrevIcon = () => {
  return (
    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M6.06066 1.06066L5 0L0 5L5 10L6.06066 8.93934L2.12132 5L6.06066 1.06066Z" fill="black"
            fillOpacity="0.25"/>
    </svg>
  )
}


const PrevIcon2 = () => {
  return (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M6.06066 1.06066L5 0L0 5L5 10L6.06066 8.93934L2.12132 5L6.06066 1.06066Z" fill="black"
            fillOpacity="0.25"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12.0607 1.06066L11 0L6 5L11 10L12.0607 8.93934L8.12132 5L12.0607 1.06066Z" fill="black"
            fillOpacity="0.25"/>
    </svg>
  )
}