import React, { InputHTMLAttributes } from "react";
import clsx from "clsx";
import "./BaseInputSocials.scss";
import { BaseIcon } from "../BaseIcon";

type PropsType = InputHTMLAttributes<HTMLInputElement> & {
  variant: "TG" | "VK" | "WA" | "FB" | "IG";
  error?: boolean;
  className?: string;
}

function BaseInputSocials({ className = "", variant, error, ...rest }: PropsType) {

  return (
    <label className={clsx({
      "BaseInputSocials": true,
      "BaseInputSocials_required": error,
      [className]: !!className
    })}>
      <div className="BaseInputSocials__icon-box">
        <BaseIcon icon={`SOCIALS_${variant}`}
                  viewBox={"0 0 20 21"}
                  className="BaseInputSocials__icon"
        />
      </div>
      <input className="BaseInputSocials__input" type="text"
             {...rest}
      />
    </label>
  );
}

export default BaseInputSocials;