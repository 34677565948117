import { Tag } from "src/components/base/Tag";

import { EventStatusEnum } from "src/common/enums";
import styles from "./EventStatus.module.scss";

type EventStatusType = {
  className?: string;
  isOwner: boolean;
  status: string;
};

export default function EventStatus({
  className = "",
  isOwner,
  status,
}: EventStatusType) {
  const colors = {
    [EventStatusEnum["Опубликовано"]]: styles.EventStatus_blue,
    [EventStatusEnum["Завершено"]]: styles.EventStatus_red,
    [EventStatusEnum["Архив"]]: styles.EventStatus_red,
  };

  const foundClassName = Object.entries(colors).find(([key, value]) =>
    key === status ? value : "",
  );
  const bgColorClassName = foundClassName ? foundClassName[1] : "";

  return isOwner ? (
    <Tag
      className={[styles.EventStatus, bgColorClassName, className]
        .join(" ")
        .trim()}
      variant="event_preview"
    >
      {status.toLocaleLowerCase() ===
      EventStatusEnum["Архив"].toLocaleLowerCase()
        ? "Скрыто"
        : status}
    </Tag>
  ) : null;
}
