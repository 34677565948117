import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { BaseLoader } from "src/components/base/BaseLoader";
import { useCheckUser } from "src/hooks/useCheckUser";

export const RoutePrivate = () => {
  const { logoutUser, isLoading, isSuccess, location } = useCheckUser();

  React.useEffect(() => {
    logoutUser();
  }, [location]);

  React.useEffect(() => {
    window.addEventListener("focus", logoutUser);
    return () => {
      window.removeEventListener("focus", logoutUser);
    };
  }, []);

  if (isLoading) {
    return <BaseLoader className="base-loader_center" />;
  } else if (isSuccess) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to="/signin"
        replace
        state={{
          path: location.pathname,
        }}
      />
    );
  }
};
