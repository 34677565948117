import React from "react";

import styles from "./Stepper.module.scss";

type PropsType = {
  currentStep: number;
  maxSteps: number;
}

function Stepper({currentStep, maxSteps}:PropsType) {
  return (
    <div className={styles.Stepper}>
      <span>
        Шаг {currentStep} из {maxSteps}
      </span>
      <div className={styles.Stepper__field}>
        <div style={{ width: `${100 / maxSteps * currentStep}%` }} className={styles.Stepper__fill}></div>
      </div>
    </div>
  );
}

export default Stepper;