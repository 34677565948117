import React, { Dispatch, SetStateAction } from "react";
import { BaseToggle } from "../../components/base";

import styles from "./SubscribeMeets.module.scss";
import { SurveyTitle } from "../index";
import clsx from "clsx";

type PropsType = {
  title?: string;
  titleStyle?: string;
  className?: string;
  checked: boolean | undefined;
  onChange: Dispatch<SetStateAction<boolean>>;
}

function SubscribeMeets({ checked = false, className = '', title = 'Получать уведомления по мероприятиям', titleStyle, onChange }: PropsType) {
  return (
    <div
      // className={styles.SubscribeMeets}
      className={clsx({
        [styles["SubscribeMeets"]]: true,
        [className]: !!className
      })}
    >
        <SurveyTitle className={titleStyle}>
          {title}
        </SurveyTitle>
        <div className={styles.SubscribeMeets__box}>
          <BaseToggle checked={checked} onChange={onChange} />
          <h3 className={styles.SubscribeMeets__sign}>{checked ? "Да" : "Нет"}</h3>
        </div>
    </div>
  );
}

export default SubscribeMeets;