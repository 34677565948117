import React, { useEffect, useState } from "react";

import { BaseTypography } from "../../../components/base/BaseTypography";
import { BaseInput } from "../../../components/base/BaseInput";
import { Stack } from "../../../components/base/Stack";
import { OrderItem } from "../../../content";

import styles from "./AccountOrders.module.scss";
import { BaseButton } from "../../../components/base/BaseButton";
import { LINK_TO_AUTH_SHOP } from "../../../common/consts/shop";
import { OrderType, useOrders } from "../../../api/hooks/ordersHooks";
import { BaseLoader } from "../../../components/base/BaseLoader";

// type PropsType = {
//   orders: OrderType[];
// }

const AccountOrders = () => {
  const [limit, setLimit] = useState(3);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("loadingState", loading);
  }, [loading]);

  const { data } = useOrders({ page: 1, limit: limit, order: "DESC" });

  const [state, setState] = useState<OrderType[]>();

  useEffect(() => {
    if (!data) return;
    console.log("заказ данных");
    console.log(data.item);
    setState(data.item);
    setLoading(false);
  }, [data]);

  return (
    <div className={styles.AccountOrders} id="orderTab">
      <Stack space={16}>
        <BaseTypography variant={"p"} format={"profile-sub-title"}>
          Адрес доставки
        </BaseTypography>
        <BaseInput
          className={styles.Poll__input}
          placeholder={"Индекс, город, улица, дом/строение, квартира\n"}
        />
      </Stack>

      <Stack space={16}>
        {state && state.length > 0 ? (
          <div className={styles.AccountOrders__full}>
            {state?.map((item) => (
              <OrderItem key={item.id} {...item} />
            ))}
            {state.length < limit ? (
              loading ? (
                <BaseLoader />
              ) : (
                <a href="#orderTab">
                  <BaseButton
                    variant={"base-button-new-style-black"}
                    onClick={() => {
                      setLimit(3);
                    }}
                  >
                    Скрыть заказы
                  </BaseButton>
                </a>
              )
            ) : (
              <BaseButton
                variant={"base-button-new-style-black"}
                onClick={() => {
                  setLimit(limit + 3);
                  setLoading(true);
                }}
              >
                Показать ещё
              </BaseButton>
            )}
          </div>
        ) : (
          <div className={styles.AccountOrders__empty}>
            У вас ещё не было заказов
            <a href={LINK_TO_AUTH_SHOP}>
              <BaseButton variant={"base-button-new-style-black"}>
                Тёрка стор
              </BaseButton>
            </a>
            {/*<BaseButton*/}
            {/*  variant={"base-button-new-style-black"}*/}
            {/*  onClick={() => {*/}
            {/*    setState(data)*/}
            {/*  }}*/}
            {/*>*/}
            {/*  кнопка переключает стейт*/}
            {/*</BaseButton>*/}
          </div>
        )}
      </Stack>
    </div>
  );
};

export default AccountOrders;
