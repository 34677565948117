import React from "react";

import styles from "./BaseContainer.module.scss";
import clsx from "clsx";

type PropsType = {
  children: React.ReactNode;
  className?: string;
};

const BaseContainer = ({ children, className = "" }: PropsType) => {
  return (
    <div
      className={clsx({
        [styles["BaseContainer"]]: true,
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

export default BaseContainer;
