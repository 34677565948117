import { Card } from "../CardHome/Card";

import { useAuth } from "src/hooks/useAuth";
import card1 from "../../common/images/image-sidim.svg";
import card2 from "../../common/images/image-lejim.svg";
import styles from "../../layouts/CardsHome/CardsHome.module.scss";

export const Cards = () => {
  const { authed } = useAuth();
  return (
    <div className="cardsHome">
      <div className={styles.cardsHome__wrapper}>
        <Card
          title={"Встречи 1 на 1"}
          description={
            "В нашем городе очень много интересных и талантливых людей, наша задача — познакомить тебя с каждым из них."
          }
          image={card1}
          link={authed ? "/account" : "/signin"}
        />
        <Card
          title={"Мероприятия города"}
          description={
            "Участвуй в открытых и эксклюзивных мероприятиях Калининграда."
          }
          image={card2}
          link="https://t.me/terkatalk"
        />
      </div>
    </div>
  );
};
