import { FC, useState } from 'react';

import { BaseTag } from 'src/components/base/BaseTag';

import './index.scss';

type PropsType = {
  className?: string;
  tags: Array<{ id: string; name: string }> | undefined;
  onChange: (value: string[]) => void;
  blocked?: boolean;
};

export const Tags: FC<PropsType> = ({
  className = '',
  tags = [],
  onChange,
  blocked = false,
}) => {
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);

  const onSelectHandler = (id: string) => () => {
    if (blocked) return;

    const copy = [...selectedIDs];

    if (copy.includes(id)) {
      copy.find((selectedID, i) =>
        selectedID === id ? copy.splice(i, 1) : null,
      );
    } else {
      copy.push(id);
    }

    setSelectedIDs(copy);
    onChange(copy);
  };

  const isEqualID = (selectedIDs: string[], selectedID: string) => {
    return selectedIDs.find((id) => selectedID === id);
  };

  return (
    <div className={['Tags', className].join(' ').trim()}>
      {tags &&
        tags?.map(({ id, name }) => (
          <BaseTag
            className={isEqualID(selectedIDs, id) ? 'base-tag_active ' : ''}
            name={name}
            key={id}
            id={id}
            onClick={onSelectHandler(id)}
          />
        ))}
    </div>
  );
};
