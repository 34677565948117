import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

import Tabs from "./Tabs/Tabs";
import Tab from "./Tab/Tab";

import styles from './AccountTabs.module.scss';

type PropsType = {
  className?: string;
  children: React.ReactNode;
};

const AccountTabs = ({className, children}: PropsType) => {
  const location = useLocation();
  const getTabValue = () => {
    const searchParams = new URLSearchParams(location.search);
    const myQueryParam = searchParams.get('tab');
    return myQueryParam === "orders" ? 1 : 0;
  };

  const [value, setValue] = React.useState(getTabValue);

  useEffect(() => {
    setValue(getTabValue);
  }, [location.search]);

  const navigate = useNavigate();
  const handleTabsChange = (newValue: number) => {
    setValue(newValue);
    const tabName = newValue === 1 ? "orders" : "networking";
    navigate(`/account?tab=${tabName}`);
  };

  return (
    <div className={clsx([styles.AccountTabs, className])}>
      <Tabs onChange={handleTabsChange} items={items} value={value}/>
      <div className={styles.AccountTabs__tabs}>
        {React.Children.map(children, (child, index) => (
          <Tab index={index} value={value} key={index}>
            {child}
          </Tab>
        ))}
      </div>
    </div>
  );
};

export default AccountTabs;

const items = [
  {label: "Встречи"},
  {label: "Покупки"},
];
