import { FC } from 'react';
import './index.scss';

type PropsType = {
  className?: string | 'base-loader_center';
};

export const BaseLoader: FC<PropsType> = ({ className = '' }) => {
  return (
    <ul className={['base-loader', className].join(' ').trim()}>
      <li className="base-loader__item"></li>
      <li className="base-loader__item"></li>
      <li className="base-loader__item"></li>
    </ul>
  );
};
