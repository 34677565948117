import { GoBack, ProfileTitle } from "src/content";

import styles from "./LayoutAccount.module.scss";

type PropsType = {
  children: React.ReactNode;
  title: string;
  isArrowGoBack?: boolean;
  to?: string;
  onClick?: () => void;
};

const LayoutAccount = ({
  children,
  title,
  isArrowGoBack,
  to,
  onClick,
}: PropsType) => {
  return (
    <section className={styles.LayoutAccount}>
      <div className={styles.LayoutAccount__header}>
        {isArrowGoBack && <GoBack to={to} onClick={onClick} />}
        <ProfileTitle title={title} />
      </div>
      {children}
    </section>
  );
};

export default LayoutAccount;
