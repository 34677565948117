import React from 'react';

import arrowContinue from '../../../common/images/image-arrow-continue.webp';
import WhiteArrow from '../../../common/images/image-white-arrow-back.webp';
import arrowBack from '../../../common/images/image-arrow-back.webp';
import arrow from '../../../common/images/image-arrow.webp';
import './index.scss';
import { BaseIcon } from "../BaseIcon";

type PropsType = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?:
  | 'base-black-btn'
  | 'base-white-btn'
  | 'base-grey-btn'
  | 'base-empty-btn'
  | 'base-button-continue'
  | 'base-button-go-back'
  | 'base-button-with-arrow'
  | 'base-button-with-white-arrow'
  | 'base-white-btn-landing'
  | 'base-black-btn-landing'
  | 'base-button-new-style-black'
  | 'base-button-new-style-black-with-white-arrow'
  | 'base-text-btn';
};

export const BaseButton = React.forwardRef<any, any>(({ className = '', children, variant = 'base-black-btn', ...props }: PropsType, ref: React.ForwardedRef<any>) => {
  const baseClassDisabled = props.disabled ? `base-button-disabled` : ``;

  switch (variant) {
    case 'base-button-continue':
      return (
        <button
          className={[variant, className, baseClassDisabled].join(' ').trim()}
          ref={ref}
          {...props}
        >
          {children}
          <img
            className="base-button-continue__arrow"
            src={arrowContinue}
            alt="Кнопка вперёд"
          />
        </button>
      );
    case 'base-button-go-back':
      return (
        <button className={['base-button-go-back', className].join(' ').trim()}
                ref={ref} {...props}>
          <BaseIcon icon={"ARROW_HORIZONTAL"}
                    viewBox={"0 0 41 16"}
                    className="base-button-new-style-black-with-white-arrow__img"
          />
        </button>
      );
    case 'base-button-with-white-arrow':
      return (
        <img src={WhiteArrow} alt="" className="base-button-with-white-arrow" ref={ref} />
      );
    case 'base-button-with-arrow':
      return (
        <button
          className={['base-button-with-arrow', className, baseClassDisabled]
            .join(' ')
            .trim()}
          ref={ref}
          {...props}
        >
          <div className="base-button-with-arrow__area">
            <img
              className="base-button-with-arrow__img"
              alt="Стрелка"
              src={arrow}
            />
          </div>
        </button>
      );
    case 'base-button-new-style-black-with-white-arrow':
      return (
        <button
          className={['base-button-new-style-black-with-white-arrow', className, baseClassDisabled]
            .join(' ')
            .trim()}
          ref={ref}
          {...props}
        >
          {children}
          <div className="base-button-new-style-black-with-white-arrow__area">
            <BaseIcon icon={"ARROW_HORIZONTAL"}
                      viewBox={"0 0 41 16"}
                      className="base-button-new-style-black-with-white-arrow__img"
            />
          </div>
        </button>
      );
    case 'base-text-btn':
      return (
        <button className={['base-text-btn', className, baseClassDisabled].join(" ").trim()} ref={ref} {...props}>
          {children}
        </button>
      );
    default:
      return (
        <button
          className={[variant, className, baseClassDisabled].join(' ').trim()}
          ref={ref}
          {...props}
        >
          {children}
        </button>
      );
  }
});
