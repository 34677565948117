import { IconEnum, icons } from "src/common/consts/icons";

type PropsType = React.SVGAttributes<HTMLOrSVGElement> & {
  className?: string;
  viewBox?: string;
  fill?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  icon: keyof typeof IconEnum;
  height?: string;
  width?: string;
};

export default function BaseIcon({
  className = "",
  viewBox = "0 0 17 16",
  fill = "none",
  icon,
  ...rest
}: PropsType) {
  return (
    <svg
      className={className}
      viewBox={viewBox}
      height="20"
      width="20"
      {...rest}
      fill={fill}
    >
      {icons[icon]}
    </svg>
  );
}
