import React from "react";
import { DeepRequired, FieldErrorsImpl } from "react-hook-form";

import { BaseDatePicker } from "src/components/base";
import { BaseInputError } from "src/components/base/BaseInputError";
import { BaseTypography } from "src/components/base/BaseTypography";
import { ImageUploader } from "../ImageUploader";
import { BaseTextarea } from "src/components/base/BaseTextarea";
import { ImagePreview } from "../ImagePreview";
import { SelectTags } from "../SelectTags";
import { BaseSwitch } from "src/components/base/BaseSwitch";
import { BaseInput } from "src/components/base/BaseInput";
import { BaseLabel } from "src/components/base/BaseLabel";
import SelectContained from "../SelectContained/SelectContained";

import { EventType, useEventTypes } from "src/api/hooks/eventsHooks";
import { OptionType } from "src/common/types/option-type";
import styles from "./EventForm.module.scss";
import clsx from "clsx";

type PropsType = {
  className?: string;
  onHandleState: (
    key: keyof EventType,
    value: string | number | OptionType | OptionType[] | boolean,
  ) => void;
  onUploadCover: (file: File | string) => void;
  onDeleteCover: () => void;
  onEditCover?: () => void;
  croppedCover: File | string | undefined;
  errors: FieldErrorsImpl<DeepRequired<EventType>>;
  state: EventType;
  disabled?: boolean;
};

const EventForm = ({
  className = "",
  onHandleState,
  onUploadCover,
  onDeleteCover,
  onEditCover,
  croppedCover,
  errors,
  state,
  disabled,
}: PropsType) => {
  const { data: eventTypes } = useEventTypes();
  const timeOptions = useMakeTimeOptions();
  const foundTime = React.useMemo(() => {
    return timeOptions.find(({ name }) => state.time === name);
  }, [state.time]);

  const handleKeyPress =
    (maxLenght: number) => (e: React.ChangeEvent<HTMLInputElement> | any) => {
      if (!/[0-9]/.test(e.key) || e.currentTarget.value.length >= maxLenght) {
        e.preventDefault();
      }
    };

  return (
    <>
      <ul className={[styles.EventForm, className].join(" ").trim()}>
        <li className={styles.EventForm__item}>
          <BaseLabel className={styles.EventForm__label} label="Организатор">
            <BaseInput
              placeholder="Название"
              onChange={(e) => onHandleState("organizer", e.target.value)}
              value={state.organizer}
              maxLength={80}
              disabled={disabled}
            />
            <InputCounter
              className={styles.EventForm__counter}
              from={state?.organizer?.length}
              till={80}
            />
          </BaseLabel>
          <BaseInputError field="organizer" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel
            className={styles.EventForm__label}
            label="Название мероприятия"
          >
            <BaseInput
              placeholder="Название"
              onChange={(e) => onHandleState("title", e.target.value)}
              value={state.title}
              disabled={disabled}
              maxLength={80}
            />
            <InputCounter
              className={styles.EventForm__counter}
              from={state?.title?.length}
              till={80}
            />
          </BaseLabel>
          <BaseInputError field="title" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel className={styles.EventForm__label} label="Описание">
            <BaseTextarea
              className={styles.EventForm__textarea}
              placeholder="Опишите цели встречи"
              onChange={(e) => onHandleState("text", e.target.value)}
              value={state.text}
              disabled={disabled}
              maxLength={1000}
            />
            <InputCounter
              className={styles.EventForm__counter}
              from={state?.text?.length}
              till={1000}
            />
          </BaseLabel>
          <BaseInputError field="text" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Обложка мероприятия">
            {croppedCover ? (
              <ImagePreview
                onDelete={() => onDeleteCover()}
                disabled={disabled}
                onEdit={() => onEditCover && onEditCover()}
                src={croppedCover}
              />
            ) : (
              <ImageUploader onChange={(file) => onUploadCover(file)} />
            )}
          </BaseLabel>
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Тематика и теги поиска">
            <SelectTags
              placeholder="Выберите"
              onChange={(options) => onHandleState("eventType", options)}
              options={eventTypes as OptionType[]}
              value={state.eventType as unknown as OptionType[]}
              maxLenght={3}
              disabled={disabled}
            />
          </BaseLabel>
          <BaseInputError field="eventType" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <div>
            <BaseLabel label="Дата проведения">
              <BaseDatePicker
                onChange={(value: string | number | OptionType) => {
                  if (typeof value === "string") {
                    onHandleState("meetingAt", value);
                  }
                }}
                value={state.meetingAt}
                disabled={disabled}
              />
            </BaseLabel>
            <BaseInputError field="meetingAt" errors={errors} />
          </div>
          <div>
            <BaseLabel label="Время проведения">
              <SelectContained
                placeholder="Выберите"
                multiple={false}
                onChange={({ name }) => onHandleState("time", name)}
                value={foundTime}
                options={timeOptions}
              />
            </BaseLabel>
            <BaseInputError field="meetingAt" errors={errors} />
          </div>
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Место проведения">
            <BaseInput
              placeholder="Введите адрес"
              onChange={(e) => onHandleState("address", e.target.value)}
              value={state.address}
              disabled={disabled}
            />
          </BaseLabel>
          <BaseInputError field="address" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Количество человек">
            <BaseInput
              placeholder="Количество"
              onChange={(e) =>
                onHandleState("maxParticipants", +e.target.value)
              }
              onKeyPress={handleKeyPress(4)}
              value={state.maxParticipants}
              disabled={disabled}
            />
          </BaseLabel>
          <BaseInputError field="maxParticipants" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Ссылка на чат в Telegram">
            <BaseInput
              placeholder="Введите ссылку"
              onChange={(e) => onHandleState("telegramLink", e.target.value)}
              value={state.telegramLink}
              disabled={disabled}
            />
          </BaseLabel>
          <BaseInputError field="telegramLink" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel
            className={styles.EventForm__label_cost}
            label="Стоимость мероприятия, руб."
          >
            <BaseSwitch
              className={styles.EventForm__switch}
              checked={state.isFree}
              onChange={(e) => onHandleState("isFree", e.target.checked)}
              label="Бесплатное"
            />
            <div className={styles.EventForm__input_cost_wrapper}>
              <BaseInput
                className={clsx({
                  [styles.EventForm__input_cost]: true,
                  [styles.EventForm__input_hide]: state.isFree,
                })}
                onChange={(e) => onHandleState("cost", +e.target.value)}
                onKeyPress={handleKeyPress(6)}
                placeholder="Введите стоимость"
                value={state.cost}
              />
              <BaseInputError field="cost" errors={errors} />
            </div>
          </BaseLabel>
        </li>
        <li
          className={clsx({
            [styles.EventForm__item]: true,
            [styles.EventForm__input_hide]: state.isFree,
          })}
        >
          <BaseLabel label="Ссылка на билет">
            <BaseInput
              placeholder="Введите ссылку"
              onChange={(e) => onHandleState("ticketUrl", e.target.value)}
              value={state.ticketUrl}
              disabled={disabled}
              maxLength={100}
            />
          </BaseLabel>
          <BaseInputError field="ticketUrl" errors={errors} />
        </li>
        <li
          className={clsx({
            [styles.EventForm__item]: true,
            [styles.EventForm__input_hide]: state.isFree,
          })}
        >
          <BaseLabel label="Артикул билета">
            <BaseInput
              placeholder="Введите артикул"
              onChange={(e) => onHandleState("vendorCode", e.target.value)}
              value={state.vendorCode}
              disabled={disabled}
              maxLength={100}
            />
          </BaseLabel>
          <BaseInputError field="vendorCode" errors={errors} />
        </li>
      </ul>
    </>
  );
};

const useMakeTimeOptions = () => {
  const result = React.useMemo(() => {
    const result = [];
    const minute = {
      start: 0,
      step: 30,
      end: 50,
    };
    const hour = {
      start: 0,
      step: 1,
      end: 23,
    };
    for (let i = hour.start; i <= hour.end; i += hour.step) {
      for (let j = minute.start; j <= minute.end; j += minute.step) {
        result.push({
          id: Math.random().toString(16).slice(2, 6),
          name: (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j),
        });
      }
    }

    return result;
  }, []);

  return result;
};

type InputCounterPropsType = {
  className?: string;
  from?: number;
  till?: number;
};

const InputCounter = React.memo(
  ({ className = "", from = 0, till = 0 }: InputCounterPropsType) => {
    return (
      <>
        <BaseTypography
          className={clsx([styles.InputCounter, className])}
          variant="p"
        >
          <span
            className={clsx({
              [styles.InputCounter__from]: true,
              [styles.InputCounter__from_active]: from > 0,
            })}
            style={from > till ? { color: "rgba(233, 0, 0, 0.5)" } : {}}
          >
            {from}
          </span>
          <span className={styles.InputCounter__divider}>{"/"}</span>
          <span
            className={clsx({
              [styles.InputCounter__till]: true,
              [styles.InputCounter__till_active]: from > 0,
            })}
          >
            {till}
          </span>
        </BaseTypography>
      </>
    );
  },
);

const MemoizedEventForm = React.memo(EventForm);

export default MemoizedEventForm;
