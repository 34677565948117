import { CssTab, CssTabs, someProps } from "./Tabs.config";

type PropsType = {
  onChange: (value: number) => void;
  items: Array<{ label: string }>;
  value: number;
};

export default function Tabs({ onChange, items, value }: PropsType) {
  return (
    <CssTabs
      onChange={(_: any, value: number) => onChange(value)}
      value={value}
      aria-label="basic tabs example"
    >
      {items.map(({ label }, index) => (
        <CssTab label={label} {...someProps(index)} key={index} />
      ))}
    </CssTabs>
  );
}
