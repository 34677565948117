import { Stack } from "src/components/base/Stack";
import { SurveySubtitle } from "..";

import styles from "./StackCategory.module.scss";

export function StackCategory({
  children,
  label,
  space = 14,
}: {
  children: React.ReactNode;
  label: string;
  space?: number;
}) {
  return (
    <Stack space={space}>
      <SurveySubtitle className={styles.StackCategory}>{label}</SurveySubtitle>
      {children}
    </Stack>
  );
}
