import { BaseLoader } from "src/components/base/BaseLoader";

type PropsType = {
    children: React.ReactNode;
    isLoading: boolean;
}

const Preloader = ({ children, isLoading }: PropsType) => {
    return isLoading ? <BaseLoader /> : <>{children}</>;
}

export default Preloader;