import React from 'react';

import { BaseIcon } from "../../components/base/BaseIcon";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../hooks/useWindowSize";
import { OrderType } from "../../api/hooks/ordersHooks";
import { formatBablo } from "../../common/functions/formatBablo";

import styles from './OrderItem.module.scss';

type PropsType = OrderType;


const OrderItem = ({createdAt, status, user, amount, deliveryWay, items, address, phone}: PropsType) => {
  const [width] = useWindowSize();
  const ifMobile = width < 767;

  function formatDate(dateString: string) {
    const inputDate = new Date(dateString);

    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1;
    const year = inputDate.getFullYear();

    const hour = inputDate.getHours();
    const minute = inputDate.getMinutes();

    return (
      (day < 10 ? "0" + day : day) +
      "." +
      (month < 10 ? "0" + month : month) +
      "." +
      year +
      " | " +
      (hour < 10 ? "0" + hour : hour) +
      ":" +
      (minute < 10 ? "0" + minute : minute)
    );
  }

  const orderDate = formatDate(createdAt);

  function truncateLongWords(text:string) {
    // Регулярное выражение для поиска слов длиной более 30 символов
    const regex = /[\wа-яА-ЯёЁ0-9]{30,}/g;

    // Заменяем найденные слова
    return text.replace(regex, (match) => {
      if (match.length > 25) {
        return match.slice(0, 25) + '...';
      }
      return match;
    });
  }

  return (
    <div className={styles.OrderItem}>
      <span className={styles.OrderItem__date_status}>
        <span className={styles.OrderItem__date}>{orderDate}</span>
        <span className={styles.OrderItem__status}>{status.name}</span>
      </span>
      <div className={styles.OrderItem__divider}></div>
      <table className={styles.OrderItem__table}>
        <tbody>
        <tr>
          <td>Получатель:</td>
          <td>
            {user.firstName}
            {" "}
            {user.lastName}
            {" | "}
            {user.email}
            {" | "}
            {phone}
          </td>
        </tr>
        <tr>
          <td>Сумма:</td>
          <td>{formatBablo(amount)} ₽</td>
        </tr>
        <tr>
          <td>Доставка:</td>
          <td>{deliveryWay} <br/> <span className={styles.OrderItem__address}>{truncateLongWords(address)}</span></td>
        </tr>
        <tr>
          <td>Состав заказа:</td>
          <td>
            {items.map(({name, vendorCode}) =>
              <span className={styles.OrderItem__url} key={vendorCode} onClick={() => {
                console.log('кликнули на товар №', vendorCode, name)
              }}>
                {name}
              </span>
            )}
          </td>
        </tr>
        </tbody>
      </table>
      <Link className={styles.OrderItem__link_to_order} to={'/account?tab=orders'}>
        {ifMobile ?
          (
            <BaseIcon
              className={styles.Arrow}
              icon={"ARROW_HORIZONTAL_BLACK_SMALL"}
              viewBox={"0 0 21 16"}
            />
          ) : (
            <BaseIcon
              className={styles.Arrow}
              icon={"ARROW_HORIZONTAL_BLACK"}
              viewBox={"0 0 60 15"}
            />
          )}
      </Link>
    </div>
  );
};

export default OrderItem;
