import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast/headless";
import clsx from "clsx";
import {
  startOfDay,
  endOfDay,
  endOfTomorrow,
  startOfTomorrow,
  eachWeekendOfInterval,
} from "date-fns";

import { EventPreview, SelectContained, Tab, Tabs } from "src/content";
import { BaseDatePicker } from "src/components/base";
import { BaseTypography } from "src/components/base/BaseTypography";
import { LayoutAccount } from "src/layouts";
import { AuthModal } from "src/modals/AuthModal";
import { Button } from "src/components/base/Button";
import { Tag } from "src/components/base/Tag";

import {
  GetAllEventsQueryDto,
  useEventTypes,
  useInfinityEvents,
} from "src/api/hooks/eventsHooks";
import { useIsInfinityEntriesExists } from "src/hooks/useIsInfinityEntriesExists";
import { EventStatusEnum, RoutesEnum } from "src/common/enums";
import { useEventHandlers } from "src/hooks/useEventHandlers";
import { useWindowSize } from "src/hooks/useWindowSize";
import { useJwtPayload } from "src/hooks/useJwtPayload";
import { useFilters } from "src/hooks/useFilters";
import { OptionType } from "src/common/types/option-type";
import { useEffectAfterMount } from "src/hooks/useEffectAfterMount";
import { useFiltersBy } from "src/hooks/useFiltersBy";
import { useModal } from "src/hooks/useModal";
import { EventActionType } from "src/common/types/event-action.type";
import styles from "./Events.module.scss";
import { PersonType, getMe, useMe } from "src/api/hooks/personsHooks";

const DATE_FILTER_INITIAL = {
  today: {
    start: undefined,
    end: undefined,
  },
  tomorow: {
    start: undefined,
    end: undefined,
  },
  weekend: {
    start: undefined,
    end: undefined,
  },
  custom: {
    start: undefined,
    end: undefined,
  },
};

const ALL_EVENTS_INDEX = 0;
const MY_EVENTS_INDEX = 1;
const ARCHIVE_EVENTS_INDEX = 2;
const HAVE_PART_EVENTS_INDEX = 3;

const indexes = [
  ALL_EVENTS_INDEX,
  MY_EVENTS_INDEX,
  ARCHIVE_EVENTS_INDEX,
  HAVE_PART_EVENTS_INDEX,
];

const items = [
  {
    id: "1",
    name: "Все мероприятия",
  },
  {
    id: "2",
    name: "Мои мероприятия",
  },
  {
    id: "3",
    name: "Архив мероприятий",
  },
  {
    id: "4",
    name: "Участвую",
  },
];

const EventsPage = () => {
  const { isShowing, toggle } = useModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // const { data: user } = useMe();
  const size = useWindowSize();

  const [user, setUser] = React.useState<PersonType | undefined>();

  React.useEffect(() => {
    (async () => {
      console.log("CALLL");
      const user = await getMe();
      setUser(user);
    })();
  }, []);

  const windowWidth = size[0];

  // Filters
  const [limit] = React.useState(12);
  const [page] = React.useState(1);
  const { filters, onHandleFilters } = useFilters<GetAllEventsQueryDto>({
    eventTypes: [],
    personId: undefined,
    statuses: [EventStatusEnum["Опубликовано"]],
    isOutput: true,
    isPart: false,
    limit,
    page,
    date: DATE_FILTER_INITIAL,
  });

  // Requests to Api
  const { data: events, fetchNextPage } = useInfinityEvents(filters);

  // Tabs value
  const [value, setValue] = React.useState(() => {
    const queryValue = searchParams.get("tab");

    if (queryValue === "my-events") {
      return MY_EVENTS_INDEX;
    } else if (queryValue === "arhive") {
      return ARCHIVE_EVENTS_INDEX;
    } else if (queryValue === "part") {
      return HAVE_PART_EVENTS_INDEX;
    } else {
      return ALL_EVENTS_INDEX;
    }
  });

  React.useEffect(() => {
    const isPart = value === HAVE_PART_EVENTS_INDEX;
    const personId = value === MY_EVENTS_INDEX || isPart ? user?.id : undefined;
    const statuses: string[] = [];

    let isOutput = true;

    switch (value) {
      case ALL_EVENTS_INDEX:
        statuses.push(EventStatusEnum["Опубликовано"]);
        setSearchParams({ tab: "all-events" });
        break;
      case MY_EVENTS_INDEX:
        statuses.push(EventStatusEnum["Опубликовано"]);
        statuses.push(EventStatusEnum["Архив"]);
        setSearchParams({ tab: "my-events" });
        if (!user?.id) {
          isOutput = false;
        }
        break;
      case ARCHIVE_EVENTS_INDEX:
        statuses.push(EventStatusEnum["Завершено"]);
        setSearchParams({ tab: "arhive" });
        break;
      case HAVE_PART_EVENTS_INDEX:
        statuses.push(EventStatusEnum["Опубликовано"]);
        statuses.push(EventStatusEnum["Завершено"]);
        setSearchParams({ tab: "part" });
        if (!user?.id) {
          isOutput = false;
        }
        break;
    }

    onHandleFilters({
      personId,
      statuses,
      isPart,
      isOutput,
    });
  }, [value, searchParams, user]);

  // The function handles the tabs state
  const onHandleTabs = (index: number) => {
    setValue(() => index);
  };

  // The function allows to upload more entries
  const onTakeMoreEntries = (e: any) => {
    if (!filters?.limit) return;
    try {
      fetchNextPage();
    } catch (e) {
      toast.error("Не удалось загрузить остальные записи.");
    }
  };

  // The function leads to create event page
  const onGoToCreateEventPage = () => navigate(RoutesEnum.EVENTS_CREATE);

  // Event button handlers
  const { onAddUserToParticipantsList, onAddUserToWaitersList, onLeaveEvent } =
    useEventHandlers({});

  const { isMayUpload, isRecordsExists } = useIsInfinityEntriesExists(events);

  const ifEventNoExistsTab0 = isRecordsExists
    ? "Пока нет мероприятий для тебя."
    : "";
  const ifEventNoExistsTab1 = isRecordsExists
    ? "Ты пока не создал(-а) ни одного мероприятия."
    : "";
  const ifEventNoExistsTab2 = isRecordsExists
    ? "Пока нет мероприятий в архиве"
    : "";
  const isEventNoExistsTab3 = isRecordsExists
    ? "Пока не участвуешь в мероприятиях"
    : "";

  const { data: eventTypes } = useEventTypes();

  const {
    handleCheck: handleCheckCategory,
    handleReset: handleResetCategory,
    isChecked: isCheckedCategory,
    checkedIDs: checkedCategoryIDs,
  } = useFiltersBy();

  useEffectAfterMount(() => {
    onHandleFilters({ eventTypes: checkedCategoryIDs });
  }, [checkedCategoryIDs]);

  const {
    handleCheck: handleCheckDate,
    handleReset: handleResetDate,
    isChecked: isCheckedDate,
    checkedIDs: checkedDateIDs,
  } = useFiltersBy();

  const [date, setDate] =
    React.useState<GetAllEventsQueryDto["date"]>(DATE_FILTER_INITIAL);

  useEffectAfterMount(() => {
    for (const id of indexes) {
      const foundId = !!checkedDateIDs.find((checkedId) => +checkedId === id);

      if (id === MY_EVENTS_INDEX) {
        setDate((prev) => {
          return !!foundId
            ? {
                ...prev,
                today: {
                  start: startOfDay(Date.now()).toString(),
                  end: endOfDay(Date.now()).toString(),
                },
              }
            : {
                ...prev,
                today: {
                  start: undefined,
                  end: undefined,
                },
              };
        });
      }

      if (id === ARCHIVE_EVENTS_INDEX) {
        setDate((prev) => {
          return !!foundId
            ? {
                ...prev,
                tomorow: {
                  start: startOfTomorrow().toString(),
                  end: endOfTomorrow().toString(),
                },
              }
            : {
                ...prev,
                tomorow: {
                  start: undefined,
                  end: undefined,
                },
              };
        });
      }

      if (id === HAVE_PART_EVENTS_INDEX) {
        const date = new Date();
        const [start, end] = eachWeekendOfInterval({
          start: +date,
          end: date.setDate(date.getDate() + 7),
        }).sort((a, b) => +new Date(a) - +new Date(b));

        setDate((prev) => {
          return !!foundId
            ? {
                ...prev,
                weekend: {
                  start: startOfDay(start).toString(),
                  end: endOfDay(end).toString(),
                },
              }
            : {
                ...prev,
                weekend: {
                  start: undefined,
                  end: undefined,
                },
              };
        });
      }
    }
  }, [checkedDateIDs]);

  useEffectAfterMount(() => {
    onHandleFilters({ date });
  }, [date]);

  const handleDate = (start: Date) => {
    setDate((prev) => ({
      ...prev,
      custom: {
        start: start.toString(),
        end: endOfDay(new Date(start)).toString(),
      },
    }));
  };

  const isResetTag =
    checkedDateIDs.length > 0 ||
    checkedCategoryIDs.length > 0 ||
    !!(date?.custom?.start && date?.custom.end);

  const [eventAction, setEventAction] = React.useState<EventActionType>({
    eventId: undefined,
    eventAction: undefined,
    ownerId: undefined,
    telegramLink: undefined,
  });

  React.useEffect(() => {
    const html = document.querySelector("html");

    if (html) {
      html.style.overflow = "-moz-scrollbars-vertical";
      html.style.overflowY = "scroll";
    }

    return () => {
      if (html) {
        html.style.overflow = "unset";
        html.style.overflowY = "unset";
      }
    };
  }, []);

  return (
    <>
      <LayoutAccount title="Мероприятия">
        <div className={styles.EventsPage}>
          <div className={styles.EventsPage__tabs}>
            {windowWidth < 768 ? (
              <SelectContained
                className={styles.EventsPage__tabs_list}
                placeholder="Все мероприятия"
                onChange={(option) => {
                  if (isFinite(+option.id) && typeof +option.id === "number") {
                    onHandleTabs(+option.id - 1);
                  }
                }}
                multiple={false}
                options={items}
                value={items[value]}
              />
            ) : (
              <Tabs
                className={styles.EventsPage__tabs_list}
                onChange={(_, index) => onHandleTabs(index)}
                items={items}
                value={value}
              />
            )}
            <Button
              className={styles.EventsPage__button_create}
              variant="contained_nuar"
              onClick={onGoToCreateEventPage}
            >
              Создать мероприятие
            </Button>
          </div>
          <EventDateFilters
            className={styles.EventsPage__date_filters}
            handleCheck={handleCheckDate}
            handleDate={handleDate}
            isChecked={isCheckedDate}
            date={filters.date?.custom?.start}
          />
          <EventCategoryFilters
            className={styles.EventsPage__category_filters}
            isChecked={isCheckedCategory}
            handleCheck={handleCheckCategory}
            handleReset={() => {
              handleResetCategory();
              handleResetDate();
              setDate(() => DATE_FILTER_INITIAL);
            }}
            isResetTag={isResetTag}
            data={eventTypes}
          />
          <div className={styles.EventsPage__events}>
            {events?.pages?.map((page) =>
              page?.items?.map((event) => (
                <EventPreview
                  onGetEventAction={(eventAction) =>
                    setEventAction(() => eventAction)
                  }
                  onAddUserToParticipantsList={onAddUserToParticipantsList}
                  onAddUserToWaitersList={onAddUserToWaitersList}
                  onLeaveEvent={onLeaveEvent}
                  toggle={toggle}
                  key={event.id}
                  personIdFromJWT={user?.id}
                  {...event}
                />
              )),
            )}
          </div>
          <div className={styles.EventsPage__footer} id="events-footer">
            <Tab index={0} value={value}>
              <TabContent ifEventNoExistsTab={ifEventNoExistsTab0}>
                <Button
                  className={styles.EventsPage__button_upload_more}
                  style={!isMayUpload ? { display: "none" } : { opacity: "1" }}
                  variant="contained_nuar"
                  onClick={onTakeMoreEntries}
                >
                  Загрузить ещё
                </Button>
              </TabContent>
            </Tab>
            <Tab index={1} value={value}>
              <TabContent ifEventNoExistsTab={ifEventNoExistsTab1}>
                {/* <Button
                  className={styles.EventsPage__button_create}
                  variant="contained_nuar"
                  onClick={onGoToCreateEventPage}
                >
                  Создать мероприятие
                </Button> */}
                <Button
                  className={styles.EventsPage__button_upload_more}
                  style={!isMayUpload ? { display: "none" } : { opacity: "1" }}
                  variant="contained_nuar"
                  onClick={onTakeMoreEntries}
                >
                  Загрузить ещё
                </Button>
              </TabContent>
            </Tab>
            <Tab index={2} value={value}>
              <TabContent ifEventNoExistsTab={ifEventNoExistsTab2}>
                <Button
                  className={styles.EventsPage__button_upload_more}
                  style={!isMayUpload ? { display: "none" } : { opacity: "1" }}
                  variant="contained_nuar"
                  onClick={onTakeMoreEntries}
                >
                  Загрузить ещё
                </Button>
              </TabContent>
            </Tab>
            <Tab index={3} value={value}>
              <TabContent ifEventNoExistsTab={isEventNoExistsTab3}>
                <Button
                  className={styles.EventsPage__button_upload_more}
                  style={!isMayUpload ? { display: "none" } : { opacity: "1" }}
                  variant="contained_nuar"
                  onClick={onTakeMoreEntries}
                >
                  Загрузить ещё
                </Button>
              </TabContent>
            </Tab>
          </div>
        </div>
      </LayoutAccount>
      <AuthModal
        onAddUserToParticipantsList={onAddUserToParticipantsList}
        onAddUserToWaitersList={onAddUserToWaitersList}
        eventAction={eventAction}
        isOpen={isShowing}
        toggle={toggle}
      />
    </>
  );
};

const TabContent = ({
  children,
  ifEventNoExistsTab,
}: {
  children: React.ReactNode;
  ifEventNoExistsTab: string;
}) => {
  return (
    <>
      <div className={styles.TabContent}>
        <BaseTypography className={styles.TabContent__text} variant="p">
          {ifEventNoExistsTab}
        </BaseTypography>
        <div className={styles.TabContent__content}>{children}</div>
      </div>
    </>
  );
};

type EventDateFiltersPropsType = {
  className?: string;
  handleCheck: (id: string) => void;
  handleDate: (date: Date) => void;
  isChecked: (id: string) => boolean;
  date?: string | Date;
};

const EventDateFilters = ({
  className = "",
  handleCheck,
  handleDate,
  isChecked,
  date,
}: EventDateFiltersPropsType) => {
  return (
    <ul
      className={clsx({
        [styles.EventDateFilters]: true,
        [className]: !!className,
      })}
    >
      {dateCategories.map(({ id, name, icon }) =>
        !icon ? (
          <Tag
            className={styles.EventDateFilters__filter}
            variant="event_filter_date"
            isActive={isChecked(id)}
            onClick={() => handleCheck(id)}
            key={id}
          >
            {name}
          </Tag>
        ) : (
          <BaseDatePicker
            className={clsx({
              [styles.EventDateFilters__datepicker]: true,
              [styles.EventDateFilters__datepicker_active]: date,
            })}
            onChange={handleDate}
            value={date}
            key={id}
          />
        ),
      )}
    </ul>
  );
};

const dateCategories = [
  {
    id: "1",
    name: "Сегодня",
  },
  {
    id: "2",
    name: "Завтра",
  },
  {
    id: "3",
    name: "Выходные",
  },
  {
    id: "4",
    name: "",
    icon: "CALENDAR",
  },
];

type EventCategoryFiltersPropsType = {
  className?: string;
  isChecked: (id: string) => boolean;
  handleCheck: (id: string) => void;
  handleReset: () => void;
  data?: OptionType[];
  isResetTag: boolean;
};

const EventCategoryFilters = ({
  className = "",
  isChecked,
  handleCheck,
  handleReset,
  data,
  isResetTag,
}: EventCategoryFiltersPropsType) => {
  return (
    <ul
      className={clsx({
        [styles.EventCategoryFilters]: true,
        [className]: !!className,
      })}
    >
      {data?.map(({ id, name }) => (
        <Tag
          className={styles.EventCategoryFilters__filter}
          isActive={isChecked(id)}
          onClick={() => handleCheck(id)}
          variant="event_filter_category"
          key={id}
        >
          {name}
        </Tag>
      ))}
      {isResetTag && (
        <Tag
          className={styles.EventCategoryFilters__filter}
          variant="event_filter_category_clear"
          onClick={() => handleReset()}
        >
          Сбросить
        </Tag>
      )}
    </ul>
  );
};

export default EventsPage;
