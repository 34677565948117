import { useInfiniteQuery, useMutation, useQuery } from "react-query";

import { PaginatedDto, PaginatedRequestDto } from "../commons/types";
import { PersonType } from "./personsHooks";
import { OptionType } from "src/common/types/option-type";
import { queryClient, request } from "../request";

export type EventPayStatus = "PAID" | "NOT_PAID";

export type EventType = {
  id: string;
  title: string;
  text: string;
  address: string;
  organizer: string;
  maxParticipants: number;
  meetingAt: string;
  cost: number;
  isFree: boolean;
  time: string;
  createdAt: string;
  updatedAt: string;
  telegramLink?: string;
  eventParticipants: Array<EventParticipantType>;
  eventWaiters: Array<EventWaiterType>;
  eventTypeIDs: Array<OptionType>;
  eventType: Array<EventTypeType>;
  eventStatusId: string;
  eventStatus: EventStatusType;
  multimedia?: MultimediaType;
  ticketUrl?: string;
  vendorCode?: string;
  personId: string;
  person: PersonType;
  _count: {
    eventParticipants: number;
    placeLeft: number;
  };
};

export type EventParticipantType = {
  personId: string;
  status: EventPayStatus;
  person: PersonType;
};

export type EventWaiterType = {
  personId: string;
  person: PersonType;
};

export type EventTypeType = {
  id: string;
  name: string;
};

export type EventStatusType = {
  id: string;
  name: string;
};

export type MultimediaType = {
  id: string;
  fileName: string;
  originalFileName: string;
  path: string;
};

export type GetAllEventsQueryDto = {
  eventTypes?: Array<OptionType>;
  statuses?: Array<string>;
  isOutput?: boolean;
  personId?: string;
  isPart?: boolean;
  limit: number;
  page: number;
  date?: {
    today?: {
      start?: string;
      end?: string;
    };
    tomorow?: {
      start?: string;
      end?: string;
    };
    weekend?: {
      start?: string;
      end?: string;
    };
    custom?: {
      start?: string;
      end?: string;
    };
  };
};

export type InfinityEventsResponseDto<T> = {
  pagesParams: Array<any>;
  pages: T;
};

export type UpdatePayStatusRequestDto = {
  id: string;
  status: EventPayStatus;
  personId: string;
};

// REQUESTS
const getEvents = async (
  params: PaginatedRequestDto & GetAllEventsQueryDto,
) => {
  const { data } = await request<PaginatedDto<EventType>>({
    url: `/events`,
    params,
  });

  return data;
};

const getEvent = async ({ id }: { id: string | undefined }) => {
  const { data } = await request<EventType>({
    url: `/events/${id}`,
  });

  return data;
};

const createEevent = async (payload: EventType) => {
  const { data } = await request<EventType>({
    url: `/events`,
    method: "POST",
    data: payload,
  });

  return data;
};

const updateEevent = async (payload: EventType) => {
  const { data } = await request<EventType>({
    url: `/events/${payload.id}`,
    method: "PUT",
    data: payload,
  });

  return data;
};

const updatePayStatus = async (payload: UpdatePayStatusRequestDto) => {
  const { data } = await request({
    url: `/events/${payload.id}/participants`,
    method: "PATCH",
    data: {
      personId: payload.personId,
      status: payload.status,
    },
  });

  return data;
};

const getEventTypes = async () => {
  const { data } = await request<Array<EventTypeType>>({
    url: `/events/types`,
  });

  return data;
};

const getEventStatuses = async () => {
  const { data } = await request<Array<EventStatusType>>({
    url: `/events/statuses`,
  });

  return data;
};

const getParticipants = async ({ id }: { id: string }) => {
  const { data } = await request<Array<EventParticipantType>>({
    url: `/events/${id}/participants`,
  });

  return data;
};

const getWaiters = async ({ id }: { id: string }) => {
  const { data } = await request<Array<EventParticipantType>>({
    url: `/events/${id}/waiters`,
  });

  return data;
};

const updateStatus = async ({
  id,
  statusId,
}: {
  id: string;
  statusId: string;
}) => {
  const { data } = await request<EventType>({
    url: `/events/${id}/statuses`,
    method: "PATCH",
    data: {
      statusId,
    },
  });

  return data;
};

const uploadImage = async ({
  id,
  formData,
}: {
  id: string;
  formData: FormData;
}) => {
  const { data } = await request<MultimediaType>({
    url: `/events/${id}/images`,
    method: "POST",
    data: formData,
  });

  return data;
};

const takePartInEvent = async ({ id }: { id: string }) => {
  const { data } = await request<EventType>({
    url: `/events/${id}/actions/take-part`,
    method: "PATCH",
  });

  return data;
};

const takeQueueInEvent = async ({ id }: { id: string }) => {
  const { data } = await request<EventType>({
    url: `/events/${id}/actions/take-queue`,
    method: "PATCH",
  });

  return data;
};

const leaveEvent = async ({ id }: { id: string }) => {
  const { data } = await request<EventType>({
    url: `/events/${id}/actions/leave`,
    method: "PATCH",
  });

  return data;
};

// HOOKS
export const useEvents = ({
  page,
  limit,
  eventTypes,
  isPart,
  isOutput,
  personId,
}: PaginatedRequestDto & GetAllEventsQueryDto) =>
  useQuery<PaginatedDto<EventType>>(
    ["events", page, limit, eventTypes, isPart, personId, isOutput],
    () =>
      getEvents({
        page,
        limit,
        eventTypes,
        isPart,
        personId,
        isOutput,
      }),
    {
      notifyOnChangeProps: "tracked",
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      staleTime: 5000,
    },
  );

export const useInfinityEvents = ({
  eventTypes,
  personId,
  statuses,
  isPart,
  isOutput,
  limit,
  page,
  date,
}: PaginatedRequestDto & GetAllEventsQueryDto) =>
  useInfiniteQuery<PaginatedDto<EventType>>(
    [
      "events",
      page,
      limit,
      isPart,
      personId,
      isOutput,
      eventTypes,
      statuses,
      date,
    ],
    ({ pageParam = 1 }) =>
      getEvents({
        eventTypes,
        statuses,
        personId,
        isPart,
        limit,
        isOutput,
        page: pageParam,
        date,
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (
          Math.ceil(pages.map((page) => page.items).flat(1).length / limit) <
          lastPage.totalPages
        )
          return Math.ceil(pages.length % limit) + 1;
        return undefined;
      },
      // staleTime: 1000,
      // retryDelay: 1000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      notifyOnChangeProps: "tracked",
      refetchOnMount: true,
      // cacheTime: 100,
    },
  );

export const useEvent = ({ id }: { id: string | undefined }) =>
  useQuery<EventType>(["event", id], () => getEvent({ id }), {
    notifyOnChangeProps: "tracked",
    refetchOnMount: true,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    enabled: !!id,
  });

export const useCreateEvent = () =>
  useMutation(["events"], createEevent, {
    onSettled: () => {
      queryClient.invalidateQueries("events");
    },
  });

export const useUpdateEvent = () =>
  useMutation(["events"], updateEevent, {
    onSettled: () => {
      queryClient.invalidateQueries("events");
    },
  });

export const useUploadImage = () =>
  useMutation(["events"], uploadImage, {
    onSettled: () => {
      queryClient.invalidateQueries("events");
    },
  });

export const useEventTypes = () =>
  useQuery<Array<EventTypeType>>(["types"], () => getEventTypes(), {
    select: (data) => data.filter((_, index) => index !== 0),
  });

export const useEventStatuses = () =>
  useQuery<Array<EventStatusType>>(["statuses"], () => getEventStatuses(), {
    select: (data) => data.filter((_, index) => index !== 0),
  });

export const useParticipants = ({ id }: { id: string }) =>
  useQuery<Array<EventParticipantType>>(
    ["participants", id],
    () => getParticipants({ id }),
    {
      enabled: !!id,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );

export const useWaiters = ({ id }: { id: string }) =>
  useQuery<Array<EventWaiterType>>(["waiters", id], () => getWaiters({ id }), {
    enabled: !!id,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

export const useUpdatePayStatus = () =>
  useMutation(["eventParticipants"], updatePayStatus, {
    onSettled: () => {
      queryClient.invalidateQueries("eventParticipants");
    },
  });

export const useUpdateStatus = () =>
  useMutation(["events"], updateStatus, {
    onSettled: () => {
      queryClient.invalidateQueries("events");
    },
  });

export const useTakePartInEvent = () =>
  useMutation(["events"], takePartInEvent, {
    onSettled: () => {
      queryClient.invalidateQueries("events");
    },
  });

export const useTakeQueueInEvent = () =>
  useMutation(["events"], takeQueueInEvent, {
    onSettled: () => {
      queryClient.invalidateQueries("events");
    },
  });

export const useLeaveEvent = () =>
  useMutation(["events"], leaveEvent, {
    onSettled: () => {
      queryClient.invalidateQueries("events");
    },
  });
