import React from 'react';

import checkWhite from '../../../common/images/fa-check-white.webp';
import checkBlack from '../../../common/images/fa-check-black.webp';
import './BaseValidation.scss';

interface BaseValidationProps {
  text: string;
  valid?: boolean;
}

const BaseValidation: React.FC<BaseValidationProps> = ({ text, valid }) => {
  return (
    <div
      className={['base-validation', valid ? 'base-validation_valid' : '']
        .join(' ')
        .trim()}
    >
      <img
        className="base-validation__img"
        src={valid ? checkBlack : checkWhite}
        alt="check"
      />
      <span className="base-validation__text">{text}</span>
    </div>
  );
};

export default BaseValidation;
