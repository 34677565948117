import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { CredentialsForm } from "src/layouts/CredentialsForm";
import { BaseTypography } from "src/components/base/BaseTypography";
import { BaseInputError } from "src/components/base/BaseInputError";
import { BaseLoader } from "src/components/base/BaseLoader";
import { BaseButton } from "src/components/base/BaseButton";
import { BaseInput } from "src/components/base/BaseInput";
import { Stack } from "src/components/base/Stack";

import { SigninValidation } from "src/common/validation/index";
import { useAuth } from "src/hooks/useAuth";
import "./index.css";
import { LINK_TO_AUTH_SHOP } from "../../../common/consts/shop";
import { useQueryIsStore } from "../../../hooks/useQuery";

type SigninType = {
  email: string;
  password: string;
};

export const Signin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { signin, signinFromStore } = useAuth();
  const isStore = useQueryIsStore(); // hook говорит true если со стора

  const navigate = useNavigate();

  // useEffect(() => {
  //   const redirectFromLoginStore = signinFromStore();
  //   if (redirectFromLoginStore) {
  //     console.log("lock and load")
  //     // to orders
  //     window.location.href = window.location.origin + "/account?tab=orders";
  //   }
  // }, [])

  const form = useForm<SigninType>({
    resolver: yupResolver(SigninValidation),
  });

  const onSubmit = async (data: SigninType) => {
    try {
      setIsLoading(true);
      if (isStore) {
        await signin(data);
        window.location.href = LINK_TO_AUTH_SHOP;
      } else {
        await signin(data);
        navigate("/account");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const { errors } = form.formState;

  return (
    <CredentialsForm title="Вход">
      <div className="Signin">
        <form
          className="Signin__form"
          onSubmit={form.handleSubmit(onSubmit)}
          id="signin-form-id"
        >
          <div className="Signin__form-items">
            <Stack space={14}>
              <Stack space={10}>
                <BaseInput
                  className={`Signin__input`}
                  placeholder="Ваша электронная почта"
                  {...form.register("email")}
                />
                <BaseInputError<SigninType> errors={errors} field={"email"} />
              </Stack>
              <Stack space={10}>
                <BaseInput
                  className={`Signin__input`}
                  placeholder="Пароль"
                  type="password"
                  {...form.register("password")}
                />
                <BaseInputError<SigninType>
                  errors={errors}
                  field={"password"}
                />
              </Stack>
            </Stack>
            <BaseTypography variant="p" format="form-link">
              Еще нет аккаута?
              <Link className="Signin__link" to="/signup/form/step/1">
                Зарегистрироваться
              </Link>
            </BaseTypography>
          </div>
        </form>
        <div className="Signin__control">
          {isStore && <div></div>}

          {isLoading ? (
            <BaseLoader />
          ) : (
            <BaseButton
              className="Signin__control-button"
              variant="base-button-new-style-black"
              type="submit"
              form="signin-form-id"
            >
              Подтвердить
            </BaseButton>
          )}
          <Link className="Signin__control-link" to="/signin/reset-password">
            <BaseTypography variant="p" format="form-link">
              Восстановить пароль
            </BaseTypography>
          </Link>
        </div>
      </div>
    </CredentialsForm>
  );
};
