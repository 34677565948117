import { PASSWORD_REG_EXP } from '../common/validation/index';

type PropsType = {
  password: string;
  confirmPassword: string;
};

export const useValidatePassword = ({
  password,
  confirmPassword,
}: PropsType) => {
  const isValidLength = !!(password && password.length >= 8);
  const isValidSymbols = !!(password && password.match(PASSWORD_REG_EXP));
  const isEquals = !!(
    password &&
    confirmPassword &&
    password === confirmPassword
  );

  return [isValidLength, isValidSymbols, isEquals];
};
