import React from "react";

import { OptionType } from "src/common/types/option-type";
import styles from "./Tabs.module.scss";

type PropsType = {
  className?: string;
  onChange: (option: OptionType, index: number) => void;
  items: Array<OptionType>;
  value: number;
};

const Tabs = ({ className, onChange, items, value }: PropsType) => {
  return (
    <ul className={[styles.Tabs, className].join(" ").trim()}>
      {items.map(({ id, name }, index) => (
        <li
          className={[
            styles.Tabs__item,
            value === index ? styles.Tabs__item_active : "",
          ]
            .join(" ")
            .trim()}
          onClick={() => onChange({ id, name }, index)}
          {...props({ index, id })}
        >
          {name}
        </li>
      ))}
    </ul>
  );
};

const props = (args: { index: number; id: string }) => ({
  tabIndex: args.index,
  key: args.id,
});

const MemoizedTabs = React.memo(Tabs);

export default MemoizedTabs;
